import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Api from "../../helpers/Api";
import CreateButton from "../styled/actionBtns/create.btn";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
    background: "#f5f6f8",
  },
  icon: {
    color: "#1684ea",
  },
}));

export default function SelectTemplate(props) {
  const history = useHistory();
  const classes = useStyles();
  const { button, icon } = classes;
  const { user, userProfile } = useSelector((state) => state.auth);
  const {
    templateIds,
    templateDictionary,
    profile,
    defaultOpen,
    setOuterOpen,
  } = props;

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (setOuterOpen) {
      setOuterOpen(false);
    }
  };

  const createApi = async (templateId) => {
    const template = templateDictionary[templateId];
    const issueShared = template?.issueShared || [];
    const pipeline = template?.pipeline;
    const profileId = profile?._id;
    const userProfileId = user?.profile;
    const sharedSet = new Set([...issueShared, profileId, userProfileId]);

    let issueObject = {
      user: user._id,
      template: templateId,
      profile: profile?._id,
      shared: Array.from(sharedSet),
      creator:userProfileId,
      createdIn:profileId
    };

    if (pipeline?.length > 0) {
      issueObject = { ...issueObject, status: pipeline[0]?._id };
    }

    const res = await Api.post("issue/create", issueObject);
    const data = res?.data;
    const issueId = data?._id;
    const path = "/issue/edit/" + issueId;
    history.push(path);
  };

  useEffect(() => {
    if (defaultOpen) {
      setOpen(true);
    }
  }, []);

  return (
    <div>
      {!defaultOpen ? (
        <Button
          variant="contained"
          className={classes.button}
          startIcon={<AddIcon className={icon} />}
          onClick={() => {
            setOpen(true);
          }}
        >
          Create Ticket
        </Button>
      ) : null}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Select Team"}</DialogTitle>
        <List component="nav">
          {templateIds &&
            templateIds.map((templateId, i) => {
              const template = templateDictionary[templateId];
              return (
                <ListItem
                  key={i}
                  button
                  onClick={() => {
                    createApi(templateId);
                  }}
                >
                  <ListItemText primary={template?.title} />
                </ListItem>
              );
            })}
        </List>
      </Dialog>
    </div>
  );
}
