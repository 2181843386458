import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel, useMediaQuery } from "@material-ui/core";
import { Link } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import LoginBox from "./LoginBox";
import Button from "@material-ui/core/Button";
import { handleGoogleLogin, handleEmailPasswordSignup, firebaseLoginHelper } from "./auth.utils";
import TextField from "@material-ui/core/TextField";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import iconsGoogle from "../../Assets/iconsGoogle.svg";
import Box from "@material-ui/core/Box";
import cx from "clsx";
import { useGoogleOneTapLogin } from "react-google-one-tap-login";
import firebase from "firebase";
import Api from "../../helpers/Api";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import sha256 from "../../helpers/sha256";
import config from "../../config/index";
import jwt_decode from "jwt-decode";
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "30px",
    width: "100%",
  },
  loginSignUpCont: {
    backgroundColor: "#ffffff",
    maxWidth: "580px",
    width: "100%",
    // height: "475px",
    borderRadius: "0px",
    display: "flex",
    flexDirection: "column",
    // alignItems: "center",
  },
  button: {
    width: "271px",
    padding: "5px 0",
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
  googleButton: {
    width: "280px",
    border: "2px solid grey",
    backgroundColor: "white",
    color: "black",
    padding: "8px 0",
    width: "100%",
    borderRadius: "999rem",
  },
  icon: {
    width: "20px",
    marginRight: "10px",
  },
  smallFont: {
    fontSize: "15px",
    fontWeight: "700",
    color: "#0f1419",
  },
  dividerCont: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0",
  },
  dividerHalfCont: {
    width: "44%",
    backgroundColor: "#a4a7a4",
    height: "1px",
  },
  freeText: {
    textAlign: "center",
    fontWeight: "700",
    fontSize: "30px",
    color: "#170f49",
    marginBottom: "40px"
  },
  signUpCont: {
    width: "100%",
    fontSize: "15px",
    padding: "4px 40px",
    marginTop: "15px",
    fontWeight: "700",
    borderRadius: "999rem",
    boxShadow: "none",
    height: "42px",
    "&:hover": {
      boxShadow: "none",
    },
  },
  conditonStyle: {
    marginTop: "30px",
    marginBottom: "35px",
    opacity: "0.8",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
    width: "73%",
    textAlign: "center",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    }
  },
  singInCont: {
    fontSize: "15px",
    opacity: "0.8",
    marginTop: "20px",
    textAlign: "center",
  },
  signinStyle: {
    color: "#2E73F8",
    marginLeft: "5px",
    cursor: "pointer",
  },
  linkStyle: {
    textdecoration: "underline",
    color: "#0f1419",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "21px",
  },
  inputs: {
    marginBottom: "7px",
    width: "100%",
    borderRadius: "20px",
  },
  inputLabel: {
    fontWeight: "400",
    fontSize: "16px",
    color: "#666666",
    marginBottom: "7px",
    display: "flex",
    alignItems: "center",
  },
  forgotPassword: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    textAlign: "right",
    textDecoration: "underline",
    color: "#111111",
  },
  signupCont: {
    display: "flex",
    // alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  input: {
    backgroundColor: "rgba(33, 150, 243, 0.1)"
  },
  noBorder: {
    border: "none",
  },
  showHideCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& div": {
      display: "flex",
      alignItems: "center",
    },
    "& h3": {
      marginLeft: "4px"
    }
  }
}));

function AuthBox(props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const { setLoadingBool } = props;
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [toggleState, setToggleState] = useState("Sign In");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordLen, setPasswordLen] = useState(false);

  function handleSubmit(event) {
    event.preventDefault();
    if (email.includes(".com") && password.length > 7) {
      setLoadingBool(true);
      handleEmailPasswordSignup(dispatch, email, password);
    } else {
      if (password.length < 8) {
        setPasswordLen(true);
      }
    }
  }

  // called when firebase user is already present, we need to just signin
  const googleOneTapHelper = async (
    token, email
  ) => {


  };
  useGoogleOneTapLogin({
    onError: (error) => console.log(error),
    onSuccess: async (response) => {
      // firebase
      //   .auth()
      //   .getRedirectResult()
      //   .then(function (result) {
      //     if (result.credential) {
      //       // This gives you the OAuth Access Token for that provider.
      //       var token = result.credential.accessToken;
      //     }
      //     var user = result.user;
      //   });
      const email = response.email;
      const displayName = response.name;

      const saltfromEnv = process.env.GOOGLE_ONE_TAP_CUSTOM_SALT;
      const password = await sha256(response.email + saltfromEnv);
      const imageUrl = response.picture;
      // // Start a sign in process for an unauthenticated user.
      // var provider = new firebase.auth.OAuthProvider("google.com");
      // provider.addScope("profile");
      // provider.addScope("email");
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((result) => {

          // naya user hai, 
          //Add the profile photo
          result.photoURL = imageUrl;
          result.displayName = displayName;

          firebaseLoginHelper(result, dispatch)

        })
        .catch((e) => {
          // Login
          firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then((result) => {
              //update the profile photo
              result.photoURL = imageUrl

            })
            .catch((e) => {
              //Finally failed
              console.log(e, " is the firebase error while login");
            });
        });
      // const auth = getAuth();
      // createUserWithEmailAndPassword(auth, email, password)
      //   .then((userCredential) => {
      //     // Signed in
      //     const user = userCredential.user;
      //     // ...
      //   })
      //   .catch((error) => {
      //     const errorCode = error.code;
      //     const errorMessage = error.message;
      //     console.log("error is", errorCode)
      //     // ..
      //   });
    },
    googleAccountConfigs: {
      client_id:
        // "1022242836655-082rs8inm6tbes99jps1op7msp4t69l3.apps.googleusercontent.com", // Your google client id here !!!""
        config?.googleOneTapClientId,
        callback: async (res) => {
        var googletoken = res.credential;
        var response = jwt_decode(googletoken);
        const email = response.email;
        const displayName = response.name;
        const saltfromEnv = process.env.GOOGLE_ONE_TAP_CUSTOM_SALT;
        const password = await sha256(response.email + saltfromEnv);
        const imageUrl = response.picture;

        firebase
          .auth()
          .createUserWithEmailAndPassword(email, password)
          .then((result) => {

            // naya user hai, 
            //Add the profile photo
            let firebaseUser = JSON.parse(JSON.stringify(result.user));
            firebaseUser.photoURL = imageUrl;
            firebaseUser.displayName = displayName;

            firebaseLoginHelper(firebaseUser, dispatch,displayName)

          })
          .catch(async (e) => {
            console.log(e, ' is the error catched')
            // user already exists
            await Api.post('googleOneTapSignIn', { googletoken })
              .then(res => {
                const data = res?.data;
                const user = data?.user;
                const firebaseToken = data?.firebaseToken
                const uid = user?.uid
                const activeUserId = user?._id;
                const activeUserProfileId = user?.profile

                localStorage.setItem("token", firebaseToken);
                localStorage.setItem("activeUserId", activeUserId);
                localStorage.setItem("activeUserProfileId", activeUserProfileId);
                localStorage.setItem("loggedInFirebaseUId", uid);
                const userId = user._id;
                const personDictionary = {};
                personDictionary[userId] = user;
                dispatch({
                  type: "SET_AUTH_USER",
                  user,
                });
              })

          })
        // var header = JSON.parse(base64urlDecode(headerSeg));
        // var payload = JSON.parse(base64urlDecode(payloadSeg));
      }
    },
  });

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.loginSignUpCont} >
        <Typography className={classes.freeText} >{toggleState} to RealList</Typography>
        {toggleState === "Sign In" ? (
          <>
            <LoginBox
              setToggleState={setToggleState}
              setLoadingBool={setLoadingBool}
            />
          </>
        ) : (
          <>
            <form className={classes.signupCont} onSubmit={handleSubmit}>
              <TextField
                style={{ marginBottom: "20px" }}
                className={classes.inputs}
                size="small"
                id="standard-basic"
                color="primary"
                placeholder="Email"
                type="email"
                value={email}
                InputProps={{
                  className: classes.input,
                  classes: { notchedOutline: classes.noBorder }
                }}
                onChange={(event) => setEmail(event.target.value)}
                variant="outlined"
              />
              <TextField
                className={classes.inputs}
                size="small"
                id="standard-basic"
                color="primary"
                type="password"
                placeholder="Password"
                value={password}
                InputProps={{
                  className: classes.input,
                  classes: { notchedOutline: classes.noBorder }
                }}
                onChange={(event) => setPassword(event.target.value)}
                variant="outlined"
              />
              <div className={classes.showHideCont} >
                <div></div>
                <div style={{ cursor: "pointer" }} >
                  <VisibilityIcon />
                  <h3>Show</h3>
                </div>
              </div>

              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    style={{ paddingBottom: "0px", paddingTop: "0px" }}
                  />
                }
                label="Remember me"
              />
              {passwordLen ? (
                <p style={{ fontSize: "14px", color: "red" }}>
                  Password should contain at least 8 characters
                </p>
              ) : null}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.signUpCont}
              >
                Sign Up
              </Button>
            </form>
            <div className={classes.dividerCont}>
              <div className={classes.dividerHalfCont}></div>
              <span style={{ fontSize: "15px", fontWeight: "500", color: "gray" }}>or</span>
              <div className={classes.dividerHalfCont}></div>
            </div>
            <Button
              className={cx(classes.button, classes.googleButton)}
              onClick={(el) => {
                setLoadingBool(true);
                handleGoogleLogin(dispatch);
              }}
            >
              <img className={classes.icon} src={iconsGoogle} alt="google" />{" "}
              <Typography className={classes.smallFont}>
                Sign Up using Google
              </Typography>
            </Button>


            <Typography className={classes.singInCont} >
              Already have an account?
              <span
                className={classes.signinStyle}
                onClick={() => {
                  setToggleState("Sign In");
                }}
              >
                Sign In
              </span>
            </Typography>


            <Typography className={classes.conditonStyle}>
              By signing up, you agree to the{" "}
              <Link className={classes.linkStyle} to="/terms">
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link className={classes.linkStyle} to="/terms">
                Privacy Policy
              </Link>
              ,
              {" "}as well as our partner Dwolla's{" "}
              <Link className={classes.linkStyle} to="https://www.dwolla.com/legal/tos/#legal-content">
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link className={classes.linkStyle} to="https://www.dwolla.com/legal/privacy/#legal-content">
                Privacy Policy
              </Link>
            </Typography>
          </>
        )}
      </Paper>
    </div>
  );
}

export default AuthBox;
