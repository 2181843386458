import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import NotificationsIcon from "@material-ui/icons/Notifications";
import Popover from "@material-ui/core/Popover";
import Paper from "@material-ui/core/Paper";
import Badge from "@material-ui/core/Badge";
import ActivityCard from "../activity/notify.card";
import { Box, IconButton, Tooltip } from "@material-ui/core";
import Api from "../../helpers/Api";
import arrayToReducer from "../../helpers/arrayToReducer";
import BoxSpaceBetween from "../styled/generic/BoxSpaceBetween";
import { Typography } from "@mui/material";
import { Close } from "@material-ui/icons";
import Button from "../styled/generic/Button";
import HorizBox from "../styled/generic/HorizBox";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const NotificationSound = "/sounds/notification-sound.mp3";

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginTop: "-2px",
    marginRight: 0,
    [theme.breakpoints.down("sm")]: {
      marginRight: "-0.6rem",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "-1.05rem",
    },
  },

  NotificationsIconStyle: {
    color: theme.palette.action.disabled,
    fontSize: "1.7rem",
  },

  popoverStyle: {
    maxHeight: "4rem",
    maxWidth: "19rem",
    padding: "1rem",
  },

  popoverButton: {
    width: "11rem",
    height: "3.0rem",
    paddingRight: "2rem",
    display: "flex",
    borderRadius: "5%",
  },
  center: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",
  },
  menuIcon: {
    "& path": {
      fill: "rgba(0, 0, 0, 0.26)",
    },
  },
  text: {
    color: "rgba(0, 0, 0, 0.26)",
    marginTop: "-5%",
    fontWeight: "500",
    fontSize: "12px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));
export default function S(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const audioPlayer = useRef(null);

  const { activityDictionary, topNotificationIds: reducerTopNotificationIds } =
    useSelector((state) => state.activity);
  const topNotificationIds = Array.isArray(reducerTopNotificationIds)
    ? reducerTopNotificationIds.slice(0, 5)
    : [];
  const [anchorEl, setAnchorEl] = React.useState(null);

  const notifications = topNotificationIds.map((id) => activityDictionary[id]);
  var newNotifications = [];
  notifications.map((n) => {
    if (n?.seen) {
    } else {
      newNotifications.push(n?._id);
    }
  });

  const handleClick = async (event) => {
    setAnchorEl(event.currentTarget);
    var newNotArr = newNotifications.map((nId) => {
      var nOld = activityDictionary[nId];
      return {
        ...nOld,
        seen: true,
      };
    });

    let { newDict } = arrayToReducer(newNotArr);

    var newNotDict = {
      ...activityDictionary,
      ...newDict,
    };
    dispatch({
      type: "AddActivity",
      payload: {
        activityDictionary: newNotDict,
      },
    });

    await Api.post("notification/markAsSeen", {
      notificationIds: newNotifications,
    });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function playAudio() {
    audioPlayer.current.play();
  }

  useEffect(() => {
    if (newNotifications.length > 0) {
      playAudio();
    }
  }, [newNotifications]);

  return (
    <>
      <audio ref={audioPlayer} src={NotificationSound} />
      <Tooltip title="Notifications">
        <Box
          aria-label="show 17 new notifications"
          color="disabled"
          className={classes.center}
          onClick={handleClick}
        >
          <Badge badgeContent={newNotifications.length} color="primary">
            <NotificationsIcon
              sx={{ fontSize: 32 }}
              className={classes.menuIcon}
            />
          </Badge>
        </Box>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          sx={{
            boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
            borderRadius: "12px",
            px: 2,
            py: 2,
            width: "440px",
          }}
        >
          <BoxSpaceBetween>
            <Typography variant="h6">Notifications</Typography>
            <IconButton onClick={handleClose} color="primary" size="small">
              <Close />
            </IconButton>
          </BoxSpaceBetween>
          <Box
            sx={{
              height: "300px",
              overflowY: "auto",
              mt: 1,
              "&::-webkit-scrollbar": {
                display: "none",
              },
              scrollbarWidth: "none",
            }}
          >
            {topNotificationIds && topNotificationIds.length > 0 ? (
              <>
                {topNotificationIds.map((notId, i) => {
                  return <ActivityCard key={i} actId={notId} />;
                })}
              </>
            ) : (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="300px"
              >
                <Typography variant="subtitle1">
                  No notification found
                </Typography>
              </Box>
            )}
          </Box>
          {topNotificationIds && topNotificationIds.length > 0 && (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              mt={2}
            >
              <HorizBox>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  sx={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    history.push("/notifications");
                  }}
                >
                  View All
                </Typography>
              </HorizBox>
            </Box>
          )}
        </Box>
      </Popover>
    </>
  );
}
