import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, TextField, Avatar, IconButton, InputAdornment } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import NormalDrawer from '../../styled/CommonComponents/NormalDrawer';
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddIcon from "@material-ui/icons/Add";
import TextFieldNumberFormated from "../../styled/CommonComponents/TextFieldNumberFormated";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import SearchEntityDialog from '../../styled/CommonComponents/SearchEntityDrawer';
import { ASSET_OPTIONS } from '../../../helpers/constants';
import { addAssetApiCall, createDefaultAssetCategories } from '../api.call';
import CustomFileUploadButton from '../../file/Uploader/CustomFileUploadButton';
import { getAllFiles } from '../../community/api.call';
import CancelIcon from '@material-ui/icons/Cancel';
import { getCategoriesByLibrary } from '../../OfferingsAndProducts/Api.call';
import NotifyAlert from '../../styled/CommonComponents/NotifyAlert';
import * as yup from "yup";
import CategoryAutocomplete from '../../OfferingsAndProducts/CategoryAutocomplete';

const assetValidationSchema = yup.object().shape({
    nameId: yup.string().min(2).max(250).required("Name is required"),
    category: yup.object().required("Category is required"),
    purchseCost: yup.number().min(1).required("Purchse cost is required"),
    fullLife: yup.string().required("Full life is required"),
    salvageValue: yup.number().min(1).required("Purchasing price is required")
});


const useStyles = makeStyles((theme) => ({
    mainDialogCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "& .MuiButton-label": {
            textTransform: "capitalize",
            fontSize: "14px"
        }
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 55px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "5px",
        paddingBottom: "15px"
    },
    bottomAct: {
        width: "100%",
        height: "50px",
        padding: "0px 7px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        borderTop: "1px solid #ececec"
    },
    inputBoxCont: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap"
    },
    depricationInputBoxCont: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
        justifyContent: "space-between"
    },
    fullWInput: {
        width: "100%",
        padding: "10px",
        boxSizing: "border-box",
        "& p": {
            fontSize: "15px",
            fontWeight: "500",
            marginBottom: "1px"
        }
    },
    oneThirdWInput: {
        width: "33.33%",
        padding: "10px",
        boxSizing: "border-box",
        "& p": {
            fontSize: "15px",
            fontWeight: "500",
            marginBottom: "1px"
        }
    },
    oneFourthWInput: {
        width: "24%",
        padding: "10px",
        boxSizing: "border-box",
        "& p": {
            fontSize: "15px",
            fontWeight: "500",
            marginBottom: "1px"
        }
    },
    oneThirdWInputAutocomplete: {
        width: "33.33%",
        padding: "10px",
        boxSizing: "border-box",
        "& p": {
            fontSize: "15px",
            fontWeight: "500",
            marginBottom: "1px"
        }
    },
    addVendorSty: {
        width: "100%",
        color: "#aeacac",
        border: "1px solid #c3c2c2",
        borderRadius: "8px",
        cursor: "pointer",
        padding: "9px 10px"
    },
    selectedUserOrgSty: {
        maxWidth: "100%",
        padding: "2px 5px",
        cursor: "pointer",
        border: "1px solid #c3c2c2",
        borderRadius: "8px",
        marginRight: "5px",
        marginBottom: "5px",
        display: "flex",
        alignItems: "center",
        "& .MuiSvgIcon-root": {
            fontSize: "20px",
            marginLeft: "5px"
        },
        "& .MuiIconButton-root": {
            padding: "3px"
        },
        "& .MuiAvatar-root": {
            width: "30px",
            height: "30px",
        },
        "& h3": {
            fontSize: "13px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "10px",
            fontWeight: "400",
            color: "gray"
        }
    },
    sectionLable: {
        padding: "0px 10px",
        "& div": {
            backgroundColor: "#EEF5FC",
            padding: "5px 0px",
            marginTop: "10px"
        }
    },
    symbolSty: {
        paddingTop: "40px",
        fontSize: "16px",
        fontWeight: "600"
    },
    usersCont: {
        width: "100%",
        height: "100px",
        fontSize: "16px",
        fontWeight: "500",
        borderRadius: "10px",
        border: `2px dashed ${theme.palette.primary.main}`,
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "center",
        backgroundColor: "#f5f5f5",
        "&:hover": {
            backgroundColor: "#ececec",
        }
    },
    selectedParticipantsCont: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: "#f9f9f9",
        padding: "5px 5px 0px",
        border: "1px solid #dfdfdf",
        "& .MuiButton-root": {
            height: "38px",
            borderRadius: "5px",
            marginBottom: "5px"
        }
    },
    allImagesCont: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        marginBottom: "10px"
    },
    imageCardCont: {
        width: "33.33%",
        padding: "10px",
    },
    imageCard: {
        width: "100%",
        height: "200px",
        position: "relative",
        backgroundPosition: "center",
        backgroundSize: "contain",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"
    },
    closeBtn: {
        position: "absolute",
        top: "0px",
        right: "0px",
    },
    validationSty: {
        fontSize: "12px",
        color: "red",
        fontWeight: "400",
        marginTop: "3px"
    },
}));

const searchParam = [
    {
        entity: "User",
        scop: [
            {
                type: "Global",
                role: []
            }
        ]
    },
    {
        entity: "Organization",
        scop: [
            {
                type: "Global",
                role: []
            }
        ]
    }
]

const usersOnlySearchParam = [
    {
        entity: "User",
        scop: [
            {
                type: "Global",
                role: []
            }
        ]
    }
]

export default function AssetAddDrawer({
    createAssetDawer, setCreateAssetDrawer, profileParentData, afterAssetsAdd, walletData
}) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { createdFileIds } = useSelector((state) => state.file);

    const initialObj = {
        nameId: "",
        condition: "",
        type: "",
        location: "",
        category: null,
        model: "",
        manufacturer: "",
        vendor: null,
        purchseCost: 0,
        purchaseDate: new Date(),
        warratyExpiration: new Date(),
        fullLife: 0,
        lastMaintenanceDate: new Date(),
        nextServiceDate: new Date(),
        salvageValue: 0,
        depreciationExp: 0,
        depreciationExpense: 0,
        notifiyTo: [],
        files: []
    }
    const [openVendorDrawer, setOpenVendorDrawer] = useState(false)
    const [openNotifySelectDrawer, setOpenNotifySelectDrawer] = useState(false)
    const [assetObject, setAssetObject] = useState(initialObj)
    const [loader, setLoader] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState([])
    const [alertObj, setAlertObj] = useState({})
    const [validationObj, setValidationObj] = useState({})
    const [categoryLoading, setCategoryLoading] = useState(false)

    useEffect(() => {
        if (assetObject?.fullLife && assetObject?.fullLife > 0) {
            const newObj = {
                ...assetObject,
                depreciationExpense: (parseFloat(assetObject?.purchseCost || 0) - (parseFloat(assetObject?.salvageValue || 0) / parseFloat(assetObject?.fullLife || 0))).toFixed(2)
            }
            setAssetObject(newObj)
        }
    }, [assetObject?.purchseCost, assetObject?.fullLife && assetObject?.salvageValue])

    const afterFileAdded = async () => {
        if (createdFileIds && createdFileIds.length > 0) {
            await getAllFiles({ files: createdFileIds })
                .then((data) => {
                    dispatch({ type: "FileReset" });
                    if (data && data.length > 0) {
                        let oldFiles = assetObject?.newArr || [];
                        setAssetObject({
                            ...assetObject,
                            files: [...oldFiles, ...data]
                        })
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const getCategoriesByLibraryApiCall = async () => {
        setCategoryLoading(true)
        await getCategoriesByLibrary({
            librayId: walletData?.defaultLibrary,
            type: "Asset"
        })
            .then(async (data) => {
                if (data && data.length > 0) {
                    setCategoryLoading(false)
                } else {
                    await createDefaultAssetCategories({
                        library: walletData?.defaultLibrary,
                        profileId: profileParentData?._id,
                        userId: user?._id
                    })
                        .then((datak) => {
                            setCategoryLoading(false)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (walletData?.defaultLibrary) {
            getCategoriesByLibraryApiCall()
        }
    }, [walletData?.defaultLibrary])

    useEffect(() => {
        setValidationObj({})
    }, [assetObject])

    const onSubmit = async (e) => {
        e.preventDefault();
        if (profileParentData && profileParentData?._id) {
            const formData = {
                nameId: assetObject?.nameId,
                category: assetObject?.category,
                purchseCost: assetObject?.purchseCost,
                fullLife: assetObject?.fullLife,
                salvageValue: assetObject?.salvageValue,
            }
            await assetValidationSchema.validate(formData, { abortEarly: false })
                .then(async (data) => {
                    const entityProfileId = profileParentData?._id;
                    setLoader(true)
                    await addAssetApiCall({
                        isAsset: true,
                        name: assetObject?.nameId,
                        condition: assetObject?.condition,
                        type: assetObject?.type,
                        location: assetObject?.location,
                        category: assetObject?.category?._id,
                        assetModel: assetObject?.model,
                        manufacturer: assetObject?.manufacturer,
                        vendor: assetObject?.vendor?._id,
                        purchasePrice: assetObject?.purchseCost,
                        purchaseDate: assetObject?.purchaseDate,
                        warratyExpiration: assetObject?.warratyExpiration,
                        fulLifeInMonth: assetObject?.fullLife,
                        lastMaintenanceDate: assetObject?.lastMaintenanceDate,
                        nextServiceDate: assetObject?.nextServiceDate,
                        salvageValue: assetObject?.salvageValue,
                        notifiyTo: assetObject?.notifiyTo ? assetObject.notifiyTo.map((s) => s._id) : [],
                        files: assetObject?.files ? assetObject?.files.map((s) => s._id) : [],
                        profile: entityProfileId,
                        profileArr: [entityProfileId],
                        shared: [entityProfileId],
                        addedBy: user?.profile,
                        user: user?._id
                    })
                        .then((data) => {
                            setAssetObject(initialObj)
                            setCreateAssetDrawer(false)
                            if (afterAssetsAdd) {
                                afterAssetsAdd(null, false)
                            }
                            setLoader(false)
                            setAlertObj({
                                show: true,
                                message: "Asset successfully added!!!",
                                status: "success"
                            })
                        })
                        .catch((err) => {
                            console.log(err)
                            setLoader(false)
                            setAlertObj({
                                show: true,
                                message: "Something went wrong!!!",
                                status: "error"
                            })
                        })
                })
                .catch((err) => {
                    console.log(err)
                    let errorArr = err?.inner
                    let obj = {}
                    errorArr.map((err) => {
                        obj[err?.path] = err?.message
                    })
                    setValidationObj(obj)
                })
        } else {
            setAlertObj({
                show: true,
                message: "Something went wrong!!!",
                status: "error"
            })
        }
    }

    useEffect(() => {
        setAssetObject({
            ...assetObject,
            category: selectedCategory
        })
    }, [selectedCategory])

    return (<>
        <NormalDrawer
            openDrawer={createAssetDawer}
            setOpenDrawer={setCreateAssetDrawer}
            anchor={"right"}
            width={"55vw"}
            title={"Add Asset"}
            content={
                <form className={classes.mainDialogCont} onSubmit={onSubmit} >
                    <div className={classes.bodyCont} >
                        <div className={classes.sectionLable} >
                            <div>
                                Setp 1: Primary Information
                            </div>
                        </div>
                        <div className={classes.inputBoxCont} >
                            <div className={classes.fullWInput} >
                                <p>Name-ID<span style={{ color: "red" }} >*</span></p>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    placeholder="Enter name id"
                                    autoComplete='off'
                                    value={assetObject?.nameId}
                                    fullWidth
                                    onChange={(e) => {
                                        setAssetObject({
                                            ...assetObject,
                                            nameId: e.target.value
                                        })
                                    }}
                                />
                                <h5 className={classes.validationSty} >{validationObj?.nameId}</h5>
                            </div>
                            <div className={classes.oneThirdWInputAutocomplete} >
                                <p>Condition</p>
                                <Autocomplete
                                    options={ASSET_OPTIONS.condition}
                                    value={assetObject?.condition}
                                    onChange={(event, value) => {
                                        setAssetObject({
                                            ...assetObject,
                                            condition: value
                                        })
                                    }}
                                    fullWidth
                                    size="small"
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            {...params}
                                            placeholder={"Select condition"}
                                        />
                                    )}
                                />
                            </div>
                            <div className={classes.oneThirdWInputAutocomplete} >
                                <p>Type</p>
                                <Autocomplete
                                    options={ASSET_OPTIONS?.type}
                                    value={assetObject?.type}
                                    onChange={(event, value) => {
                                        setAssetObject({
                                            ...assetObject,
                                            type: value
                                        })
                                    }}
                                    fullWidth
                                    size="small"
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            {...params}
                                            placeholder={"Select type"}
                                        />
                                    )}
                                />
                            </div>
                            <div className={classes.oneThirdWInputAutocomplete} >
                                <p>Location</p>
                                <Autocomplete
                                    options={ASSET_OPTIONS?.location}
                                    value={assetObject?.location}
                                    onChange={(event, value) => {
                                        setAssetObject({
                                            ...assetObject,
                                            location: value
                                        })
                                    }}
                                    fullWidth
                                    size="small"
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            size="small"
                                            {...params}
                                            placeholder={"Select location"}
                                        />
                                    )}
                                />
                            </div>
                            <div className={classes.oneThirdWInputAutocomplete} >
                                <p>Category<span style={{ color: "red" }} >*</span></p>
                                {/* we put this code to rerander the autocomplete after default asset category create */}
                                {categoryLoading ? (
                                    <CategoryAutocomplete
                                        libraryId={walletData?.defaultLibrary}
                                        selectedCategories={assetObject?.category}
                                        setSelectedCategories={setSelectedCategory}
                                        isMultiple={false}
                                        type={"Asset"}
                                        profileId={profileParentData?._id}
                                        isCreateNew={true}
                                        placeholder="Select Category"
                                    />
                                ) : (
                                    <CategoryAutocomplete
                                        libraryId={walletData?.defaultLibrary}
                                        selectedCategories={assetObject?.category}
                                        setSelectedCategories={setSelectedCategory}
                                        isMultiple={false}
                                        type={"Asset"}
                                        profileId={profileParentData?._id}
                                        isCreateNew={true}
                                        placeholder="Select Category"
                                    />
                                )}
                                <h5 className={classes.validationSty} >{validationObj?.nameId}</h5>
                            </div>
                            <div className={classes.oneThirdWInput} >
                                <p>Model</p>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    placeholder="Enter model"
                                    autoComplete='off'
                                    value={assetObject?.model}
                                    fullWidth
                                    onChange={(e) => {
                                        setAssetObject({
                                            ...assetObject,
                                            model: e.target.value
                                        })
                                    }}
                                />
                            </div>
                            <div className={classes.oneThirdWInput} >
                                <p>Manufacturer</p>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    placeholder="Enter manufacturer"
                                    autoComplete='off'
                                    value={assetObject?.manufacturer}
                                    fullWidth
                                    onChange={(e) => {
                                        setAssetObject({
                                            ...assetObject,
                                            manufacturer: e.target.value
                                        })
                                    }}
                                />
                            </div>
                            <div className={classes.oneThirdWInput} >
                                <p>Vendor</p>
                                {assetObject?.vendor ? (
                                    <div className={classes.selectedUserOrgSty} onClick={() => { setOpenVendorDrawer(true) }} >
                                        <Avatar
                                            src={assetObject?.vendor?.parent?.displayPicture?.thumbUrl ? assetObject?.vendor?.parent?.displayPicture?.thumbUrl : assetObject?.vendor?.parent?.displayPicture?.url}
                                            alt={assetObject?.vendor?.parent?.displayName}
                                        />
                                        <div style={{ marginLeft: "5px" }} >
                                            <h3>{assetObject?.vendor?.parent?.displayName}</h3>
                                            <p>{assetObject?.vendor?.parent?.username}</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className={classes.addVendorSty} onClick={() => { setOpenVendorDrawer(true) }} >
                                        + Add Vendor
                                    </div>
                                )}
                                <SearchEntityDialog
                                    searchParameter={searchParam}
                                    givenNotAllowedProfileId={[]}
                                    entity={profileParentData?.parentModelName}
                                    curEntityId={profileParentData?.parent?._id}
                                    onSave={(value) => {
                                        setAssetObject({
                                            ...assetObject,
                                            vendor: value
                                        })
                                    }}
                                    selectedProfile={assetObject?.vendor ? [assetObject?.vendor] : []}
                                    noFilter={true}
                                    multiple={false}
                                    open={openVendorDrawer}
                                    setOpen={setOpenVendorDrawer}
                                    onClickOptionHideOptions={true}
                                    financialRelationType={"Vendor"}
                                    title={"Select vendor"}
                                    noPalCreate={false}
                                    allowOutSider={false}
                                />
                            </div>
                        </div>

                        <div className={classes.sectionLable} >
                            <div>
                                Setp 2: Purchase Information
                            </div>
                        </div>
                        <div className={classes.inputBoxCont} >
                            <div className={classes.oneThirdWInput} >
                                <p>Purchse Cost<span style={{ color: "red" }} >*</span></p>
                                <TextFieldNumberFormated
                                    variant="outlined"
                                    size="small"
                                    placeholder="Enter purchse cost"
                                    autoComplete='off'
                                    value={assetObject?.purchseCost}
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                        setAssetObject({
                                            ...assetObject,
                                            purchseCost: e.target.value
                                        })
                                    }}
                                />
                                <h5 className={classes.validationSty} >{validationObj?.purchseCost}</h5>
                            </div>
                            <div className={classes.oneThirdWInput} >
                                <p>Purchase Date</p>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        value={assetObject?.purchaseDate}
                                        fullWidth
                                        margin="normal"
                                        id="due-date-picker"
                                        format="MM/dd/yyyy"
                                        onChange={async (date) => {
                                            setAssetObject({
                                                ...assetObject,
                                                purchaseDate: date
                                            })
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className={classes.oneThirdWInput} >
                                <p>Warraty Expiration</p>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        value={assetObject?.warratyExpiration}
                                        fullWidth
                                        margin="normal"
                                        id="due-date-picker"
                                        format="MM/dd/yyyy"
                                        onChange={async (date) => {
                                            setAssetObject({
                                                ...assetObject,
                                                warratyExpiration: date
                                            })
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className={classes.oneThirdWInput} >
                                <p>Full Life<span style={{ color: "red" }} >*</span></p>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    placeholder="Enter full life"
                                    autoComplete='off'
                                    type="number"
                                    value={assetObject?.fullLife}
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                Month(s)
                                            </InputAdornment>
                                        )
                                    }}
                                    onChange={(e) => {
                                        setAssetObject({
                                            ...assetObject,
                                            fullLife: e.target.value
                                        })
                                    }}
                                />
                                <h5 className={classes.validationSty} >{validationObj?.fullLife}</h5>
                            </div>
                            <div className={classes.oneThirdWInput} >
                                <p>Last Maintenance Date</p>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        value={assetObject?.lastMaintenanceDate}
                                        fullWidth
                                        margin="normal"
                                        id="due-date-picker"
                                        format="MM/dd/yyyy"
                                        onChange={async (date) => {
                                            setAssetObject({
                                                ...assetObject,
                                                lastMaintenanceDate: date
                                            })
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                            <div className={classes.oneThirdWInput} >
                                <p>Next Service Date</p>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        value={assetObject?.nextServiceDate}
                                        fullWidth
                                        margin="normal"
                                        id="due-date-picker"
                                        format="MM/dd/yyyy"
                                        onChange={async (date) => {
                                            setAssetObject({
                                                ...assetObject,
                                                nextServiceDate: date
                                            })
                                        }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>

                        <div className={classes.sectionLable} >
                            <div>
                                Setp 3: Depreciation Expense
                            </div>
                        </div>
                        <div className={classes.depricationInputBoxCont} >
                            <div className={classes.oneFourthWInput} >
                                <p>Asset Cost</p>
                                <TextFieldNumberFormated
                                    variant="outlined"
                                    size="small"
                                    placeholder="Enter purchse cost"
                                    autoComplete='off'
                                    value={assetObject?.purchseCost}
                                    style={{ width: "100%" }}
                                    disabled
                                />
                            </div>
                            <div className={classes.symbolSty} >-</div>
                            <div className={classes.oneFourthWInput} >
                                <p>Salvage Value<span style={{ color: "red" }} >*</span></p>
                                <TextFieldNumberFormated
                                    variant="outlined"
                                    size="small"
                                    placeholder="Enter purchse cost"
                                    autoComplete='off'
                                    value={assetObject?.salvageValue}
                                    style={{ width: "100%" }}
                                    onChange={(e) => {
                                        setAssetObject({
                                            ...assetObject,
                                            salvageValue: e.target.value
                                        })
                                    }}
                                />
                                <h5 className={classes.validationSty} >{validationObj?.salvageValue}</h5>
                            </div>
                            <div className={classes.symbolSty} >/</div>
                            <div className={classes.oneFourthWInput} >
                                <p>Use-Full Life</p>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    placeholder="Enter full life"
                                    autoComplete='off'
                                    type="number"
                                    value={assetObject?.fullLife}
                                    fullWidth
                                    disabled
                                />
                            </div>
                            <div className={classes.symbolSty} >=</div>
                            <div className={classes.oneFourthWInput} >
                                <p>Depreciation Expense</p>
                                <TextFieldNumberFormated
                                    variant="outlined"
                                    size="small"
                                    placeholder="Enter purchse cost"
                                    autoComplete='off'
                                    value={assetObject?.depreciationExpense}
                                    style={{ width: "100%" }}
                                    disabled
                                />
                            </div>
                        </div>
                        <div className={classes.sectionLable} style={{ marginBottom: "15px" }} >
                            <div>
                                Setp 3: Notification  Alert
                            </div>
                        </div>
                        <div style={{ padding: "0px 10px" }} >
                            {assetObject?.notifiyTo && assetObject.notifiyTo.length > 0 ? (
                                <div className={classes.selectedParticipantsCont}>
                                    {assetObject?.notifiyTo && assetObject.notifiyTo.map((selectedProfile) => (<div className={classes.selectedUserOrgSty} >
                                        <Avatar
                                            src={selectedProfile?.parent?.displayPicture?.thumbUrl ? selectedProfile?.parent?.displayPicture?.thumbUrl : selectedProfile?.parent?.displayPicture?.url}
                                            alt={selectedProfile?.parent?.displayName}
                                        />
                                        <div style={{ marginLeft: "5px" }} >
                                            <h3>{selectedProfile?.parent?.displayName}</h3>
                                            <p>{selectedProfile?.parent?.username}</p>
                                        </div>
                                        <IconButton
                                            onClick={() => {
                                                const filteredEntities = assetObject.notifiyTo.filter((entityProfile) => entityProfile?._id != selectedProfile?._id)
                                                setAssetObject({
                                                    ...assetObject,
                                                    notifiyTo: filteredEntities
                                                })
                                            }}
                                        >
                                            <CancelIcon />
                                        </IconButton>
                                    </div>))}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<AddIcon />}
                                        onClick={() => {
                                            setOpenNotifySelectDrawer(true)
                                        }}
                                    >
                                        Add User
                                    </Button>
                                </div>
                            ) : (
                                <div
                                    className={classes.usersCont}
                                    onClick={() => {
                                        setOpenNotifySelectDrawer(true)
                                    }}
                                >
                                    + Add User(s)
                                </div>
                            )}
                        </div>
                        <SearchEntityDialog
                            searchParameter={usersOnlySearchParam}
                            entity={profileParentData?.parentModelName}
                            curEntityId={profileParentData?.parent?._id}
                            onSave={(value) => {
                                setAssetObject({
                                    ...assetObject,
                                    notifiyTo: value
                                })
                            }}
                            selectedProfile={assetObject?.notifiyTo && assetObject.notifiyTo.length > 0 ? assetObject.notifiyTo : []}
                            noFilter={true}
                            multiple={true}
                            open={openNotifySelectDrawer}
                            setOpen={setOpenNotifySelectDrawer}
                            onClickOptionHideOptions={true}
                            financialRelationType={"Admin"}
                            title={"Select users(s)"}
                            noPalCreate={true}
                        />
                        <div className={classes.sectionLable} style={{ marginBottom: "15px" }} >
                            <div>
                                Setp 4: Upload Pictures
                            </div>
                        </div>
                        <div style={{ padding: "0px 10px" }} >
                            {assetObject?.files && assetObject.files.length > 0 && (
                                <div className={classes.allImagesCont}>
                                    {assetObject.files.map((file) => (<div className={classes.imageCardCont} >
                                        <div
                                            className={classes.imageCard}
                                            style={{ backgroundImage: `url(${file?.thumbUrl ? file?.thumbUrl : file?.url})` }}
                                        >
                                            <IconButton
                                                className={classes.closeBtn}
                                                onClick={() => {
                                                    const filteredFiles = assetObject.files.filter((entityProfile) => entityProfile?._id != file?._id)
                                                    setAssetObject({
                                                        ...assetObject,
                                                        files: filteredFiles
                                                    })
                                                }}
                                            >
                                                <CancelIcon />
                                            </IconButton>
                                        </div>
                                    </div>))}
                                </div>
                            )}
                            <CustomFileUploadButton
                                showComponent={
                                    <div className={classes.usersCont} >
                                        + Add Picture(s)
                                    </div>
                                }
                                parentType={"Offering"}
                                parentId={null}
                                fileNum={25}
                                givenFileTypes={["image", "video"]}
                                givenMaxSize={26214400}
                                closeFunCall={afterFileAdded}
                            />
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "5px" }} >
                        {loader && (<LinearProgress />)}
                    </div>
                    <div className={classes.bottomAct} >
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            type="submit"
                        >
                            Save & Close
                        </Button>
                    </div>
                </form>
            }
        />
        <NotifyAlert
            alertObj={alertObj}
            setAlertObj={setAlertObj}
        />
    </>);
}