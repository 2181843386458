import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { getOfferingsByLibrary } from './Api.call';
import LinearProgress from '@material-ui/core/LinearProgress';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from "@material-ui/lab/Pagination";
import { IconButton } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MyPopOver from "../styled/CommonComponents/MyPopOver";

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100%",
        "& table": {
            tableLayout: "auto"
        },
        "& .MuiTableCell-sizeSmall": {
            padding: "0px 10px"
        }
    },
    tableContainer: {
        width: "100%",
        height: "calc(100% - 54px)",
        "& tr": {
            height: "45px"
        },
        "&::-webkit-scrollbar": {
            display: "none"
        },
        "& table": {
            tableLayout: "auto"
        }
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderTop: "1px solid #ececec",
    },
    singleOption: {
        padding: "4px 15px",
        fontSize: "15px",
        fontWeight: "500",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ececec"
        }
    },
    iconBtn: {
        "& .MuiSvgIcon-root": {
            color: "black"
        }
    },
}));

export default function OfferingsByLibrary({
    libraryId, offeringSearchStr, setOfferingSearchStr,
    offeringAction, allOfferings, setAllOfferings, profileId,
    wallet
}) {
    const classes = useStyles();
    const theme = useTheme();
    const { user } = useSelector((state) => state.auth);

    const [loader, setLoader] = useState(false)
    const [offerings, setOfferings] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(15)

    useEffect(() => {
        setOfferingSearchStr("")
        if (libraryId) {
            setLoader(true)
            getOfferingsByLibrary({
                libraryId,
                profileId,
                type: "both"
            })
                .then((data) => {
                    if (data && data.length > 0) {
                        setOfferings(data)
                        setAllOfferings(data)
                        let totalNumbers = data.length || 0
                        let locTotalPage = Math.ceil(totalNumbers / pageLimit);
                        setTotalPages(locTotalPage)
                    } else {
                        setOfferings([])
                        setAllOfferings([])
                        setTotalPages(0)
                    }
                    setLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [libraryId])

    //for search
    useEffect(() => {
        setLoader(true)
        let filteredData = []
        allOfferings.map((data) => {
            const patt = new RegExp(offeringSearchStr, "i");
            const res = patt.test(data?.name);
            if (res) {
                filteredData.push(data);
            }
        });
        let totalNumbers = filteredData.length || 0
        let locTotalPage = Math.ceil(totalNumbers / pageLimit);
        setTotalPages(locTotalPage)
        setCurPage(1)
        setOfferings(filteredData)
        setLoader(false)
    }, [offeringSearchStr, allOfferings])

    return (
        <div className={classes.mainCont} >
            <div style={{ width: "100%", height: "5px" }} >
                {loader && (<LinearProgress />)}
            </div>
            <TableContainer className={classes.tableContainer}>
                <Table stickyHeader aria-label="sticky table" size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{ minWidth: "230px" }} >
                                Name
                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "120px" }} >
                                Code
                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "170px" }} >
                                Price
                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "70px" }} >
                                Unit
                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "220px" }} >
                                Income Chart Account
                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "100px" }} >
                                Tax
                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "170px" }} >
                                Purchase price
                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "70px" }} >
                                Unit
                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "220px" }} >
                                Expense Chart Account
                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "80px" }} >
                                Type
                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "150px" }} >
                                Category
                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "150px" }} >
                                Add By
                            </TableCell>
                            <TableCell align="center" style={{ minWidth: "50px" }} >
                                Action
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {offerings && offerings.length > 0 && offerings.slice((curPage - 1) * pageLimit, (curPage - 1) * pageLimit + pageLimit).map((data) => {
                            return (
                                <TableRow>
                                    <TableCell align="left" >
                                        {data?.name}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {data?.code}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {`₹${data?.price}`}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {data?.unit}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {data?.incomeChartAccount?.name}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {data?.taxType === "%" ? `${data?.tax}%` : `₹${data?.tax}`}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {`₹${data?.purchasePrice}`}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {data?.purchaseUnit}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {data?.expenseChartAccount?.name}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {data?.model}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {data?.category?.name}
                                    </TableCell>
                                    <TableCell align="center" >
                                        {data?.isPublic ? "RealList" : data?.user?.displayName}
                                    </TableCell>
                                    <TableCell align="center" style={{ minWidth: "50px" }} >
                                        <MyPopOver
                                            closeOnClick={true}
                                            appearContent={<span className={classes.iconBtn} >
                                                <IconButton>
                                                    <MoreVertIcon />
                                                </IconButton>
                                            </span>}
                                            showContent={<div style={{ padding: "5px 0px" }} >
                                                <div
                                                    className={classes.singleOption}
                                                    onClick={() => {
                                                        offeringAction("edit", data)
                                                    }}
                                                >
                                                    Edit
                                                </div>
                                                {data?.doSell && data?.incomeChartAccount?._id && wallet?.defaultIncomeOfferingRelation != data?.offeringRelation?._id && (
                                                    <div
                                                        className={classes.singleOption}
                                                        onClick={() => {
                                                            offeringAction("setDefaultIncome", data)
                                                        }}
                                                    >
                                                        Default Income Offering
                                                    </div>
                                                )}
                                                {data?.doPurchase && data?.expenseChartAccount?._id && wallet?.defaultExpansesOfferingRelation != data?.offeringRelation?._id && (
                                                    <div
                                                        className={classes.singleOption}
                                                        onClick={() => {
                                                            offeringAction("setDefaultExpense", data)
                                                        }}
                                                    >
                                                        Default Expense Offering
                                                    </div>
                                                )}
                                                <div
                                                    className={classes.singleOption}
                                                    onClick={() => {
                                                        offeringAction("delete", data)
                                                    }}
                                                >
                                                    Delete
                                                </div>
                                            </div>}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <div className={classes.paginationCont} >
                <Pagination
                    count={totalPages}
                    page={curPage}
                    color="primary"
                    siblingCount={0}
                    onChange={(event, value) => { setCurPage(value) }}
                />
            </div>
        </div>
    );
}