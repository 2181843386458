import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { TextField, Button, InputAdornment } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import iconsGoogle from "../../../Assets/iconsGoogle.svg";
import HeroBuildings from "../../../Assets/HeroBuildings.svg";
import { Link } from "react-router-dom";
import Collapse from '@material-ui/core/Collapse';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import {
    handleGoogleLogin, handleEmailPasswordLogin,
    handleEmailPasswordSignup
} from "../../auth/auth.utils";
import ClearIcon from '@material-ui/icons/Clear';
import "aos/dist/aos.css";
import "../../auth/auth.style.css";

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
const signupSchema = yup.object().shape({
    fname: yup.string().min(2, 'First name should be 2 chars minimum.').max(50, 'First name should have less then 50 chars.').required("First name should be required"),
    lname: yup.string().max(50, 'Last name should have less then 50 chars.'),
    email: yup.string().email("Not a proper email").required("Mail id should be required"),
    password: yup.string().min(8, 'Too short - should be 8 chars minimum.').matches(passwordRules, { message: "Please create a stronger password" }).required("Pssword should be required"),
});
const signinSchema = yup.object().shape({
    email: yup.string().email("Not a proper email").required("Mail id should be required"),
    password: yup.string().min(8, 'Too short - should be 8 chars minimum.').matches(passwordRules, { message: "Please create a stronger password" }).required("Pssword should be required"),
});

const useStyles = makeStyles((theme) => ({
    root: {
        height: "calc(100vh - 70px)",
        width: "100%",
        padding: "0px 180px 0px",
        display: "flex",
        justifyContent: "space-between",
        background: "linear-gradient(48deg, #FFFFFF 70%, #E8F1FF 100%)",
        [theme.breakpoints.down("lg")]: {
            padding: "0px 110px 0px",
        },
        [theme.breakpoints.down("md")]: {
            padding: "50px 50px 80px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "auto"
        },
        [theme.breakpoints.down("sm")]: {
            padding: "30px 30px 80px",
            //have to add height

        },
        [theme.breakpoints.down("xs")]: {
            padding: "20px 20px 80px",
            //have to add height

        },
    },
    textImageCont: {
        width: "55%",
        paddingRight: "30px",
        paddingTop: "50px",
        "& div": {
            width: "90%"
        },
        "& h3": {
            fontSize: "45px",
            fontWeight: "700",
            marginBottom: "25px"
        },
        "& p": {
            fontSize: "18px",
            fontWeight: "450"
        },
        "& img": {
            height: "calc(100vh - 390px)",
            maxWidth: "100%",
            marginTop: "40px"
        },
        [theme.breakpoints.down("md")]: {
            width: "100%",
            padding: "0px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            "& h3": {
                fontSize: "38px",
                fontWeight: "650",
            },
            "& p": {
                fontSize: "17px",
            },
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            "& h3": {
                fontSize: "30px",
                fontWeight: "600",
            },
            "& p": {
                fontSize: "16px",
                fontWeight: "400"
            },
            "& img": {
                marginTop: "10px"
            },
        }
    },
    singInSignUpCont: {
        width: "45%",
        paddingRight: "30px",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            width: "100%",
            justifyContent: "center",
            marginTop: "60px",
            paddingRight: "0px",
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "0px",
        }
    },
    rigthSide: {
        width: "430px",
        overflow: "hidden",
        backgroundColor: "white",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        borderRadius: "7px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]: {
            width: "300px",
            width: "95%",
        }
    },
    logoTitleCont: {
        display: "flex",
        alignItems: "center",
        marginBottom: "30px",
        "& img": {
            width: "52px",
        },
        "& h3": {
            fontSize: "28px",
            color: theme.palette.primary.main
        }
    },
    logoText: {
        display: "flex",
        alignItems: "center",
        marginBottom: "20px",
        "& img": {
            width: "50px",
            height: "auto"
        },
        "& h2": {
            fontSize: "26px",
            color: theme.palette.primary.main,
            fontWeight: "700",
            marginLeft: "5px"
        }
    },
    nameCont: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "15px"
    },
    loginCont: {
        width: "100%",
        marginTop: "20px"
    },
    signupCont: {
        width: "100%",
        marginTop: "20px"
    },
    topAllCont: {
        width: "100%",
        padding: "20px 30px 0px"
    },
    btnTC: {
        width: "100%",
        textAlign: "center",
        padding: "5px 15px",
        fontSize: "13px",
        backgroundColor: "#d3ecff",
        marginTop: "30px"
    },
    linkStyle: {
        textDecoration: "none",
        color: "blue",
    },
    btnSty: {
        width: "100%",
        marginTop: "20px",
        padding: "8px 0px",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        }
    },
    icon: {
        width: "20px",
        marginRight: "10px",
    },
    googleBtnSty: {
        border: "1px solid gray",
        width: "100%",
        marginTop: "20px",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        }
    },
    userNotExist: {
        width: "100%",
        height: "40px",
        marginBottom: "15px",
    },
    userNotCont: {
        width: "100%",
        height: "100%",
        borderRadius: "5px",
        padding: "0px 12px",
        backgroundColor: "rgba(255,0,0,0.2)",
        color: "#3c3232",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "15px",
        "& p": {
            fontSize: "14px"
        }
    },
    otherSlideBtn: {
        borderTop: "1px solid #c9c8c8",
        paddingTop: "20px",
        marginTop: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        position: "relative",
        "& div": {
            position: "absolute",
            bottom: "31px",
            backgroundColor: "white",
            padding: "0px 7px",
            fontSize: "16px"
        },
        "& p": {
            color: "gray",
            fontSize: "14px"
        },
        "& a": {
            textDecoration: "none",
            color: theme.palette.primary.main,
            cursor: "pointer",
        }
    },
    titleSty: {
        fontSize: "20px"
    }
}));

export default function HomePage({ pageRef }) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const location = useLocation();
    const dispatch = useDispatch();

    const [boolVal, setBoolVal] = useState(true)
    const [sIshowPassword, setSIshowPassword] = useState(false)
    const [sUshowPassword, setSUIshowPassword] = useState(false)
    const [loginMail, setLoginMail] = useState("")
    const [loginPassword, setLoginPassword] = useState("")
    const [signupFName, setSignupFName] = useState("")
    const [signupLName, setSignupLName] = useState("")
    const [signupMail, setSignupMail] = useState("")
    const [signupPassword, setSignupPassword] = useState("")
    const [loginErrorObj, setLoginErrorObj] = useState({})
    const [signupErrorObj, setSignupErrorObj] = useState({})
    const [noUserExist, setNoUserExist] = useState(false)

    const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));

    useEffect(() => {
        setLoginErrorObj({});
    }, [loginMail, loginPassword]);

    useEffect(() => {
        setSignupErrorObj({});
    }, [signupFName, signupLName, signupMail, signupPassword]);

    const onSignupSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            fname: signupFName,
            lname: signupLName,
            email: signupMail,
            password: signupPassword
        };

        await signupSchema
            .validate(formData, { abortEarly: false })
            .then(async (data) => {
                const displayName = `${signupFName} ${signupLName}`
                await handleEmailPasswordSignup(dispatch, signupMail, signupPassword, displayName,true,signupFName,signupLName);
            })
            .catch((err) => {
                let errorArr = err?.inner;
                let obj = {};
                errorArr.map((err) => {
                    obj[err?.path] = err?.message;
                });
                setSignupErrorObj(obj);
            });
    };

    const userNotFoundErr = () => {
        setNoUserExist(true)
    }

    const onLoginSubmit = async (e) => {
        e.preventDefault();
        const formData = {
            email: loginMail,
            password: loginPassword
        };

        await signinSchema
            .validate(formData, { abortEarly: false })
            .then(async (data) => {

                await handleEmailPasswordLogin(dispatch, loginMail, loginPassword, userNotFoundErr);

            })
            .catch((err) => {
                let errorArr = err?.inner;
                let obj = {};
                errorArr.map((err) => {
                    obj[err?.path] = err?.message;
                });
                setLoginErrorObj(obj);
            });
    };


    return (
        <section className={classes.root} ref={pageRef} id="homePage" >
            <div className={classes.textImageCont} data-aos={isExSmall ? "" : "fade-right"} >
                <div>
                    <h3>Real Estate projects made <span style={{ color: "#2D76E0" }} >Transparent</span> and <span style={{ color: "#2D76E0" }} >profitable</span></h3>
                    <p>Join our real estate community to work and network with real estate developers, investors and professionals</p>
                </div>
                <img src={HeroBuildings} />
            </div>

            <div className={classes.singInSignUpCont} >
                <div className={classes.rigthSide} data-aos={isExSmall ? "" : "fade-left"} >
                    <div className={classes.topAllCont} >
                        <h3 className={classes.titleSty} >Welcome, to RealList</h3>
                        <Collapse in={!boolVal} collapsedSize={0}>
                            <div className={classes.loginCont} >
                                {noUserExist && (
                                    <div className={classes.userNotCont} >
                                        <p>Incorrect mail id or password</p>
                                        <ClearIcon
                                            style={{
                                                fontSize: "20px",
                                                cursor: "pointer"
                                            }}
                                            onClick={() => { setNoUserExist(false) }}
                                        />
                                    </div>
                                )}
                                <form onSubmit={onLoginSubmit} >
                                    <TextField
                                        placeholder="Email"
                                        style={{ width: "100%" }}
                                        value={loginMail}
                                        onChange={(e) => { setLoginMail(e.target.value); }}
                                    />
                                    <p style={{ color: "#EE1D52", fontSize: "12px", marginBottom: "15px" }}>
                                        {loginErrorObj?.email && (<>{loginErrorObj?.email}</>)}
                                    </p>
                                    <TextField
                                        type={sIshowPassword ? "text" : "password"}
                                        placeholder="Password"
                                        style={{ width: "100%" }}
                                        InputProps={{
                                            endAdornment: (<InputAdornment position="end">
                                                {sIshowPassword ? (
                                                    <VisibilityOffIcon
                                                        style={{ color: "gray", cursor: "pointer" }}
                                                        onClick={() => { setSIshowPassword(!sIshowPassword) }}
                                                    />
                                                ) : (
                                                    <VisibilityIcon
                                                        style={{ color: "gray", cursor: "pointer" }}
                                                        onClick={() => { setSIshowPassword(!sIshowPassword) }}
                                                    />
                                                )}
                                            </InputAdornment>)
                                        }}
                                        value={loginPassword}
                                        onChange={(e) => { setLoginPassword(e.target.value); }}
                                    />
                                    {loginErrorObj?.password ? (
                                        <p style={{ color: "#EE1D52", fontSize: "12px", marginBottom: "15px" }}>
                                            {loginErrorObj?.password}
                                        </p>
                                    ) : (<p style={{ color: "gray", fontSize: "12px", marginBottom: "15px", marginTop: "10px" }} >
                                        *Must contain at least one uppercase, one lowercase, one numerical character and one special character. Minimum 8 characters.
                                    </p>)}

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.btnSty}
                                        type="submit"
                                    >
                                        Sign In
                                    </Button>
                                    <Button
                                        className={classes.googleBtnSty}
                                        onClick={(el) => { handleGoogleLogin(dispatch) }}
                                    >
                                        <img className={classes.icon} src={iconsGoogle} alt="google" />{" "}
                                        Sign In using Google
                                    </Button>
                                </form>
                                <div className={classes.otherSlideBtn} >
                                    <div>or</div>
                                    <p>Don't have an account? <a
                                        onClick={() => { setBoolVal(true) }}
                                    >Sign up</a></p>
                                </div>
                            </div>
                        </Collapse>
                        <Collapse in={boolVal} collapsedSize={0}>
                            <form onSubmit={onSignupSubmit} className={classes.signupCont} >
                                <div className={classes.nameCont} >
                                    <div style={{ width: "47%" }} >
                                        <TextField
                                            placeholder="First name"
                                            style={{ width: "100%" }}
                                            value={signupFName}
                                            onChange={(e) => { setSignupFName(e.target.value); }}
                                        />
                                        {signupErrorObj?.fname && (
                                            <p style={{ color: "#EE1D52", fontSize: "12px" }}>
                                                {signupErrorObj?.fname}
                                            </p>
                                        )}
                                    </div>
                                    <div style={{ width: "47%" }} >
                                        <TextField
                                            placeholder="Last name"
                                            style={{ width: "100%" }}
                                            value={signupLName}
                                            onChange={(e) => { setSignupLName(e.target.value); }}
                                        />
                                        {signupErrorObj?.lname && (
                                            <p style={{ color: "#EE1D52", fontSize: "12px" }}>
                                                {signupErrorObj?.lname}
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <TextField
                                    placeholder="Email"
                                    style={{ width: "100%" }}
                                    value={signupMail}
                                    onChange={(e) => { setSignupMail(e.target.value); }}
                                />
                                <p style={{ color: "#EE1D52", fontSize: "12px", marginBottom: "15px" }}>
                                    {signupErrorObj?.email && (<>{signupErrorObj?.email}</>)}
                                </p>
                                <TextField
                                    placeholder="Password"
                                    style={{ width: "100%" }}
                                    type={sUshowPassword ? "text" : "password"}
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end">
                                            {sUshowPassword ? (
                                                <VisibilityOffIcon
                                                    style={{ color: "gray", cursor: "pointer" }}
                                                    onClick={() => { setSUIshowPassword(!sUshowPassword) }}
                                                />
                                            ) : (
                                                <VisibilityIcon
                                                    style={{ color: "gray", cursor: "pointer" }}
                                                    onClick={() => { setSUIshowPassword(!sUshowPassword) }}
                                                />
                                            )}
                                        </InputAdornment>)
                                    }}
                                    value={signupPassword}
                                    onChange={(e) => { setSignupPassword(e.target.value); }}
                                />
                                {signupErrorObj?.password ? (
                                    <p style={{ color: "#EE1D52", fontSize: "12px", marginBottom: "15px" }}>
                                        {signupErrorObj?.password}
                                    </p>
                                ) : (<p style={{ color: "gray", fontSize: "12px", marginBottom: "15px", marginTop: "10px" }} >
                                    *Must contain at least one uppercase, one lowercase, one numerical character and one special character. Minimum 8 characters.
                                </p>)}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    className={classes.btnSty}
                                >
                                    Sign Up
                                </Button>
                                <Button
                                    className={classes.googleBtnSty}
                                    onClick={(el) => { handleGoogleLogin(dispatch) }}
                                >
                                    <img className={classes.icon} src={iconsGoogle} alt="google" />{" "}
                                    Sign Up using Google
                                </Button>
                                <div className={classes.otherSlideBtn} >
                                    <div>or</div>
                                    <p>Already have an account? <a
                                        onClick={() => { setBoolVal(false) }}
                                    >Sign in</a></p>
                                </div>
                            </form>
                        </Collapse>
                    </div>
                    <div className={classes.btnTC} >
                        By signing up, you agree to the{" "}
                        <Link className={classes.linkStyle} to="/terms">
                            Terms of Service
                        </Link>{" "}
                        and{" "}
                        <Link className={classes.linkStyle} to="/terms">
                            Privacy Policy
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
}