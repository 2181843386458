import { useState, useEffect } from "react";
import socket from "./socketio";
import { useSelector, useDispatch } from "react-redux";
import { topNotification } from "../../components/activity/activity.utils";
import { activityHandler } from "./activityHandler";

function useSocketHook(profileId) {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const activityReducer = useSelector((state) => state.activity);
  const { user } = state?.auth;
  useEffect(() => {
    const path = "profile/" + profileId;
    socket.removeListener(path);
    // socket.on("connected", (data) => {
    //   console.log("Socket connected: ", data);
    //   console.log(path);
    // });
    socket.on(path, (notification) => {
      console.log("Socket here: ", { notification, activityReducer });
      topNotification(notification, activityReducer, dispatch);
    });
  }, [
    profileId,
    activityReducer?.topNotificationIds?.length,
    activityReducer?.activityDictionary,
  ]);
}

export default useSocketHook;
