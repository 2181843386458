import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import { getCategoriesByLibrary } from "./Api.call";
import CategoryCreateDrawer from "./Category.Crearte.Drawer";
import HierarchicalAutocomplete from "../styled/CommonComponents/Hierarchical.Autocomplete";

const useStyles = makeStyles((theme) => ({}));
export default function CategoryAutocomplete({
  libraryId,
  selectedCategories,
  setSelectedCategories,
  type = "Common",
  isMultiple = false,
  label,
  placeholder,
  isCreateNew,
  profileId,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const [allCategories, setAllCategories] = useState([]);
  const [createCategoryDawer, setCreateCategoryDrawer] = useState(false);
  const [currentLibraryCategory, setCurrentLibraryCategory] = useState({
    library: {
      _id: libraryId,
    },
  });

  async function getCategoriesByLibraryApiCall() {
    await getCategoriesByLibrary({
      librayId: libraryId,
      type: type,
    })
      .then((data) => {
        if (isCreateNew) {
          const obj = {
            _id: "new",
            name: "+ Add New",
            parent: null,
            depth: 0,
            numChildren: 0,
          };
          setAllCategories([obj, ...data]);
        } else {
          setAllCategories(data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getCategoriesByLibraryApiCall();
  }, [libraryId]);

  return (
    <>
      <HierarchicalAutocomplete
        lableKey={"name"}
        options={allCategories}
        value={selectedCategories}
        setValue={setSelectedCategories}
        rootId={"_id"}
        parentId={"parent"}
        isMultiple={isMultiple}
        label={label}
        onChange={(e, v) => {
          if (v && v.length > 0 && v[v.length - 1]._id === "new") {
            setCreateCategoryDrawer(true);
          } else {
            if (v?._id === "new") {
              setCreateCategoryDrawer(true);
            } else {
              setSelectedCategories(v);
            }
          }
        }}
        placeholder={placeholder}
      />
      <CategoryCreateDrawer
        createCategoryDawer={createCategoryDawer}
        setCreateCategoryDrawer={setCreateCategoryDrawer}
        profileId={profileId}
        categoryType="Common"
        afterCategoryCreateOrEdit={(value) => {
          if (value) {
            setAllCategories([...allCategories, value]);
            if (isMultiple) {
              setSelectedCategories([...selectedCategories, value]);
            } else {
              setSelectedCategories(value);
            }
            setCreateCategoryDrawer(false);
          }
        }}
        currentLibraryCategory={currentLibraryCategory}
      />
    </>
  );
}
