import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles"
import { Button, TextField, InputAdornment, CircularProgress } from "@material-ui/core"
import { useTheme } from "styled-components";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AuditHistory from "./AuditHistory";
import Dashboard from "./Dashboard";
import emptyPage from "../../../Assets/EmptyPageSVG.svg"
import { getProfileParent } from "../api.call";
import { getWalletDataByProfileId } from "../../finance2o/accounts/api.call";

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "white"
    },
    headerCont: {
        width: "100%",
        height: "55px",
        borderBottom: "1px solid #ececec",
        padding: "0px 15px",
        display: "flex",
        alignItems: "center",
        "& h3": {
            fontSize: "19px",
            fontWeight: "600"
        }
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 60px)",
        overflow: "hidden",
        padding: "10px 20px 20px"
    },
    pageCont: {
        width: "100%",
        height: "calc(100vh - 115px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "10px 0px 0px"
    },
    emptyLibrarayCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            width: "200px",
            height: "auto",
        },
        "& div": {
            width: "100%",
            marginTop: "10px",
            padding: "0px 20px",
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "400",
            color: "gray",
            textAlign: "center",
        },
        "& a": {
            textDecoration: "none",
            fontSize: "14px",
            marginLeft: "5px",
            cursor: "pointer",
            color: "blue"
        }
    },
}));

const AssetsHomePage = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const { profileId } = useParams();

    const { user } = useSelector((state) => state.auth);

    const [curPage, setCurPage] = useState(0);
    const [profileParentData, setProfileParentData] = useState(null)
    const [walletData, setWalletData] = useState(null)
    const [loader, setLoader] = useState(true)

    const handleChange = (event, newValue) => {
        setCurPage(newValue);
    };

    useEffect(() => {
        if (profileId && profileId !== null && profileId != undefined) {
            setLoader(true)
            getWalletDataByProfileId({ profileId: profileId })
                .then(async (data1) => {
                    setWalletData(data1)
                    if (data1?.defaultLibrary) {
                        await getProfileParent({ profileId: profileId })
                            .then((data) => {
                                setProfileParentData(data);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                    setLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [profileId])


    return (<div className={classes.mainCont} >
        {loader ? (<div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }} >
            <CircularProgress />
        </div>) : (<>{walletData && walletData?.defaultLibrary ? (<>
            <div className={classes.headerCont} >
                <h3>Asset Management</h3>
            </div>
            <div className={classes.bodyCont} >
                <Tabs
                    value={curPage}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleChange}
                >
                    <Tab label="Dashboard" />
                    <Tab label="Audit History" />
                </Tabs>

                {curPage === 0 && (<div className={classes.pageCont} >
                    <Dashboard
                        profileParentData={profileParentData}
                        profileId={profileId}
                        walletData={walletData}
                    />
                </div>)}
                {curPage === 1 && (<div className={classes.pageCont} >
                    <AuditHistory
                        profileParentData={profileParentData}
                    />
                </div>)}
            </div>
        </>) : (<div className={classes.emptyLibrarayCont} >
            <img src={emptyPage} />
            <div>
                <p>
                    No Library exists in the current directory or any library still didn't selected as default library.<br />
                    Click <a
                        onClick={() => {
                            history.push(`/offerings-categories/management/${profileId}`)
                        }}
                    >here</a> to create and manage your library(s), category(s), product(s) and service(s)
                </p>
            </div>
        </div>)}</>)}
    </div>);
};

export default AssetsHomePage;