import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import { createLibrary, updateLibraryById } from './Api.call';

const useStyles = makeStyles((theme) => ({
    mainDialogCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "& .MuiButton-label": {
            textTransform: "capitalize",
            fontSize: "14px"
        }
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 55px)",
        overflow: "hidden",
        padding: "15px"
    },
    bottomAct: {
        width: "100%",
        height: "50px",
        padding: "0px 7px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        borderTop: "1px solid #ececec"
    }
}));

const defaultLibraryObj = {
    name: ""
}

export default function LibraryCreateDrawer({
    createLibraryDawer, setCreateLibraryDrawer,
    profileId, afterCreateOrEditNewLibrary, curEditDeleteLibrary
}) {
    const classes = useStyles();
    const theme = useTheme();
    const { user } = useSelector((state) => state.auth);

    const [libraryObj, setLibraryObj] = useState(defaultLibraryObj)
    const [loader, setLoader] = useState(false)
    const [changeState, setChangeState] = useState(false)

    useEffect(() => {
        if (curEditDeleteLibrary?._id) {
            setLibraryObj({
                name: curEditDeleteLibrary?.name
            })
        } else {
            setLibraryObj(defaultLibraryObj)
        }
    }, [curEditDeleteLibrary, createLibraryDawer])

    const onSubmit = async (e) => {
        setLoader(true)
        e.preventDefault();
        if (curEditDeleteLibrary?._id) {
            const updatedObj = {
                ...curEditDeleteLibrary,
                ...libraryObj
            }

            await updateLibraryById(updatedObj)
                .then((data) => {
                    if (data) {
                        afterCreateOrEditNewLibrary(data, true)
                        setLibraryObj(defaultLibraryObj)
                    }
                    setLoader(false)
                    setCreateLibraryDrawer(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        } else {
            const createObj = {
                name: libraryObj.name,
                public: false,
                country: "India",
                countryWide: true,
                profile: profileId,
                user: user._id
            }
            await createLibrary(createObj)
                .then((data) => {
                    if (data) {
                        afterCreateOrEditNewLibrary(data, false)
                        setLibraryObj(defaultLibraryObj)
                    }
                    setLoader(false)
                    setCreateLibraryDrawer(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    return (
        <NormalDrawer
            openDrawer={createLibraryDawer}
            setOpenDrawer={setCreateLibraryDrawer}
            anchor={"right"}
            title={curEditDeleteLibrary?._id ? "Edit Library" : "Create Library"}
            content={
                <form className={classes.mainDialogCont} onSubmit={onSubmit} >
                    <div className={classes.bodyCont} >
                        <TextField
                            label={<>Title<span style={{ color: "red" }}>*</span></>}
                            placeholder={"Enter library title"}
                            value={libraryObj.name}
                            onChange={(e) => {
                                const obj = { ...libraryObj }
                                obj.name = e.target.value;
                                setLibraryObj(obj)
                                setChangeState(!changeState)
                            }}
                            fullWidth
                            size="small"
                            variant="outlined"
                        />
                    </div>
                    <div style={{ width: "100%", height: "5px" }} >
                        {loader && (<LinearProgress />)}
                    </div>
                    <div className={classes.bottomAct} >
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            type="submit"
                            disabled={libraryObj.name && libraryObj.name.length > 2 ? false : true}
                        >
                            Save & Close
                        </Button>
                    </div>
                </form>}
        />
    );
}