import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton, TextField, InputAdornment } from "@material-ui/core";
import { useTheme } from "styled-components";
import { useParams, useHistory } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import LibraryCreateDrawer from "./Library.Create.Drawer";
import OfferingCreateDrawer from "./Offering.Create.Drawer";
import CategoryCreateDrawer from "./Category.Crearte.Drawer";
import { deleteOfferingAndRelation, getLibraryCategoriesBasicData, deleteCostCodeCategory, deleteLibrarayById } from "./Api.call";
import LessText from "../styled/CommonComponents/LessText"
import moment from "moment";
import LinearProgress from '@material-ui/core/LinearProgress';
import OfferingsDrawer from "./Offerings.Drawer";
import OfferingsByLibrary from "./OfferingsByLibrary";
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import SearchIcon from '@material-ui/icons/Search';
import emptyPage from "../../Assets/EmptyPageSVG.svg"
import productLibrary from "../../Assets/productLibrary.svg"
import { BiImport } from "react-icons/bi";
import LibrarySkeleton from "../styled/skeletons/Library.Skeleton";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CategoriesByLibrary from "./CategoriesByLibrary";
import DeleteConfirmBox from "../styled/CommonComponents/DeleteConfirmBox";
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import LibraryImport from "./Library.Import";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { updateWallet } from "../finance2o/accounts/paymentMethods/dwolla/api";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100vh",
        overflow: "hidden",
    },
    bodyCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        padding: "5px"
    },
    libraryAndCategoryHeaderCont: {
        width: "100%",
        height: "290px",
        backgroundColor: "white",
        borderRadius: "5px",
        padding: "5px 10px 5px",
    },
    librayHeader: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        borderBottom: "1px solid #ececec"
    },
    offeringCategoryHeader: {
        width: "100%",
        height: "40px",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        borderBottom: "1px solid #ececec"
    },
    librariesCont: {
        width: "100%",
        height: "calc(100% - 95px)",
        padding: "15px",
        overflowY: "hidden",
        overflowX: "auto",
        display: "flex",
        alignItems: "flex-start",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    searchAndTableCont: {
        width: "100%",
        height: "calc(100% - 300px)",
        marginTop: "10px",
        padding: "10px",
        borderRadius: "5px",
        backgroundColor: "white",
        "& .MuiTreeItem-label:hover": {
            backgroundColor: "white"
        }
    },
    searchAndAddBtnCont: {
        width: "100%",
        height: "60px",
        display: "flex",
        alignItems: "center",
        padding: "0px 5px",
        justifyContent: "space-between"
    },
    tableCont: {
        width: "100%",
        height: "calc(100% - 60px)",
        overflow: "hidden"
    },
    emptyDivSty: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            width: "200px",
            height: "auto"
        },
        "& p": {
            fontSize: "14px",
            color: "gray",
            textAlign: "center",
            margin: "10px 0px 25px"
        }
    },
    emptyBtnCont: {
        display: "flex",
        alignItems: "center",
    },
    backAndTitleCont: {
        display: "flex",
        alignItems: "center",
        "& h3": {
            fontSize: "16px",
            fontWeight: "500",
            marginLeft: "15px"
        }
    },
    libraryCard: {
        minWidth: "250px",
        height: "150px",
        padding: "7px",
        borderRadius: "5px",
        marginRight: "15px",
        cursor: "pointer",
        position: "relative",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        "&:hover": {
            transform: "scale(1.02)"
        }
    },
    penSty: {
        position: "absolute",
        top: "5px",
        right: "5px",
        "& .MuiIconButton-root": {
            padding: "5px"
        },
        "& .MuiSvgIcon-root": {
            color: "black",
            fontSize: "22px"
        }
    },
    libraryImageCont: {
        width: "100%",
        height: "80px",
        overflow: "hidden",
        borderRadius: "5px",
        backgroundColor: "#ececec",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            height: "100%",
            width: "auto",
            transform: "rotate(-15deg)",
        }
    },
    libraryDetailsCont: {
        width: "100%",
        paddingTop: "5px",
        "& h3": {
            fontSize: "14px",
            fontWeight: "500"
        },
        '& p': {
            fontSize: "12px",
            color: "gray",
            fontWeight: "400",
            marginTop: "3px",
        }
    },
    deleteConfirm: {
        width: "350px",
        padding: "25px 0px 35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& .MuiSvgIcon-root": {
            fontSize: "75px",
            color: "red"
        },
        "& h3": {
            fontSize: "20px"
        },
        "& p": {
            fontSize: "14px"
        }
    },
    singleOption: {
        padding: "4px 15px",
        fontSize: "15px",
        fontWeight: "500",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "#ececec"
        }
    },
}));

const OfferingsAndProductsManagement = ({ }) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();
    const { profileId } = useParams();

    const [importLibraryOpen, setImportLibraryOpen] = useState(false)
    const [loader, setLoader] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [curTab, setCurTab] = useState(0);
    const [createLibraryDawer, setCreateLibraryDrawer] = useState(false)
    const [createOfferingDawer, setCreateOfferingDrawer] = useState(false)
    const [createCategoryDawer, setCreateCategoryDrawer] = useState(false)
    const [librarAndCategoriesArr, setLibrarAndCategoriesArr] = useState([])
    const [currentLibraryCategory, setCurrentLibraryCategory] = useState({})
    const [incomeChartAccounts, setIncomeChartAccounts] = useState([])
    const [expenseChartAccounts, setExpenseChartAccounts] = useState([])
    const [wallet, setWallet] = useState()
    const [selectedCategoryForOffering, setSelectedCategoryForOffering] = useState()
    const [selectedCategory, setSelectedCategory] = useState()
    const [openOfferingDrawer, setOpenOfferingDrawer] = useState(false)
    const [curPage, setCurPage] = useState("")
    const [offeringSearchStr, setOfferingSearchStr] = useState("")
    const [categorySearchStr, setCategorySearchStr] = useState("")
    const [allOfferings, setAllOfferings] = useState([])
    const [givenParentCategory, setGivenParentCategory] = useState(null)

    //edit
    const [curEditDeleteLibrary, setCurEditDeleteLibrary] = useState(null)
    const [curEditDeleteOffering, setCurEditDeleteOffering] = useState(null)
    const [deleteConfirmBox, setDeleteConfirmBox] = useState(false)
    const [categoryDeleteConfirmBox, setCategoryDeleteConfirmBox] = useState(false)
    const [libraryDeleteConfirmBox, setLibraryDeleteConfirmBox] = useState(false)
    const [offeringDeleteLoader, setOfferingDeleteLoader] = useState(false)
    const [categoryDeleteLoader, setCategoryDeleteLoader] = useState(false)
    const [libraryDeleteLoader, setLibraryDeleteLoader] = useState(false)
    const [categoryForEditDelete, setCategoryForEditDelete] = useState(null)

    //snackBar
    const [responseStatusAndMsg, setResponseStatusAndMsg] = useState(null)
    const [sanckBarBool, setSanckBarBool] = useState(null)


    const buildHierarchy = (items, parent = null) => {
        const result = []
        for (const item of items) {
            if (item.parent == parent) {
                const children = buildHierarchy(items, item._id);
                if (children.length > 0) {
                    item.children = children;
                }
                result.push(item);
            }
        }
        return result;
    }

    const getLibrariesAndCategories = async (isInitial) => {
        if (isInitial) {
            setLoader(true)
        }
        await getLibraryCategoriesBasicData({ curProfileId: profileId, profileIds: [profileId] })
            .then(async (data) => {
                const librariesAndCategories = data?.librariesAndCategories || []
                const locWallet = data?.wallet
                const locExpenseChartAccounts = data?.expenseChartAccount || []
                const locIncomeChartAccounts = data?.incomeChartAccount || []
                let LibraryCategoryObj = []
                librariesAndCategories.map((librarycategory) => {
                    const categories = librarycategory?.categories || []
                    LibraryCategoryObj.push({
                        library: librarycategory.library,
                        linerCategories: categories,
                        categoriesIds: categories.map((category) => category?._id),
                        category: buildHierarchy(categories)
                    })
                })
                if (LibraryCategoryObj && LibraryCategoryObj.length > 0) {
                    setCurrentLibraryCategory(LibraryCategoryObj[0])
                    setCurPage("category");
                }
                setLibrarAndCategoriesArr(LibraryCategoryObj)
                setIncomeChartAccounts(locIncomeChartAccounts)
                setExpenseChartAccounts(locExpenseChartAccounts)
                setWallet(locWallet)
                if (LibraryCategoryObj && LibraryCategoryObj.length > 0 && locWallet?._id && !locWallet.defaultLibrary) {
                    await updateWalletApiCall({
                        defaultLibrary: LibraryCategoryObj[0]?.library?._id,
                        walletId: locWallet?._id,
                    })
                }
                if (isInitial) {
                    setLoader(false)
                }
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getLibrariesAndCategories(true);
    }, [])

    //wallet update--------------------------------------------------------------
    const updateWalletApiCall = async (updateObj) => {
        setUpdateLoader(true)
        await updateWallet(updateObj)
            .then((data) => {
                setWallet(data)
                setUpdateLoader(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //Library--------------------------------------------------------------------
    const librarayAction = (type, library = null) => {
        setCreateLibraryDrawer(false)
        setCurEditDeleteLibrary(null)

        switch (type) {
            case "create":
                setCreateLibraryDrawer(true)
                break;
            case "edit":
                setCurEditDeleteLibrary(library)
                setCreateLibraryDrawer(true)
                break;
            case "delete":
                setCurEditDeleteLibrary(library)
                setLibraryDeleteConfirmBox(true)
                break
        }
    }

    const afterCreateOrEditNewLibrary = async (data, isEdit) => {
        let arr = []
        if (isEdit) {
            librarAndCategoriesArr.map((librarayCategory) => {
                if (librarayCategory?.library?._id == data?._id) {
                    arr.push({
                        library: data,
                        category: librarayCategory?.category || [],
                        categoriesIds: librarayCategory?.categoriesIds || [],
                        linerCategories: librarayCategory?.linerCategories || [],
                    })
                } else {
                    arr.push(librarayCategory)
                }
            })
            setResponseStatusAndMsg({
                status: "success",
                message: `Library successfully updated`
            })
            setSanckBarBool(true)
            setCurEditDeleteLibrary(null)
        } else {
            const obj = {
                library: data,
                category: [],
                categoriesIds: [],
                linerCategories: [],
            }
            arr = [...librarAndCategoriesArr, obj]
            if (librarAndCategoriesArr.length == 0) {
                setCurrentLibraryCategory(obj)
                await updateWalletApiCall({
                    defaultLibrary: obj?.library?._id,
                    walletId: wallet?._id,
                })
            }
            setResponseStatusAndMsg({
                status: "success",
                message: `Library successfully added`
            })
            setSanckBarBool(true)
        }
        setLibrarAndCategoriesArr(arr)
    }

    const deleteLibrary = async () => {
        setLibraryDeleteLoader(true)
        await deleteLibrarayById({
            libraryId: curEditDeleteLibrary?._id
        })
            .then((data) => {
                if (data === null) {
                    setResponseStatusAndMsg({
                        status: "warning",
                        message: "To delete library first you have to delete all categories, products and services, under the library"
                    })
                    setSanckBarBool(true)
                } else {
                    const arr = librarAndCategoriesArr.filter((s) => s?.library?._id != data?._id)
                    setLibrarAndCategoriesArr(arr)
                    setResponseStatusAndMsg({
                        status: "success",
                        message: "Library successfully deleted"
                    })
                    setSanckBarBool(true)
                }
                setCurEditDeleteLibrary(null)
                setLibraryDeleteLoader(false)
                setLibraryDeleteConfirmBox(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //Category-------------------------------------------------------------------
    //have to check update api call for category
    const categoryAction = (type, category) => {
        setCreateCategoryDrawer(false)
        setGivenParentCategory(null)
        setSelectedCategoryForOffering()
        setCreateOfferingDrawer(false)
        setCategoryForEditDelete(null)
        setCategoryDeleteConfirmBox(false)
        switch (type) {
            case "create":
                setCreateCategoryDrawer(true)
                break;
            case "createWithParent":
                setGivenParentCategory(category)
                setCreateCategoryDrawer(true)
                break;
            case "createOfferingWithCategory":
                setSelectedCategoryForOffering(category)
                setCreateOfferingDrawer(true)
                break;
            case "edit":
                setCategoryForEditDelete(category);
                setCreateCategoryDrawer(true);
                break;
            case "delete":
                setCategoryForEditDelete(category);
                setCategoryDeleteConfirmBox(true);
                break
        }
    }

    const afterCategoryCreateOrEdit = async (data, isEdit) => {
        const librayId = data?.library
        let finalArr = [];
        let currentObj = {}
        if (isEdit) {
            librarAndCategoriesArr.map((librarycategory) => {
                if (librarycategory?.library?._id == librayId) {
                    let categoriesArr = librarycategory?.linerCategories || []
                    let arr = []
                    categoriesArr.map((category) => {
                        if (category?._id == data?._id) {
                            arr.push(data)
                        } else {
                            arr.push(category)
                        }
                    })
                    currentObj = {
                        library: librarycategory.library,
                        linerCategories: arr,
                        categoriesIds: arr.map((category) => category?._id),
                        category: buildHierarchy(arr)
                    }
                    finalArr.push(currentObj)
                } else {
                    finalArr.push(librarycategory)
                }
            })
            setResponseStatusAndMsg({
                status: "success",
                message: `catrgory modified successfully`
            })
            setSanckBarBool(true)
        } else {
            librarAndCategoriesArr.map((librarycategory) => {
                if (librarycategory?.library?._id == librayId) {
                    let categoriesArr = librarycategory?.linerCategories || []
                    categoriesArr.push(data)
                    currentObj = {
                        library: librarycategory.library,
                        linerCategories: categoriesArr,
                        categoriesIds: categoriesArr.map((category) => category?._id),
                        category: buildHierarchy(categoriesArr)
                    }
                    finalArr.push(currentObj)
                } else {
                    finalArr.push(librarycategory)
                }
            })
            setResponseStatusAndMsg({
                status: "success",
                message: `catrgory successfully added`
            })
            setSanckBarBool(true)
        }
        setCurrentLibraryCategory(currentObj)
        setLibrarAndCategoriesArr(finalArr)
        setGivenParentCategory(null)
        setCategoryForEditDelete(null)
    }

    const deleteCategory = async () => {
        setCategoryDeleteLoader(true)
        await deleteCostCodeCategory({
            categoryId: categoryForEditDelete?._id
        })
            .then((data) => {
                const librayId = data?.library
                let finalArr = [];
                let currentObj = {}
                librarAndCategoriesArr.map((librarycategory) => {
                    if (librarycategory?.library?._id == librayId) {
                        let categoriesArr = librarycategory?.linerCategories || []
                        let filtered = categoriesArr.filter(c => categoriesArr?._id != data?._id)
                        currentObj = {
                            library: librarycategory.library,
                            linerCategories: filtered,
                            categoriesIds: filtered.map((category) => category?._id),
                            category: buildHierarchy(filtered)
                        }
                        finalArr.push(currentObj)
                    } else {
                        finalArr.push(librarycategory)
                    }
                })
                setCurrentLibraryCategory(currentObj)
                setLibrarAndCategoriesArr(finalArr)
                setCategoryDeleteLoader(false)
                setCategoryDeleteConfirmBox(false)
                setCategoryForEditDelete(null)
                setResponseStatusAndMsg({
                    status: "success",
                    message: "Category successfully deleted"
                })
                setSanckBarBool(true)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    //Products and services -----------------------------------------------------
    const offeringAction = async (type, offering) => {
        setCreateOfferingDrawer(false)
        setCurEditDeleteOffering(null)
        setDeleteConfirmBox(false)
        switch (type) {
            case "create":
                setCreateOfferingDrawer(true);
                break;
            case "edit":
                setCurEditDeleteOffering(offering);
                setCreateOfferingDrawer(true);
                break;
            case "delete":
                setCurEditDeleteOffering(offering);
                setDeleteConfirmBox(true);
                break
            case "setDefaultIncome":
                await updateWalletApiCall({
                    walletId: wallet?._id,
                    defaultIncomeOfferingRelation: offering?.offeringRelation?._id
                })
                break;
            case "setDefaultExpense":
                await updateWalletApiCall({
                    walletId: wallet?._id,
                    defaultExpansesOfferingRelation: offering?.offeringRelation?._id
                })
                break;
        }
    }

    const afterOfferingCreateOrEdit = async (data, isEdit) => {
        let arr = [];
        if (!isEdit) {
            arr = [data, ...allOfferings]
            setResponseStatusAndMsg({
                status: "success",
                message: `${data?.model} successfully added`
            })
            setSanckBarBool(true)
        } else {
            allOfferings.map((item) => {
                if (curEditDeleteOffering?._id != item?._id) {
                    arr.push(item)
                } else {
                    arr.push(data)
                }
            })
            setResponseStatusAndMsg({
                status: "success",
                message: `${data?.model} successfully modified`
            })
            setSanckBarBool(true)
        }
        setCurEditDeleteOffering(null)
        setSelectedCategoryForOffering(null)
        setAllOfferings(arr)
    }

    const deleteOffering = async () => {
        if (curEditDeleteOffering?.offeringRelation?._id && curEditDeleteOffering?._id) {
            setOfferingDeleteLoader(true)
            await deleteOfferingAndRelation({
                offeringId: curEditDeleteOffering?._id,
                offeringRelationId: curEditDeleteOffering?.offeringRelation?._id
            })
                .then((data) => {
                    let arr = [];
                    allOfferings.map((item) => {
                        if (item?._id != curEditDeleteOffering?._id) {
                            arr.push(item)
                        }
                    })
                    setAllOfferings(arr)
                    setOfferingDeleteLoader(false)
                    setDeleteConfirmBox(false)
                    setResponseStatusAndMsg({
                        status: "success",
                        message: `${curEditDeleteOffering?.model} successfully deleted`
                    })
                    setSanckBarBool(true)
                    setCurEditDeleteOffering(null)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    const handleSnackBarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSanckBarBool(false);
    }

    return (<div className={classes.mainCont} >
        {loader ? (<LibrarySkeleton />) : (<>
            {librarAndCategoriesArr && librarAndCategoriesArr.length > 0 ? (<>
                <div className={classes.bodyCont} >
                    <div className={classes.libraryAndCategoryHeaderCont} >
                        <div className={classes.librayHeader} >
                            <div className={classes.backAndTitleCont} >
                                <IconButton 
                                onClick={()=>{
                                    history.goBack();
                                }}
                                 >
                                    <KeyboardBackspaceIcon />
                                </IconButton>
                                <h3>Library Management</h3>
                            </div>

                            <div className={classes.backAndTitleCont}  >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={() => {
                                        librarayAction("create")
                                    }}
                                    style={{ marginRight: "20px" }}
                                >
                                    New Library
                                </Button>

                                <Button
                                    variant="outlined"
                                    color="primary"
                                    startIcon={<BiImport />}
                                    onClick={() => {
                                        setImportLibraryOpen(true)
                                    }}
                                >
                                    Import Library
                                </Button>
                            </div>
                        </div>
                        <div style={{ width: "5px", width: "100%" }} >
                            {updateLoader && (<LinearProgress />)}
                        </div>
                        <div className={classes.librariesCont} >
                            {librarAndCategoriesArr.map((libraryCategory) => (
                                <div
                                    className={classes.libraryCard}
                                    onClick={() => {
                                        setCurrentLibraryCategory(libraryCategory)
                                    }}
                                    style={libraryCategory?.library?._id == currentLibraryCategory?.library?._id ? { border: `2px solid ${theme.palette.primary.main}` } : {}}
                                >
                                    <div className={classes.libraryImageCont} >
                                        <img src={productLibrary} />
                                    </div>
                                    <div className={classes.libraryDetailsCont} >
                                        <h3><LessText
                                            limit={30}
                                            string={libraryCategory?.library?.name}
                                        /></h3>
                                        {libraryCategory?.library?.createdAt && (
                                            <p>Created: {moment(libraryCategory?.library?.createdAt).format("Do MMMM YYYY")}</p>
                                        )}
                                    </div>
                                    <span className={classes.penSty} >
                                        <MyPopOver
                                            closeOnClick={true}
                                            appearContent={
                                                <IconButton>
                                                    <MoreVertIcon />
                                                </IconButton>
                                            }
                                            showContent={<div style={{ padding: "5px 0px" }} >
                                                <div
                                                    className={classes.singleOption}
                                                    onClick={() => {
                                                        librarayAction("edit", libraryCategory?.library)
                                                    }}
                                                >
                                                    Edit
                                                </div>
                                                {wallet?.defaultLibrary == libraryCategory?.library?._id ? null : (
                                                    <div
                                                        className={classes.singleOption}
                                                        onClick={async () => {
                                                            await updateWalletApiCall({
                                                                defaultLibrary: libraryCategory?.library?._id,
                                                                walletId: wallet?._id,
                                                            })
                                                        }}
                                                    >
                                                        Mark As Default
                                                    </div>
                                                )}
                                                <div
                                                    className={classes.singleOption}
                                                    onClick={() => {
                                                        librarayAction("delete", libraryCategory?.library)
                                                    }}
                                                >
                                                    Delete
                                                </div>
                                            </div>}
                                        />
                                    </span>
                                </div>
                            ))}
                        </div>
                        <div className={classes.offeringCategoryHeader} style={{ border: "none" }} >
                            <Tabs
                                value={curTab}
                                indicatorColor="primary"
                                textColor="primary"
                                onChange={(e, value) => { setCurTab(value) }}
                                aria-label="disabled tabs example"
                            >
                                <Tab label="Products & Services" />
                                <Tab label="Categories" />
                            </Tabs>

                            <div></div>
                        </div>
                    </div>
                    <div className={classes.searchAndTableCont} >
                        <div className={classes.searchAndAddBtnCont} >
                            <TextField
                                id="outlined-basic"
                                variant="outlined"
                                size="small"
                                placeholder={curTab === 0 ? "Enter service or product name" : "Enter category name"}
                                autoComplete='off'
                                value={curTab === 0 ? offeringSearchStr : categorySearchStr}
                                style={{ width: "350px" }}
                                onChange={(e) => {
                                    if (curTab === 0) {
                                        setOfferingSearchStr(e.target.value);
                                    } else {
                                        setCategorySearchStr(e.target.value);
                                    }
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    if (curTab === 0) {
                                        setCurEditDeleteOffering(null)
                                        offeringAction("create", null)
                                    } else {
                                        setCategoryForEditDelete(null)
                                        categoryAction("create", null)
                                    }
                                }}
                            >
                                New
                            </Button>
                        </div>
                        <div className={classes.tableCont} >
                            {curTab === 0 && (
                                <OfferingsByLibrary
                                    libraryId={currentLibraryCategory?.library?._id}
                                    offeringSearchStr={offeringSearchStr}
                                    setOfferingSearchStr={setOfferingSearchStr}
                                    offeringAction={offeringAction}
                                    allOfferings={allOfferings}
                                    setAllOfferings={setAllOfferings}
                                    profileId={profileId}
                                    wallet={wallet}
                                />
                            )}
                            {curTab === 1 && (
                                <CategoriesByLibrary
                                    currentLibraryCategory={currentLibraryCategory}
                                    setSelectedCategory={setSelectedCategory}
                                    setOpenOfferingDrawer={setOpenOfferingDrawer}
                                    categorySearchStr={categorySearchStr}
                                    setCategorySearchStr={setCategorySearchStr}
                                    categoryAction={categoryAction}
                                    profileId={profileId}
                                />
                            )}
                        </div>
                    </div>
                    <div></div>
                </div>
            </>) : (<div className={classes.emptyDivSty} >
                <img src={emptyPage} />
                <p>
                    Create new library or import library, <br />
                    In order to create your own library you have to first create library <br />
                    then category,and after that you will be able to create new product or service.
                </p>
                <div className={classes.emptyBtnCont} >
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={() => {
                            librarayAction("create")
                        }}
                        style={{ marginRight: "20px" }}
                    >
                        New Library
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<BiImport />}
                        onClick={() => {
                            setImportLibraryOpen(true)
                        }}
                    >
                        Import Library
                    </Button>
                </div>
            </div>)}
        </>)}
        <LibraryImport
            importLibraryOpen={importLibraryOpen}
            setImportLibraryOpen={setImportLibraryOpen}
            profileId={profileId}
            wallet={wallet}
            getLibrariesAndCategories={getLibrariesAndCategories}
        />
        <LibraryCreateDrawer
            createLibraryDawer={createLibraryDawer}
            setCreateLibraryDrawer={setCreateLibraryDrawer}
            profileId={profileId}
            afterCreateOrEditNewLibrary={afterCreateOrEditNewLibrary}
            curEditDeleteLibrary={curEditDeleteLibrary}
        />
        <OfferingCreateDrawer
            createOfferingDawer={createOfferingDawer}
            setCreateOfferingDrawer={setCreateOfferingDrawer}
            profileId={profileId}
            purchase={false}
            selling={true}
            library={currentLibraryCategory?.library}
            selectedCategoryForOffering={selectedCategoryForOffering}
            afterOfferingCreateOrEdit={afterOfferingCreateOrEdit}
            curEditDeleteOffering={curEditDeleteOffering}
        />
        <CategoryCreateDrawer
            createCategoryDawer={createCategoryDawer}
            setCreateCategoryDrawer={setCreateCategoryDrawer}
            profileId={profileId}
            categoryType="Common"
            afterCategoryCreateOrEdit={afterCategoryCreateOrEdit}
            currentLibraryCategory={currentLibraryCategory}
            givenParentCategory={givenParentCategory}
            categoryForEditDelete={categoryForEditDelete}
        />
        <OfferingsDrawer
            openOfferingDrawer={openOfferingDrawer}
            setOpenOfferingDrawer={setOpenOfferingDrawer}
            selectedCategory={selectedCategory}
            profileId={profileId}
            allCategories={currentLibraryCategory?.linerCategories || []}
        />
        <DeleteConfirmBox
            open={deleteConfirmBox}
            setOpen={setDeleteConfirmBox}
            loading={offeringDeleteLoader}
            pageTitle={`Delete ${curEditDeleteOffering?.offering?.name}`}
            cantent={
                <div className={classes.deleteConfirm} >
                    <ReportProblemIcon />
                    <h3>Are you sure?</h3>
                    <p>You won't be able to revert this!</p>
                </div>
            }
            cancleConfirmDelete={() => {
                setDeleteConfirmBox(false)
            }}
            confirmDelete={deleteOffering}
        />
        <DeleteConfirmBox
            open={categoryDeleteConfirmBox}
            setOpen={setCategoryDeleteConfirmBox}
            loading={categoryDeleteLoader}
            pageTitle={`Delete ${categoryForEditDelete?.name}`}
            cantent={
                <div className={classes.deleteConfirm} >
                    <ReportProblemIcon />
                    <h3>Are you sure?</h3>
                    <p>You won't be able to revert this!</p>
                </div>
            }
            cancleConfirmDelete={() => {
                setCategoryDeleteConfirmBox(false)
            }}
            confirmDelete={deleteCategory}
        />
        <DeleteConfirmBox
            open={libraryDeleteConfirmBox}
            setOpen={setLibraryDeleteConfirmBox}
            loading={libraryDeleteLoader}
            pageTitle={`Delete ${curEditDeleteLibrary?.name}`}
            cantent={
                <div className={classes.deleteConfirm} >
                    <ReportProblemIcon />
                    <h3>Are you sure?</h3>
                    <p>You won't be able to revert this!</p>
                </div>
            }
            cancleConfirmDelete={() => {
                setLibraryDeleteConfirmBox(false)
            }}
            confirmDelete={deleteLibrary}
        />
        <Snackbar
            open={sanckBarBool}
            autoHideDuration={6000}
            onClose={handleSnackBarClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
            <Alert onClose={handleSnackBarClose} severity={responseStatusAndMsg?.status}>
                {responseStatusAndMsg?.message}
            </Alert>
        </Snackbar>
    </div>);
};

export default OfferingsAndProductsManagement;