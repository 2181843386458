import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles"
import { Button, TextField, InputAdornment } from "@material-ui/core"
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllAssetAssignTo, getAllCategories, getEventsByProfile } from "../api.call";
import SearchIcon from '@material-ui/icons/Search';
import Table from '@material-ui/core/Table';
import LinearProgress from '@material-ui/core/LinearProgress';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GetAppIcon from '@material-ui/icons/GetApp';
import Pagination from "@material-ui/lab/Pagination";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Avatar } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import moment from "moment";
import { ASSET_OPTIONS } from '../../../helpers/constants';
import { useDebounce } from "react-use";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
    auditMainCont: {
        width: "100%",
        height: "calc(100vh - 130px)",
        overflow: "hidden",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between"
    },
    leftSideSpace: {
        width: "350px",
        height: "100%",
        backgroundColor: "white",
        padding: "10px"
    },
    rightSideSpace: {
        width: "calc(100% - 350px)",
        height: "100%"
    },
    tableHeadSearch: {
        width: "100%",
        height: "50px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        "& h3": {
            fontSize: "16px",
            fontWeight: "500",
            marginTop: "10px"
        }
    },
    searchCont: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    tableMainBody: {
        width: "100%",
        height: "calc(100% - 55px)",
        boxShadow: "rgba(0, 0, 0, 0.09) 25px 30px 100px;"
    },
    tableContainer: {
        width: "100%",
        borderRadius: "4px",
        height: "calc(100% - 50px)",
        "& tr": {
            height: "55px",
            outline: "1px solid #d2d2d2",
        },
        "&.MuiTableCell-root": {
            outline: "1px solid #ececec",
            border: "none",
        },
        "& table": {
            border: "none",
            tableLayout: "auto",
            borderCollapse: "collapse",
        },
        "& th": {
            border: "none",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "550",
            padding: "10px"
        },
        "& td": {
            border: "none",
            borderCollapse: "collapse",
            fontSize: "12px",
            fontWeight: "500",
            padding: "0px 10px",
        }
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderTop: "1px solid #d2d1d1",
    },
    avatarGroupSty: {
        "& .MuiAvatar-root": {
            width: "30px",
            height: "30px",
        },
    },
    detailsCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    nameCont: {
        width: "100%",
        marginLeft: "5px",
        "& h3": {
            fontSize: "15px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "13px",
            fontWeight: "400"
        }
    },
    filterCont: {
        width: "100%",
        height: "100%",
        borderRadius: "5px",
        boxShadow: "rgba(0, 0, 0, 0.09) -30px 25px 100px"
    },
    filterHeader: {
        width: "100%",
        height: "60px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 10px",
    },
    filterInputCont: {
        width: "100%",
        height: "calc(100% - 60px)",
        overflowY: "auto",
        overflowX: "hidden"
    },
    displayFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    lableStyle: {
        fontSize: "16px",
        fontWeight: "500"
    },
    divLable: {
        width: "100%",
        padding: "10px",
        "& h3": {
            fontSize: "16px",
            fontWeight: "500"
        }
    },

}));

const AuditHistory = ({
    profileParentData
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const [searchObject, setSearchObject] = useState({
        createdDateFrom: null,
        createdDateTo: null,
        assetSearchStr: "",
        filterLocation: "",
        filterCategory: null,
        eventType: null,
        filterStatus: null,
        filterAssignTo: null
    });
    const [searchString, setSearchString] = useState("");
    const [loader, setLoader] = useState()
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(20)
    const [totalPages, setTotalPages] = useState(0)
    const [assetEvents, setAssetEvents] = useState([])
    const [changeState, setChangeState] = useState(false)
    const [filterAssignTo, setFilterAssignTo] = useState([])
    const [filterCategoriesOption, setFilterCategoriesOption] = useState([])

    useEffect(() => {
        if (profileParentData?._id) {
            getAllAssetAssignTo({ profileId: profileParentData?._id })
                .then((data) => {
                    setFilterAssignTo(data)
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }, [profileParentData?._id])

    useEffect(() => {
        if (profileParentData?._id) {
            getAllCategories({ profileId: profileParentData?._id })
                .then((data) => {
                    setFilterCategoriesOption(data)
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }, [profileParentData?._id])

    const getAllAssetsEvents = async () => {
        if (profileParentData?._id) {
            setLoader(true)
            await getEventsByProfile({
                profileId: profileParentData?._id,
                searchObject: searchObject,
                limit: pageLimit,
                curPage: curPage - 1
            })
                .then((data) => {
                    setAssetEvents(data?.allAssetEvent || [])
                    let locTotalPage = Math.ceil((data?.allAssetEventsCount || 0) / pageLimit);
                    setTotalPages(locTotalPage)
                    setLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    useDebounce(() => {
        getAllAssetsEvents()
    }, 1000, [curPage, changeState, profileParentData?._id])

    useEffect(() => {
        setCurPage(1)
        setChangeState(!changeState)
    }, [searchString])

    const randerAssignTo = (data) => {
        switch (data?.eventType) {
            case "move":
                return (<AvatarGroup max={4} className={classes.avatarGroupSty} >
                    {data?.moveObject?.assignTo && data.moveObject.assignTo.map((participant, idx) => (
                        <Avatar
                            key={idx}
                            title={participant?.parent?.displayName}
                            alt={participant?.parent?.displayName}
                            src={participant?.parent?.displayPicture?.url}
                        />
                    ))}
                </AvatarGroup>);
            case "assign":
                return (<AvatarGroup max={4} className={classes.avatarGroupSty} >
                    {data?.assignObject?.assignTo && data.assignObject.assignTo.map((participant, idx) => (
                        <Avatar
                            key={idx}
                            title={participant?.parent?.displayName}
                            alt={participant?.parent?.displayName}
                            src={participant?.parent?.displayPicture?.url}
                        />
                    ))}
                </AvatarGroup>);
            case "repair":
                return (<div className={classes.detailsCont} >
                    <Avatar
                        src={data?.repairObject?.repairVendor?.parent?.displayPicture?.thumbUrl ? data?.repairObject?.repairVendor?.parent?.displayPicture?.thumbUrl : data?.repairObject?.repairVendor?.parent?.displayPicture?.url}
                        alt={data?.repairObject?.repairVendor?.parent?.displayName}
                        style={{ width: "30px", height: "30px" }}
                    />
                    <div className={classes.nameCont} >
                        <h3>{data?.repairObject?.repairVendor?.parent?.displayName}</h3>
                        <p>
                            {data?.repairObject?.repairVendor?.parent?.username}
                        </p>
                    </div>
                </div>);
            case "lost":
                return (<></>);
            case "expired":
                return (<></>)
            case "sold":
                return (<div className={classes.detailsCont} >
                    <Avatar
                        src={data?.soldObject?.buyer?.parent?.displayPicture?.thumbUrl ? data?.soldObject?.buyer?.parent?.displayPicture?.thumbUrl : data?.soldObject?.buyer?.parent?.displayPicture?.url}
                        alt={data?.soldObject?.buyer?.parent?.displayName}
                        style={{ width: "30px", height: "30px" }}
                    />
                    <div className={classes.nameCont} >
                        <h3>{data?.soldObject?.buyer?.parent?.displayName}</h3>
                        <p>
                            {data?.soldObject?.buyer?.parent?.username}
                        </p>
                    </div>
                </div>)
        }
    }


    return (<div className={classes.auditMainCont} >
        <div className={classes.leftSideSpace} >
            <div className={classes.filterCont} >
                <div className={classes.filterHeader} >
                    <h3 className={classes.lableStyle} >Filter</h3>

                    <div className={classes.displayFlex} >
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            disabled={loader}
                            onClick={() => {
                                setSearchObject({
                                    createdDateFrom: null,
                                    createdDateTo: null,
                                    assetSearchStr: "",
                                    filterLocation: "",
                                    filterCategory: null,
                                    eventType: null,
                                    filterStatus: null,
                                    filterAssignTo: null
                                })
                                setCurPage(1)
                                setChangeState(!changeState)
                            }}
                            style={{ marginRight: "10px" }}
                        >
                            Clear
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            disabled={loader}
                            onClick={() => {
                                setCurPage(1)
                                setChangeState(!changeState)
                            }}
                        >
                            Apply
                        </Button>
                    </div>
                </div>

                <div className={classes.filterInputCont} >
                    <div className={classes.divLable} >
                        <h3>Date Range</h3>
                        <div className={classes.displayFlex} >
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    value={searchObject?.createdDateFrom}
                                    style={{ width: "48%" }}
                                    margin="normal"
                                    placeholder="From"
                                    id="due-date-picker"
                                    format="MM/dd/yyyy"
                                    onChange={async (date) => {
                                        setSearchObject({
                                            ...searchObject,
                                            createdDateFrom: date
                                        })
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    value={searchObject?.createdDateTo}
                                    style={{ width: "48%" }}
                                    margin="normal"
                                    placeholder="To"
                                    id="due-date-picker"
                                    format="MM/dd/yyyy"
                                    onChange={async (date) => {
                                        setSearchObject({
                                            ...searchObject,
                                            createdDateTo: date
                                        })
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>

                    <div className={classes.divLable} >
                        <h3>Asset Name/ID</h3>
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            placeholder={"Enter Asset Name/ID"}
                            autoComplete='off'
                            value={searchObject?.assetSearchStr}
                            fullWidth
                            onChange={(e) => {
                                setSearchObject({
                                    ...searchObject,
                                    assetSearchStr: e.target.value
                                })
                            }}
                        />
                    </div>

                    <div className={classes.divLable} >
                        <h3>Type</h3>
                        <Autocomplete
                            multiple
                            options={ASSET_OPTIONS?.type}
                            value={searchObject?.filterType || []}
                            onChange={(event, value) => {
                                setSearchObject({
                                    ...searchObject,
                                    filterType: value
                                })
                            }}
                            fullWidth
                            size="small"
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    {...params}
                                    placeholder={"Select type"}
                                />
                            )}
                        />
                    </div>

                    <div className={classes.divLable} >
                        <h3>Location Type</h3>
                        <Autocomplete
                            multiple
                            options={ASSET_OPTIONS?.location}
                            value={searchObject?.filterLocation || []}
                            onChange={(event, value) => {
                                setSearchObject({
                                    ...searchObject,
                                    filterLocation: value
                                })
                            }}
                            fullWidth
                            size="small"
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    {...params}
                                    placeholder={"Select location"}
                                />
                            )}
                        />
                    </div>

                    <div className={classes.divLable} >
                        <h3>Category</h3>
                        <Autocomplete
                            multiple
                            options={filterCategoriesOption}
                            value={searchObject?.filterCategory || []}
                            onChange={(event, value) => {
                                setSearchObject({
                                    ...searchObject,
                                    filterCategory: value
                                })
                            }}
                            fullWidth
                            size="small"
                            getOptionLabel={(option) => {
                                if (option?.code && option?.name) {
                                    return `${option?.code}|${option?.name}`
                                } else if (option?.name) {
                                    return `${option?.name}`
                                } else {
                                    return ""
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    {...params}
                                    placeholder={"Select category"}
                                />
                            )}
                        />
                    </div>

                    <div className={classes.divLable} >
                        <h3>Event Type</h3>
                        <Autocomplete
                            multiple
                            options={ASSET_OPTIONS?.eventTypes}
                            value={searchObject?.eventType || []}
                            onChange={(event, value) => {
                                setSearchObject({
                                    ...searchObject,
                                    eventType: value
                                })
                            }}
                            fullWidth
                            size="small"
                            getOptionLabel={(option) => option?.lable}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    {...params}
                                    placeholder={"Select Status"}
                                />
                            )}
                        />
                    </div>

                    <div className={classes.divLable} >
                        <h3>Staus</h3>
                        <Autocomplete
                            multiple
                            options={ASSET_OPTIONS?.status}
                            value={searchObject?.filterStatus || []}
                            onChange={(event, value) => {
                                setSearchObject({
                                    ...searchObject,
                                    filterStatus: value
                                })
                            }}
                            fullWidth
                            size="small"
                            getOptionLabel={(option) => option}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    {...params}
                                    placeholder={"Select Status"}
                                />
                            )}
                        />
                    </div>

                    <div className={classes.divLable} >
                        <h3>Assign To</h3>
                        <Autocomplete
                            multiple
                            options={filterAssignTo}
                            value={searchObject?.filterAssignTo || []}
                            onChange={(event, value) => {
                                setSearchObject({
                                    ...searchObject,
                                    filterAssignTo: value
                                })
                            }}
                            fullWidth
                            size="small"
                            getOptionLabel={(option) => option?.parent?.displayName}
                            renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    {...params}
                                    placeholder={"Select Status"}
                                />
                            )}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className={classes.rightSideSpace} >
            <div className={classes.tableHeadSearch} >
                <h3>Audit History</h3>

                <div className={classes.searchCont} >
                    <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        placeholder={"Search"}
                        autoComplete='off'
                        value={searchString}
                        style={{ width: "350px", marginRight: "10px" }}
                        onChange={(e) => {
                            setSearchString(e.target.value)
                            setSearchObject({
                                ...searchObject,
                                assetSearchStr: e.target.value
                            })
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <SearchIcon />
                                </InputAdornment>
                            )
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<GetAppIcon />}
                        onClick={() => { }}
                    >
                        Export
                    </Button>
                </div>
            </div>
            <div style={{ width: "100%", height: "3px", overflow: "hidden" }} >
                {loader && (<LinearProgress />)}
            </div>
            <div className={classes.tableMainBody} >
                <TableContainer className={classes.tableContainer}>
                    <Table stickyHeader aria-label="sticky table" size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" style={{ minWidth: "175px" }} >
                                    Date Timestamp
                                </TableCell>
                                <TableCell align="left" style={{ minWidth: "200px" }} >
                                    Asset Name/Id
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: "150px" }} >
                                    Type
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: "150px" }} >
                                    Category
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: "95px" }} >
                                    Event Type
                                </TableCell>
                                <TableCell align="center" style={{ minWidth: "80px" }} >
                                    Status
                                </TableCell>
                                <TableCell align="right" style={{ width: "200px" }} >
                                    Assign To/Buyer/Vendor
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {assetEvents && assetEvents.length > 0 && assetEvents.slice((curPage - 1) * pageLimit, (curPage - 1) * pageLimit + pageLimit).map((data) => {
                                return (
                                    <TableRow>
                                        <TableCell align="left" >
                                            {moment(data?.createdAt).format('MMM Do YYYY, hh:mm a')}
                                        </TableCell>
                                        <TableCell align="left" >
                                            {data?.offering?.name}
                                        </TableCell>
                                        <TableCell align="center" >
                                            {data?.offering?.type}
                                        </TableCell>
                                        <TableCell align="center" >
                                            {data?.offering?.category?.name}
                                        </TableCell>
                                        <TableCell align="center" >
                                            {data?.eventType}
                                        </TableCell>
                                        <TableCell align="center" >
                                            {data?.offering?.assetStatus}
                                        </TableCell>
                                        <TableCell align="center" >
                                            {randerAssignTo(data)}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.paginationCont} >
                    <Pagination
                        count={totalPages}
                        page={curPage}
                        color="primary"
                        siblingCount={0}
                        onChange={(event, value) => { setCurPage(value) }}
                    />
                </div>
            </div>
        </div>
    </div>);
};

export default AuditHistory;