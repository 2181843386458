import React from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import Api from '../../../helpers/Api';

const Select2Autocomplete = ({
  selectedOption, setSelectedOption, apiUrl, limit = 25, uniqueField, labelField, isMulti = false
}) => {

  const loadOptions = async (search, prevOptions, { page }) => {
    const res = await Api.get(`${apiUrl}/?search=${search}&page=${page}&limit=${limit}`);
    const result = res?.data || [];
    const options = []
    result.map((s) => {
      if (s?.[uniqueField] && s?.[labelField]) {
        options.push({
          value: s?.[uniqueField],
          label: s?.[labelField],
          data: {
            ...s
          }
        })
      }
    })

    return {
      options: options,
      hasMore: result.length == limit ? true : false,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <AsyncPaginate
      value={selectedOption}
      loadOptions={loadOptions}
      isMulti={isMulti}
      closeMenuOnSelect={true}
      onChange={(e)=>{
        setSelectedOption(e)
      }}
      additional={{
        page: 1,
      }}
    />
  );
};

export default Select2Autocomplete;