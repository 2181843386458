import React, { useEffect, useState } from "react";
import Dialog from "../../../styled/generic/Dialog";
import FormBox from "../../../styled/generic/FormBox";
import TextField from "../../../styled/generic/TextField";
import SpaceBetween from "../../../styled/generic/SpaceBetween";
import DatePicker from "../../../styled/generic/DatePicker";
import dayjs from "dayjs";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";
import { useSelector } from "react-redux";
import HorizBox from "../../../styled/generic/HorizBox";
import Select from "../../../styled/generic/Select";
import { Avatar, Box, IconButton, MenuItem, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import DocumentUploader from "../../../styled/generic/DocumentUploader";
import Api from "../../../../helpers/Api";
import { useDispatch } from "react-redux";
import DocViewer from "../../../styled/generic/DocViewer";
import LabelsDrawer from "./LabelsDrawer";
import UserPickerDropdown from "../../../styled/generic/UserPickerDropdown";
import BoxSpaceBetween from "../../../styled/generic/BoxSpaceBetween";
import DrawerContainer from "../../../styled/generic/DrawerContainer";
import CategoryCreateDrawer from "../../../OfferingsAndProducts/Category.Crearte.Drawer";
import Button from "../../../styled/generic/Button";
import useShareAndAssign from "../../../ResourceAccessControl/shareAndAssign";
import { updateWallet } from "../../../finance2o/accounts/paymentMethods/dwolla/api";
import {
  getCategoriesByLibrary,
  getLibraryCategoriesBasicData,
} from "../../../OfferingsAndProducts/Api.call";
import CustomAutocomplete from "../../../styled/CommonComponents/CustomAutocomplete";
import CategoryAutocomplete from "../../../OfferingsAndProducts/CategoryAutocomplete";

const AddTaskDialog = ({
  showAddTaskDialog,
  setShowAddTaskDialog,
  targetId,
  onTaskAdd,
  onTaskUpdate,
  onTaskDelete,
  profileId,
  library,
  project,
  organization,
  parentTaskId,
  taskToUpdate,
}) => {
  const files = useSelector((state) => state.file);
  const { fileDictionary, createdFileIds } = files;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [taskName, setTaskName] = useState("");
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().add(3, "day"));
  const [comment, setComment] = useState("");

  const [filesArr, setFilesArr] = useState([]);
  const [fileIds, setFileIds] = useState([]);

  const [labels, setLabels] = useState([]);
  const [openLabelsDrawer, setOpenLabelsDrawer] = useState(false);
  const [assignees, setAssignees] = useState([]);
  const [initialProfileRole, setInitialProfileRole] = useState({});

  const [loadingPrimary, setLoadingPrimary] = useState(false);
  const [loadingSecondary, setLoadingSecondary] = useState(false);

  const [allCategories, setAllCategories] = useState([]);
  const [createOfferingDawer, setCreateOfferingDawer] = useState(false);
  const [hierarchyCategories, setHierarchyCategories] = useState([]);
  const [category, setCategory] = useState(null);
  const [createCategoryDrawer, setCreateCategoryDrawer] = useState(false);

  const projectProfileId = project?.profile;
  const orgProfileId = organization?.profile;
  const userProfileId = user?.profile;
  //Initalize
  useEffect(() => {
    console.log("IDS are: ");
    console.log("orgProfileId: ", orgProfileId);
    console.log("userProfileId: ", userProfileId);
    console.log("projectProfileId: ", projectProfileId);

    let locInitialProfileRole = {};
    if (orgProfileId) {
      locInitialProfileRole[orgProfileId] = "Owner";
    }
    if (userProfileId) {
      locInitialProfileRole[userProfileId] = "Owner";
    }
    if (projectProfileId) {
      locInitialProfileRole[projectProfileId] = "Owner";
    }
    setInitialProfileRole(locInitialProfileRole);
  }, [orgProfileId, userProfileId, projectProfileId]);

  const assignBtn = (
    <Button variant="outlined" startIcon={<Add />}>
      Click here to assign
    </Button>
  );

  const {
    sharedProfilesRole,
    ShareButton,
    ShareDrawer,
    SharedView,
    assignedProfiles,
    AssignButton,
    AssigneDrawer,
    AssignView,
  } = useShareAndAssign({
    initShared: initialProfileRole,
    initAssigned: null,
    resourceId: taskToUpdate ? taskToUpdate._id : null,
    resourceName: taskToUpdate ? "WBS" : null,
    customShareBtn: null,
    customAssignBtn: assignBtn,
  });

  const createTask = async () => {
    console.log("sharedProfilesRole: ", sharedProfilesRole);
    console.log("assignedProfiles: ", assignedProfiles);

    try {
      setLoadingPrimary(true);
      const { data } = await Api.post("/wbs/create-custom", {
        targetId: targetId,
        taskName: taskName,
        startDate: startDate.toDate(),
        endDate: endDate.toDate(),
        comment: comment,
        files: filesArr,
        labels: labels?.map((l) => l?._id) || [],
        category: category?._id,
        creatorProfileId: user?.profile,
        creatorId: user?._id,
        parentTaskId: parentTaskId,
        shared:
          sharedProfilesRole && sharedProfilesRole.length > 0
            ? sharedProfilesRole.map((s) => s?._id)
            : [],
        assigned:
          assignedProfiles && assignedProfiles.length > 0
            ? assignedProfiles.map((s) => s?._id)
            : [],
        accessRoles: sharedProfilesRole,
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Task created successfully",
          },
        });
        setShowAddTaskDialog(false);
        setFileIds([]);
        setFilesArr([]);
        onTaskAdd(data);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while creating task",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while creating task",
        },
      });
    } finally {
      setLoadingPrimary(false);
    }
  };

  //   try {
  //     const { data } = await Api.post(`/label/get`, {
  //       libraryId: library?._id,
  //     });
  //     if (data) {
  //       setLabels(data);
  //     } else {
  //       dispatch({
  //         type: "AddApiAlert",
  //         payload: {
  //           success: false,
  //           message: "Unable to get labels",
  //         },
  //       });
  //     }
  //   } catch (e) {
  //     console.log(e);
  //     dispatch({
  //       type: "AddApiAlert",
  //       payload: {
  //         success: false,
  //         message: "Unable to get labels",
  //       },
  //     });
  //   }
  // };

  const updateTask = async () => {
    try {
      setLoadingPrimary(true);
      const { data } = await Api.post("/wbs/update-custom", {
        _id: taskToUpdate?._id,
        updateBody: {
          taskName: taskName,
          startDate: startDate.toDate(),
          endDate: endDate.toDate(),
          comment: comment,
          files: filesArr,
          labels: labels?.map((l) => l?._id) || [],
          category: category?._id,
          assignees: assignees,
          creator: user?.profile,
          shared:
            sharedProfilesRole && sharedProfilesRole.length > 0
              ? sharedProfilesRole.map((s) => s?._id)
              : [],
          assigned:
            assignedProfiles && assignedProfiles.length > 0
              ? assignedProfiles.map((s) => s?._id)
              : [],
          accessRoles: sharedProfilesRole,
        },
      });
      if (data) {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Task updated successfully",
          },
        });
        setShowAddTaskDialog(false);
        setFileIds([]);
        setFilesArr([]);
        onTaskUpdate(data);
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while creating task",
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while creating task",
        },
      });
    } finally {
      setLoadingPrimary(false);
    }
  };

  const deleteTask = async () => {
    try {
      setLoadingSecondary(true);
      const { data } = await Api.post("/wbs/delete", {
        taskIds: [taskToUpdate?._id],
      });

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Task deleted successfully",
        },
      });

      setShowAddTaskDialog(false);
      onTaskDelete(taskToUpdate);
    } catch (error) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while creating task",
        },
      });
    } finally {
      setLoadingSecondary(false);
    }
  };


  useEffect(() => {
    if (taskToUpdate?._id) {

      console.log("taskToUpdate: ", taskToUpdate);

      setTaskName(taskToUpdate?.taskName);
      setStartDate(dayjs(taskToUpdate?.startDate));
      setEndDate(dayjs(taskToUpdate?.endDate));
      setComment(taskToUpdate?.comment);
      setLabels(taskToUpdate?.labels);
      setCategory(taskToUpdate?.category);
      setAssignees(taskToUpdate?.assignees || []);

      let _docs = taskToUpdate?.docs || [];
      let _files = [];
      for (let i = 0; i < _docs.length; i++) {
        let _doc = _docs[i];
        let _docFiles = _doc?.files || [];
        for (let j = 0; j < _docFiles.length; j++) {
          let _docFile = _docFiles[j];
          _files.push(_docFile);
        }
      }

      setFilesArr(_files);
      setFileIds(_files.map((f) => f?._id) || []);
    }
  }, [taskToUpdate]);

  return (
    <DrawerContainer
      open={showAddTaskDialog}
      setOpen={setShowAddTaskDialog}
      title={taskToUpdate ? "Update Task" : "Create Task"}
    >
      <FormBox label="Task Name">
        <TextField
          placeholder="Enter"
          value={taskName}
          onChange={(e) => setTaskName(e.target.value)}
          fullWidth={true}
        />
      </FormBox>

      <SpaceBetween
        left={
          <FormBox label="Start Date">
            <DatePicker
              fullWidth={true}
              value={startDate}
              onChange={setStartDate}
            />
          </FormBox>
        }
        right={
          <FormBox label="End Date">
            <DatePicker
              fullWidth={true}
              value={endDate}
              onChange={setEndDate}
            />
          </FormBox>
        }
      />

      <FormBox label="Comment">
        <TextField
          placeholder="Enter"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          fullWidth={true}
          rows={4}
          multiline={true}
        />
      </FormBox>

      <FormBox label="Categories">
        <CategoryAutocomplete
          libraryId={library?._id}
          selectedCategories={category}
          setSelectedCategories={setCategory}
          isMultiple={false}
          label={"Category"}
          profileId={profileId}
          type={null}
          isCreateNew={true}
          placeholder="Select Category"
        />
      </FormBox>
      <FormBox label="Labels">
        <CategoryAutocomplete
          libraryId={library?._id}
          selectedCategories={labels}
          setSelectedCategories={setLabels}
          isMultiple={true}
          label={"Label"}
          profileId={profileId}
          type={null}
          isCreateNew={true}
          placeholder="Select Label"
        />
      </FormBox>
      <FormBox label="Assignees">
        {AssignButton} {AssigneDrawer}
        <div>{AssignView}</div>
      </FormBox>

      <FormBox label="Attachments">
        <DocumentUploader
          documents={filesArr}
          onUpload={() => {
            setFileIds(createdFileIds);
            let data = [];
            for (let i = 0; i < createdFileIds?.length; i++) {
              data.push(fileDictionary[createdFileIds[i]]);
            }
            setFilesArr(data);
          }}
          onDelete={(dataId) => {
            setFileIds(fileIds.filter((m) => m !== dataId));
            setFilesArr(filesArr.filter((m) => m._id !== dataId));
          }}
        />
        <Box>
          {filesArr?.length > 0 ? (
            <Box>
              <DocViewer
                data={filesArr}
                isDeletable={true}
                disableEditMetaData={true}
                disableShowMetaData={true}
                onMetadataChange={() => {}}
                deleteFunction={(delId) => {
                  setFileIds(fileIds.filter((m) => m !== delId));
                  setFilesArr(filesArr.filter((m) => m._id !== delId));
                }}
              />
            </Box>
          ) : (
            <Box>No documents found</Box>
          )}
        </Box>
        <DuoButtonGroup
          primaryButtonText={taskToUpdate?._id ? "Update Task" : "Create Task"}
          primaryButtonListener={() => {
            taskToUpdate?._id ? updateTask() : createTask();
          }}
          secondaryButtonText="Delete"
          secondaryButtonListener={() => deleteTask()}
          secondaryButtonColor="error"
          hideSecondary={!taskToUpdate?._id}
          loadingPrimary={loadingPrimary}
          loadingSecondary={loadingSecondary}
        />
      </FormBox>
    </DrawerContainer>
  );
};

export default AddTaskDialog;
