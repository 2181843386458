import React from "react";
import DocumentViewer from "./DocumentViewer";
import { Box, Grid, Stack, TextField } from "@mui/material";

const DocViewerWithMetaData = ({
  file,
  isDeletable,
  deleteFunction,
  onMetadataChange,
}) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        padding: "15px",
        borderRadius: "10px",
        border: "1px solid #F3F3F3",
        background: "#F5F5F5",
      }}
    >
      <DocumentViewer
        file={file}
        isDeletable={isDeletable}
        removeFun={deleteFunction}
      />
      <Box sx={{ ml: 2 }} flex="1">
        <Stack spacing={1}>
          <TextField
            size="small"
            placeholder="Title"
            fullWidth
            sx={{ backgroundColor: "#fff" }}
            defaultValue={file?.title || ""}
            onChange={(evt) =>
              onMetadataChange(file?._id, "title", evt.target.value)
            }
          />
          <TextField
            size="small"
            placeholder="Tags"
            fullWidth
            sx={{ backgroundColor: "#fff" }}
            defaultValue={file?.title || ""}
            onChange={(evt) =>
              onMetadataChange(file?._id, "tag", evt.target.value)
            }
          />
          <TextField
            size="small"
            placeholder="Caption"
            fullWidth
            multiline
            rows={3}
            sx={{ backgroundColor: "#fff" }}
            defaultValue={file?.title || ""}
            onChange={(evt) =>
              onMetadataChange(file?._id, "description", evt.target.value)
            }
          />
        </Stack>
      </Box>
    </Box>
  );
};

const DocViewer = ({
  data,
  isDeletable,
  deleteFunction,
  disableShowMetaData,
  disableEditMetaData,
  onMetadataChange,
}) => {
  return (
    <Grid container spacing={2}>
      {data?.map((file, i) => (
        <Grid item xs={disableShowMetaData ? "auto" : 6} key={i}>
          {disableShowMetaData ? (
            <DocumentViewer
              file={file}
              isDeletable={isDeletable}
              removeFun={deleteFunction}
            />
          ) : (
            <DocViewerWithMetaData
              file={file}
              isDeletable={isDeletable}
              deleteFunction={deleteFunction}
              onMetadataChange={onMetadataChange}
            />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default DocViewer;
