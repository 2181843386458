import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles"
import { useParams, useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    mainInfoCont: {
        width: "100%",
        overflowY: "auto",
        padding: "15px 20px",
        backgroundColor: "white",
        boxShadow: "rgba(171, 190, 209, 0.5) 0px 8px 24px",
        marginBottom: "30px",
        borderRadius: "10px"
    },
    sectionLable: {
        "& div": {
            padding: "5px 0px",
            marginTop: "10px"
        }
    },
    infoCont: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between"
    },
    halfInfoCont: {
        width: "49%"
    },
    lableValue: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        padding: "5px 0px",
    },
    leftSty: {
        width: "150px",
        height: "30px"
    },
    rightSty: {
        width: "calc(100% - 155px)",
        height: "30px"
    },
    imageCardCont: {
        width: "25%",
        padding: "10px",
    },
    imageCard: {
        width: "100%",
        height: "90px"
    },
    allImagesCont: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        marginBottom: "10px"
    }
}));

const AssetTopDetailsSkeleton = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    return (<div className={classes.mainInfoCont} >
        <div className={classes.sectionLable} >
            <div>
                <Skeleton style={{ width: "200px", height: "30px" }} variant="rect" />
            </div>
        </div>
        <div className={classes.infoCont} >
            <div className={classes.halfInfoCont} >
                <div className={classes.lableValue} >
                    <Skeleton className={classes.leftSty} variant="rect" />
                    <Skeleton className={classes.rightSty} variant="rect" />
                </div>
                <div className={classes.lableValue} >
                    <Skeleton className={classes.leftSty} variant="rect" />
                    <Skeleton className={classes.rightSty} variant="rect" />
                </div>
                <div className={classes.lableValue} >
                    <Skeleton className={classes.leftSty} variant="rect" />
                    <Skeleton className={classes.rightSty} variant="rect" />
                </div>
                <div className={classes.lableValue} >
                    <Skeleton className={classes.leftSty} variant="rect" />
                    <Skeleton className={classes.rightSty} variant="rect" />
                </div>
                <div className={classes.lableValue} >
                    <Skeleton className={classes.leftSty} variant="rect" />
                    <Skeleton className={classes.rightSty} variant="rect" />
                </div>
                <div className={classes.lableValue} >
                    <Skeleton className={classes.leftSty} variant="rect" />
                    <Skeleton className={classes.rightSty} variant="rect" />
                </div>
                <div className={classes.lableValue} >
                    <Skeleton className={classes.leftSty} variant="rect" />
                    <Skeleton className={classes.rightSty} variant="rect" />
                </div>
                <div className={classes.lableValue} >
                    <Skeleton className={classes.leftSty} variant="rect" />
                    <Skeleton className={classes.rightSty} variant="rect" />
                </div>
                <div className={classes.lableValue} >
                    <Skeleton className={classes.leftSty} variant="rect" />
                    <Skeleton className={classes.rightSty} variant="rect" />
                </div>
                <div className={classes.lableValue} >
                    <Skeleton className={classes.leftSty} variant="rect" />
                    <Skeleton className={classes.rightSty} variant="rect" />
                </div>
                <div className={classes.lableValue} >
                    <Skeleton className={classes.leftSty} variant="rect" />
                    <Skeleton className={classes.rightSty} variant="rect" />
                </div>
            </div>

            <div className={classes.halfInfoCont} >
                <Skeleton style={{ width: "200px", height: "30px" }} variant="rect" />
                <div className={classes.allImagesCont} >
                    <div className={classes.imageCardCont} >
                        <Skeleton className={classes.imageCard} variant="rect" />
                    </div>
                    <div className={classes.imageCardCont} >
                        <Skeleton className={classes.imageCard} variant="rect" />
                    </div>
                    <div className={classes.imageCardCont} >
                        <Skeleton className={classes.imageCard} variant="rect" />
                    </div>
                    <div className={classes.imageCardCont} >
                        <Skeleton className={classes.imageCard} variant="rect" />
                    </div>
                    <div className={classes.imageCardCont} >
                        <Skeleton className={classes.imageCard} variant="rect" />
                    </div>
                    <div className={classes.imageCardCont} >
                        <Skeleton className={classes.imageCard} variant="rect" />
                    </div>
                    <div className={classes.imageCardCont} >
                        <Skeleton className={classes.imageCard} variant="rect" />
                    </div>
                </div>

                <div className={classes.lableValue} >
                    <Skeleton className={classes.leftSty} variant="rect" />
                    <Skeleton className={classes.rightSty} variant="rect" />
                </div>
                <div className={classes.lableValue} >
                    <Skeleton className={classes.leftSty} variant="rect" />
                    <Skeleton className={classes.rightSty} variant="rect" />
                </div>
                <div className={classes.lableValue} >
                    <Skeleton className={classes.leftSty} variant="rect" />
                    <Skeleton className={classes.rightSty} variant="rect" />
                </div>
                <div className={classes.lableValue} >
                    <Skeleton className={classes.leftSty} variant="rect" />
                    <Skeleton className={classes.rightSty} variant="rect" />
                </div>
            </div>
        </div>
    </div>);
};

export default AssetTopDetailsSkeleton;