import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, Cell, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import emptyfolder from "../../../Assets/FileIcon/emptyfolder.png";
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    emptySty: {
        width: "100%",
        padding: "20px 0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& img": {
            width: "150px",
            height: "auto",
        },
        "& p": {
            fontSize: "12px",
            fontWeight: "300",
            textAlign: "center",
        },
    },
}))

const SimpleBarChart = ({ chartData, width, height }) => {
    const classes = useStyles();

    return (<>{chartData && chartData.length>0 ? (<ResponsiveContainer width={width} height={height} >
            <BarChart
                data={chartData}
                width={width}
                height={height}
                margin={{ top: 20, right: 20, left: 20, bottom: 5 }}
            >
                <XAxis
                    dataKey="name"
                    stroke="#000000"
                />
                <YAxis
                    stroke="#000000"
                />
                <Tooltip
                    wrapperStyle={{ width: 100, backgroundColor: '#ccc' }}
                    formatter={(value) => `${value}`}
                />
                <Bar
                    dataKey="value"
                    strokeWidth={1}
                >
                    {
                        chartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry?.color} />
                        ))
                    }
                </Bar>
            </BarChart>
        </ResponsiveContainer>) : (<div className={classes.emptySty} >
                    <img src={emptyfolder} />
                    <p>No result is available!</p>
                </div>)}
    </>);
};

export default SimpleBarChart;