import { Close } from "@material-ui/icons";
import { Box, Divider, Drawer, IconButton, Typography } from "@mui/material";
import React from "react";

const DrawerContainer = ({
  title,
  open,
  setOpen,
  openFrom = "right",
  children,
  width = "40vw",
  hideHeader = false,
  backgroundColor = "#FFFFFF",
  sx,
}) => {
  return (
    <Drawer
      anchor={openFrom}
      open={open}
      onClose={() => setOpen(false)}
      sx={{ ...sx }}
    >
      <Box
        sx={{ width: width, backgroundColor: backgroundColor, height: "100%" }}
      >
        <Box>
          {!hideHeader && (
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                sx={{ px: 4, py: 2 }}
              >
                <Typography variant="h5">{title}</Typography>
                <IconButton onClick={() => setOpen(false)}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
            </>
          )}
          <Box sx={{ px: 4, py: 2 }}>{children}</Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DrawerContainer;
