import { Typography } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"
import React, { useState, useCallback, useEffect } from "react";
import { PieChart, Tooltip, Pie, Sector, Cell, ResponsiveContainer } from "recharts";
import emptyfolder from "../../../Assets/FileIcon/emptyfolder.png";

const useStyles = makeStyles((theme) => ({
    chartAndLableCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "0px 15px",
        justifyContent: "space-evenly",
        "& table": {
            width: "auto"
        },
        "& tr": {
            height: "auto"
        },
        "& td": {
            fontSize: "14px",
            padding: "1px 10px",
            fontWeight: "500"
        }
    },
    emptySty: {
        width: "100%",
        padding: "20px 0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& img": {
            width: "150px",
            height: "auto",
        },
        "& p": {
            fontSize: "12px",
            fontWeight: "300",
            textAlign: "center",
        },
    },
}))

function SimplePieGraph({ Data, chartRadius, heightNum }) {
    const classes = useStyles();

    const [filteredArr, setFilteredArr] = useState([])
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        let filteredObj = [];
        let arrWithPercentage = []
        let total = 0;

        Data && Data.length > 0 && Data.map((d) => {
            if (d?.value !== 0) {
                filteredObj.push(d)
            }
            total += parseFloat(d?.value)
        })

        filteredObj.map((filterD) => {
            let perc = (((parseFloat(filterD?.value)) / total) * 100).toFixed(0);
            arrWithPercentage.push({
                ...filterD,
                value: parseFloat(filterD?.value),
                percentage: perc
            })
        })
        setFilteredArr(arrWithPercentage)
    }, [Data])

    const onPieEnter = useCallback((_, index) => {
        setActiveIndex(index);
    }, [setActiveIndex]);

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <Paper elevation={1} style={{ backgroundColor: "#ffffff", borderRadius: "3px", padding: "3px 8px" }} >
                    <Typography style={{ fontSize: "15px", fontWeight: "530" }} >{payload[0]?.name}</Typography>
                    <Typography style={{ fontSize: "13px" }} >{`${payload[0]?.value}  (${payload[0]?.payload?.percentage}%)`}</Typography>
                </Paper>
            );
        }
        return null;
    };

    const renderActiveShape = (props) => {
        const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
            </g>
        );
    };

    const RADIAN = Math.PI / 180;

    const renderCustomizedLabel = ({
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        percent,
        index
    }) => {

        const percentageVal = (percent * 100).toFixed(0)
        let radius
        if (percentageVal > 6) {
            radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        } else {
            radius = innerRadius + (outerRadius - innerRadius) * 0.8;
        }
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text
                x={x}
                y={y}
                fill="white"
                textAnchor={"middle"}
                dominantBaseline="middle"
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    return (<>{Data && Data.length > 0 ? (
        <div className={classes.chartAndLableCont} style={{ height: heightNum }} >
            <div>
                <table>
                    <tbody>
                        {Data && Data.map((s) => (
                            <tr>
                                <td>{s?.name}</td>
                                <td>
                                    <div
                                        style={{
                                            width: "12px",
                                            height: "12px",
                                            backgroundColor: s?.color
                                        }}
                                    >
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div style={{ height: heightNum, width: heightNum }} >
                <ResponsiveContainer width="100%" height="100%">
                    <PieChart width={400} height={400}>
                        <Pie
                            activeIndex={activeIndex}
                            activeShape={renderActiveShape}
                            data={filteredArr}
                            cx="50%"
                            cy="50%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={chartRadius}
                            fill="#8884d8"
                            dataKey="value"
                            onMouseEnter={onPieEnter}
                            isAnimationActive={false}
                        >
                            {filteredArr.map((entry, index) => (
                                <Cell key={`cell-${index}`} fill={entry?.color} />
                            ))}
                        </Pie>
                        <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                </ResponsiveContainer>
            </div>
        </div>
    ) : (<div className={classes.emptySty} >
        <img src={emptyfolder} />
        <p>No result is available!</p>
    </div>)}
    </>);
}

export default SimplePieGraph;