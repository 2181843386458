import { Box, Button, IconButton, Slider, Typography } from "@mui/material";
import React, { useState } from "react";
import { getProgressColor } from "../../../../helpers/colorHelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import BoxSpaceBetween from "../../../styled/generic/BoxSpaceBetween";
import HorizBox from "../../../styled/generic/HorizBox";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";
import { useDispatch, useSelector } from "react-redux";
import DocumentUploader from "../../../styled/generic/DocumentUploader";
import DocViewer from "../../../styled/generic/DocViewer";
import Api from "../../../../helpers/Api";
import DrawerContainer from "../../../styled/generic/DrawerContainer";
import SpaceBetween from "../../../styled/generic/SpaceBetween";
import FormBox from "../../../styled/generic/FormBox";
import TextField from "../../../styled/generic/TextField";
import SingleUserWithCaptionTableCell from "../../../styled/generic/SingleUserWithCaptionTableCell";
import TaskProgressUpdateDrawer from "./TaskProgressUpdateDrawer";
import { Edit, EditOutlined } from "@mui/icons-material";
import AddTaskDialog from "./AddTaskDialog";

const TaskCard = ({
  task,
  setTask,
  setTasks,
  disableBorder = false,
  disableElevation = false,
}) => {
  const history = useHistory();
  const files = useSelector((state) => state.file);
  const { fileDictionary, createdFileIds } = files;
  const dispatch = useDispatch();
  const [showUpdateTaskDialog, setShowUpdateTaskDialog] = useState(false);
  const [taskToUpdate, setTaskToUpdate] = useState(null);
  const [workDoneToday, setWorkDoneToday] = useState(0);
  const [workLeft, setWorkLeft] = useState(0);
  const [comment, setComment] = useState("");
  const [documents, setDocuments] = useState([]);
  const [documentIds, setDocumentIds] = useState([]);
  const [showEditTaskDialog, setShowEditTaskDialog] = useState(false);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          p: 2,
          borderRadius: "12px",
          border: disableBorder ? "none" : "1px solid rgba(0,0,0,0.10)",
          boxShadow: disableElevation
            ? "none"
            : "0px 5px 10px 0px rgba(0, 0, 0, 0.10)",
          cursor: "pointer",
        }}
        onClick={() => {
          history.push(`/planning/task/${task?._id}`);
        }}
      >
        <BoxSpaceBetween>
          <Typography variant="body1">
            {String(task?.taskName).toUpperCase() || "Untitled"}
          </Typography>
          <HorizBox>
            {" "}
            <SingleUserWithCaptionTableCell
              label={task?.creator?.parent?.displayName}
              src={task?.creator?.parent?.displayPicture?.url}
              avatarTextGap={1}
              avatarSx={{
                width: "24px",
                height: "24px",
              }}
            />
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setTaskToUpdate(task);
                setShowEditTaskDialog(true);
              }}
              size="small"
              color="primary"
            >
              <EditOutlined />
            </IconButton>
          </HorizBox>
        </BoxSpaceBetween>
        <HorizBox sx={{ my: 1 }}>
          {task?.labels?.slice(0, 2).map((item) => (
            <div
              style={{
                backgroundColor: item?.color + "33",
                color: item?.color,
                padding: "4px 8px",
                borderRadius: "10px",
              }}
            >
              {item?.title}
            </div>
          ))}{" "}
          {task?.labels?.length > 2 ? (
            <div>+{task?.labels?.length - 2}</div>
          ) : null}
        </HorizBox>
        <BoxSpaceBetween
          sx={{
            backgroundColor: getProgressColor(task?.progress)?.backgroundColor,
            p: 1,
            borderRadius: "10px",
            mt: 2,
          }}
        >
          <HorizBox>
            <Typography
              variant="h6"
              sx={{
                color: getProgressColor(task?.progress)?.color,
              }}
            >
              {task?.progress || 0}%
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                color: getProgressColor(task?.progress)?.color,
              }}
            >
              Progress
            </Typography>
          </HorizBox>
          <Box>
            <Button
              sx={{
                backgroundColor: "white",
                color: getProgressColor(task?.progress)?.color,
                width: "100px",
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                setTaskToUpdate(task);
                setShowUpdateTaskDialog(true);
              }}
            >
              Update
            </Button>
          </Box>
        </BoxSpaceBetween>
      </Box>
      <TaskProgressUpdateDrawer
        open={showUpdateTaskDialog}
        setOpen={setShowUpdateTaskDialog}
        setTasks={setTasks}
        setTask={setTask}
        taskToUpdate={taskToUpdate}
      />
      <AddTaskDialog
        showAddTaskDialog={showEditTaskDialog}
        setShowAddTaskDialog={setShowEditTaskDialog}
        targetId={task?.target?._id}
        onTaskUpdate={(newTask) => {
          setTasks((prev) => {
            const index = prev.findIndex((item) => item._id === newTask._id);
            if (index > -1) {
              prev[index] = newTask;
              return [...prev];
            }
            return [...prev, newTask];
          });
        }}
        onTaskDelete={(deletedTask) => {
          setTasks((prev) => {
            return prev.filter((item) => item._id !== deletedTask._id);
          });
        }}
        profileId={task?.target?.organization?.profile}
        library={task?.target?.buildingCodeLibrary}
        project={task?.target?.project?._id}
        organization={task?.target?.organization?._id}
        taskToUpdate={taskToUpdate}
      />
    </>
  );
};

export default TaskCard;
