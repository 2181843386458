import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles"
import { useParams, useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getAssetById } from "../api.call";
import { Avatar } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import moment from "moment";
import EventsByAsset from "./EventsByAsset";
import AssetTopDetailsSkeleton from "../../styled/skeletons/Asset.Top.Details.Skeleton";

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100vh",
        overflow: "hidden",
        backgroundColor: "white"
    },
    header: {
        width: "100%",
        height: "50px",
        borderBottom: "1px solid #ececec"
    },
    bodyCont: {
        width: "100%",
        height: "calc(100vh - 50px)",
        overflowY: "auto",
        overflowX: "hidden",
        padding: "30px",
    },
    mainInfoCont: {
        width: "100%",
        overflowY: "auto",
        padding: "15px 20px",
        backgroundColor: "white",
        boxShadow: "rgba(171, 190, 209, 0.5) 0px 8px 24px",
        marginBottom: "30px",
        borderRadius: "10px"
    },
    sectionLable: {
        "& div": {
            backgroundColor: "#EEF5FC",
            padding: "5px 10px",
            marginTop: "10px",
            fontSize: "16px",
            fontWeight: "500"
        }
    },
    infoCont: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
    },
    halfInfoCont: {
        width: "49%",
        "& h4": {
            fontSize: "16px",
            fontWeight: "600"
        }
    },
    lableValue: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        padding: "5px 0px",
        "& h3": {
            width: "150px",
            minWidth: "110px",
            fontSize: "15px",
            fontWeight: "500",
            color: "gray",
            textAlign: "left"
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "500",
            color: "black",
            textAlign: "left",
            padding: "1px 5px",
            borderRadius: "3px"
        },
    },
    eventsCont: {
        width: "100%",
        maxHeight: "calc(100vh - 80px)",
        overflow: "hidden",
        padding: "15px",
        backgroundColor: "white",
        boxShadow: "rgba(171, 190, 209, 0.5) 0px 8px 24px",
        borderRadius: "10px"
    },
    imageCardCont: {
        width: "25%",
        padding: "10px",
    },
    imageCard: {
        width: "100%",
        height: "90px",
        backgroundPosition: "center",
        backgroundSize: "contain",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"
    },
    allImagesCont: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        marginBottom: "10px"
    },
    selectedUserOrgSty: {
        maxWidth: "100%",
        padding: "2px 5px",
        cursor: "pointer",
        border: "1px solid #c3c2c2",
        borderRadius: "8px",
        marginRight: "5px",
        marginBottom: "5px",
        display: "flex",
        alignItems: "center",
        "& .MuiSvgIcon-root": {
            fontSize: "20px",
            marginLeft: "5px"
        },
        "& .MuiIconButton-root": {
            padding: "3px"
        },
        "& .MuiAvatar-root": {
            width: "30px",
            height: "30px",
        },
        "& h3": {
            fontSize: "13px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "10px",
            fontWeight: "400",
            color: "gray"
        }
    },
    avatarGroupSty: {
        "& .MuiAvatar-root": {
            width: "30px",
            height: "30px",
        },
    },
}));

const AssetDetails = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();
    const { assetId } = useParams();
    const { user } = useSelector((state) => state.auth);

    const [detailAsset, setDetailAsset] = useState();
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        setLoader(true)
        getAssetById({ assetId })
            .then((data) => {
                let assign1 = data?.assignToTeam
                let assign2 = data?.assignToIndividual
                var a = moment(new Date());
                var b = moment(data?.warratyExpiration);
                let c = moment(data?.purchaseDate)
                setDetailAsset({
                    ...data,
                    warrantyMonths: b.diff(a, 'months'),
                    lifeLeft: (parseInt(data?.fulLifeInMonth) - parseInt(a.diff(c, 'months'))),
                    assignTo: [...assign1, ...assign2]
                })
                setLoader(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [assetId])

    const returnStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case "unassigned":
                return "#D3D3D3";
            case "moved":
                return "#0000FF";
            case "assigned":
                return "#00FF00";
            case "repair":
                return "#FFA500";
            case "lost":
                return "#FF0000";
            case "expired":
                return "#808080";
            case "sold":
                return "#800080";
            default:
                return "white";
        }
    };

    return (<div className={classes.mainCont} >
        <div className={classes.header} >

        </div>
        <div className={classes.bodyCont} >
            {loader ? (<AssetTopDetailsSkeleton />) : (
                <div className={classes.mainInfoCont} >
                    <div className={classes.sectionLable} >
                        <div>
                            Primary Information
                        </div>
                    </div>
                    <div className={classes.infoCont} >
                        <div className={classes.halfInfoCont} >
                            <div className={classes.lableValue} >
                                <h3>Asset Name/ID</h3>
                                <p>{detailAsset?.name}</p>
                            </div>
                            <div className={classes.lableValue} >
                                <h3>Category</h3>
                                <p>{detailAsset?.category?.name}</p>
                            </div>
                            <div className={classes.lableValue} >
                                <h3>Type</h3>
                                <p>{detailAsset?.type}</p>
                            </div>
                            <div className={classes.lableValue} >
                                <h3>Location</h3>
                                <p>{detailAsset?.location}</p>
                            </div>
                            <div className={classes.lableValue} >
                                <h3>Status</h3>
                                <p style={{ backgroundColor: returnStatusColor(detailAsset?.assetStatus), padding: "1px 5px", borderRadius: "3px" }} >{detailAsset?.assetStatus}</p>
                            </div>

                            <div className={classes.lableValue} >
                                <h3>Notify To</h3>
                                <AvatarGroup max={4} className={classes.avatarGroupSty} >
                                    {detailAsset?.notifiyTo && detailAsset?.notifiyTo.map((participant, idx) => (
                                        <Avatar
                                            key={idx}
                                            title={participant?.parent?.displayName}
                                            alt={participant?.parent?.displayName}
                                            src={participant?.parent?.displayPicture?.url}
                                        />
                                    ))}
                                </AvatarGroup>
                            </div>
                            <div className={classes.lableValue} >
                                <h3>Assigned To</h3>
                                <AvatarGroup max={4} className={classes.avatarGroupSty} >
                                    {detailAsset?.assignTo && detailAsset?.assignTo.map((participant, idx) => (
                                        <Avatar
                                            key={idx}
                                            title={participant?.parent?.displayName}
                                            alt={participant?.parent?.displayName}
                                            src={participant?.parent?.displayPicture?.url}
                                        />
                                    ))}
                                </AvatarGroup>
                            </div>
                            <div className={classes.lableValue} >
                                <h3>Manufacturer</h3>
                                <p>{detailAsset?.manufacturer}</p>
                            </div>
                            <div className={classes.lableValue} >
                                <h3>Model</h3>
                                <p>{detailAsset?.model}</p>
                            </div>
                            <div className={classes.lableValue} >
                                <h3>Purchase Cost</h3>
                                <p>{detailAsset?.purchasePrice}</p>
                            </div>
                            <div className={classes.lableValue} >
                                <h3>Vendor</h3>
                                <div className={classes.selectedUserOrgSty} >
                                    {detailAsset?.vendor?._id && (<>
                                        <Avatar
                                            src={detailAsset?.vendor?.parent?.displayPicture?.thumbUrl ? detailAsset?.vendor?.parent?.displayPicture?.thumbUrl : detailAsset?.vendor?.parent?.displayPicture?.url}
                                            alt={detailAsset?.vendor?.parent?.displayName}
                                        />
                                        <div style={{ marginLeft: "5px" }} >
                                            <h3>{detailAsset?.vendor?.parent?.displayName}</h3>
                                            <p>{detailAsset?.vendor?.parent?.username}</p>
                                        </div>
                                    </>)}
                                </div>
                            </div>
                        </div>

                        <div className={classes.halfInfoCont} >
                            {detailAsset?.files && detailAsset?.files.length > 0 && (<>
                                <h4>Images/Pictures</h4>
                                <div className={classes.allImagesCont} >
                                    {detailAsset?.files && detailAsset.files.map((file) => (<div className={classes.imageCardCont} >
                                        <div
                                            className={classes.imageCard}
                                            style={{ backgroundImage: `url(${file?.thumbUrl ? file?.thumbUrl : file?.url})` }}
                                        ></div>
                                    </div>))}
                                </div>
                            </>)}
                            <div className={classes.lableValue} >
                                <h3>Purchase Date</h3>
                                <p>{detailAsset?.purchaseDate}</p>
                            </div>
                            <div className={classes.lableValue} >
                                <h3>Warranty Expiration</h3>
                                <p>{detailAsset?.warrantyMonths} Month(s)</p>
                            </div>
                            <div className={classes.lableValue} >
                                <h3>Next Service Date</h3>
                                <p>{detailAsset?.nextServiceDate}</p>
                            </div>
                            <div className={classes.lableValue} >
                                <h3>End Of Life</h3>
                                <p>{detailAsset?.lifeLeft} Month(s)</p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className={classes.eventsCont} >
                <EventsByAsset assetId={assetId} />
            </div>
        </div>
    </div>);
};

export default AssetDetails;