import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import { useParams, useHistory } from 'react-router-dom';
import CategoryTree from "./CategoryTree";
import SvgIcon from '@material-ui/core/SvgIcon';
import TreeView from '@material-ui/lab/TreeView';

function MinusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
        </SvgIcon>
    );
}

function PlusSquare(props) {
    return (
        <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
        </SvgIcon>
    );
}

function CloseSquare(props) {
    return (
        <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
            <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
        </SvgIcon>
    );
}

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },
    tableHead: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#E7ECFD",
        fontSize: "0.875rem",
        fontWeight: "500",
        padding: "0px 25px",
        "& p": {
            fontSize: "15px"
        }
    },
    categoiresCont: {
        width: "100%",
        height: "calc(100% - 50px)",
        overflowX: "hidden",
        overflowY: "auto"
    },
    treeCont: {
        width: "100%",
    },
}));

const CategoriesByLibrary = ({
    currentLibraryCategory, setSelectedCategory,
    setOpenOfferingDrawer, categorySearchStr,
    setCategorySearchStr, categoryAction
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();
    const { profileId } = useParams();

    useEffect(() => {
        setCategorySearchStr("")
    }, [currentLibraryCategory])

    const openOfferingsDrawer = (category) => {
        setSelectedCategory(category)
        setOpenOfferingDrawer(true)
    }


    return (<div className={classes.mainCont} >
        <div className={classes.tableHead} >
            <p>Category Name</p>
            <p>Action</p>
        </div>
        <div className={classes.categoiresCont} >
            <TreeView
                className={classes.treeCont}
                expanded={currentLibraryCategory.categoriesIds}
                defaultCollapseIcon={<MinusSquare />}
                defaultExpandIcon={<PlusSquare />}
                defaultEndIcon={<CloseSquare />}
            >
                {currentLibraryCategory.category && currentLibraryCategory.category.map((category, i) => (
                    <CategoryTree
                        category={category}
                        key={i}
                        categorySearchStr={categorySearchStr}
                        categoryAction={categoryAction}
                        openOfferingsDrawer={openOfferingsDrawer}
                    />
                ))}
            </TreeView>
        </div>
    </div>);
};

export default CategoriesByLibrary;