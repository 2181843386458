import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles"
import { Avatar, IconButton, Button } from "@material-ui/core"
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchEntityDialog from "../styled/CommonComponents/SearchEntityDrawer";
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from "@material-ui/icons/Add";
import LinearProgress from "@material-ui/core/LinearProgress";
import { mainRoles } from "../../helpers/contactHelper"
import { createAndUpdateFinalcialRelation } from "../styled/CommonComponents/api.call";

const useStyles = makeStyles((theme) => ({
    mainBodyCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 50px)",
        padding: "10px 10px 20px",
        overflowY: "auto",
    },
    usersCont: {
        width: "100%",
        height: "100px",
        fontSize: "16px",
        fontWeight: "500",
        borderRadius: "10px",
        border: `2px dashed ${theme.palette.primary.main}`,
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "center",
        backgroundColor: "#f5f5f5",
        "&:hover": {
            backgroundColor: "#ececec",
        }
    },
    bottomCont: {
        width: "100%",
        height: "45px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0px 10px"
    },
    lableSty: {
        fontSize: "16px",
        fontWeight: "500",
        marginBottom: "5px",
        marginTop: "15px",
    },
    mainTreeCont: {
        width: "100%",
        maxHeight: "calc(100% - 150px)",
        overflowX: "hidden",
        overflowY: "auto",
        backgroundColor: "#f9f9f9",
        border: "1px solid #dfdfdf",
        padding: "10px 15px",
        borderRadius: "5px"
    },
    selectedParticipantsCont: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: "#f9f9f9",
        padding: "5px 5px 0px",
        border: "1px solid #dfdfdf",
        "& .MuiButton-root": {
            height: "38px",
            borderRadius: "5px",
            marginBottom: "5px"
        }
    },
    selectedUserOrgSty: {
        maxWidth: "100%",
        padding: "2px 5px",
        borderRadius: "5px",
        border: "1px solid #dcdcdc",
        marginRight: "5px",
        marginBottom: "5px",
        display: "flex",
        alignItems: "center",
        "& .MuiSvgIcon-root": {
            fontSize: "20px",
            marginLeft: "5px"
        },
        "& .MuiIconButton-root": {
            padding: "3px"
        },
        "& .MuiAvatar-root": {
            width: "30px",
            height: "30px",
        },
        "& h3": {
            fontSize: "13px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "10px",
            fontWeight: "400",
            color: "gray"
        }
    },
    singleInputCont: {
        marginBottom: "5px",
        marginTop: "15px",
    },
    lableSty: {
        fontSize: "16px",
        fontWeight: "500",
    },
}));

const searchParam = [
    {
        entity: "User",
        scop: [
            {
                type: "Global",
                role: []
            }
        ]
    },
    {
        entity: "Organization",
        scop: [
            {
                type: "Global",
                role: []
            }
        ]
    },
    {
        entity: "User(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    },
    {
        entity: "Group(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    },
    {
        entity: "Organization(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    }
]

const ContactCreateDrawer = ({
    openContactDrawer, setOpenContactDrawer, team, callAfterFun
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const [selectedContacts, setSelectedContacts] = useState([])
    const [loader, setLoader] = useState(false)
    const [financialRelationType, setFinancialRelationType] = useState("")
    const [openContactSelectDrawer, setOpenContactSelectDrawer] = useState(false)

    useEffect(() => {
        if (openContactDrawer) {
            setSelectedContacts([])
            setLoader(false)
            setFinancialRelationType("")
            setOpenContactSelectDrawer(false)
        }
    }, [openContactDrawer])

    const onContactAddCall = async () => {
        if (selectedContacts && selectedContacts.length > 0) {
            setLoader(true)
            let contactProfileIds = [];
            let notOnPlatformProfileIds = [];
            selectedContacts.map((profile) => {
                if (profile?.parentModelName !== "Pal") {
                    contactProfileIds.push(profile?._id)
                } else {
                    notOnPlatformProfileIds.push(profile?._id)
                }
            })

            const relObj = {
                mainProfile: team?.parent?.profile?._id,
                relationProfileIds: contactProfileIds,
                notOnPlatformProfileIds: notOnPlatformProfileIds,
                type: financialRelationType ? financialRelationType : "Customer",
                parent: team?.parent?._id,
                parentModelName: team?.parentModelName,
                addedBy: user?.profile,
                user: user?._id,
            };

            await createAndUpdateFinalcialRelation(relObj)
                .then(async (resData) => {
                    setOpenContactDrawer(false)
                    setSelectedContacts([])
                    setLoader(false)
                    setFinancialRelationType("")
                    setOpenContactSelectDrawer(false)
                    if (callAfterFun) {
                        callAfterFun()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    return (
        <NormalDrawer
            openDrawer={openContactDrawer}
            setOpenDrawer={setOpenContactDrawer}
            anchor={"right"}
            width={"40vw"}
            title={"Add New Contact(s)"}
            content={<div className={classes.mainBodyCont} >
                <div className={classes.bodyCont} >
                    <p className={classes.lableSty} >Contact(s)</p>
                    {selectedContacts && selectedContacts.length > 0 ? (
                        <div className={classes.selectedContactsCont}>
                            {selectedContacts && selectedContacts.map((selectedProfile) => (<div className={classes.selectedUserOrgSty} >
                                <Avatar
                                    src={selectedProfile?.parent?.displayPicture?.thumbUrl ? selectedProfile?.parent?.displayPicture?.thumbUrl : selectedProfile?.parent?.displayPicture?.url}
                                    alt={selectedProfile?.parent?.displayName}
                                />
                                <div style={{ marginLeft: "5px" }} >
                                    <h3>{selectedProfile?.parent?.displayName}</h3>
                                    <p>{selectedProfile?.parent?.username}</p>
                                </div>
                                <IconButton
                                    onClick={() => {
                                        const filteredEntities = selectedContacts.filter((entityProfile) => entityProfile?._id != selectedProfile?._id)
                                        setSelectedContacts([...filteredEntities])
                                    }}
                                >
                                    <CancelIcon />
                                </IconButton>
                            </div>))}
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                startIcon={<AddIcon />}
                                onClick={() => {
                                    setOpenContactSelectDrawer(true)
                                }}
                            >
                                Add Contact
                            </Button>
                        </div>
                    ) : (
                        <div
                            className={classes.usersCont}
                            onClick={() => {
                                setOpenContactSelectDrawer(true)
                            }}
                        >
                            + Add Contact(s)
                        </div>
                    )}
                    <SearchEntityDialog
                        searchParameter={searchParam}
                        givenNotAllowedProfileId={[]}
                        entity={team?.parentModelName}
                        curEntityId={team?.parent?._id}
                        onSave={(value) => {
                            setSelectedContacts(value)
                        }}
                        selectedProfile={selectedContacts}
                        noFilter={true}
                        multiple={true}
                        notCreateFinancialRelation={true}
                        open={openContactSelectDrawer}
                        setOpen={setOpenContactSelectDrawer}
                        onClickOptionHideOptions={true}
                        financialRelationType={financialRelationType}
                        title={"Select Contact(s)"}
                        noPalCreate={true}
                        allowOutSider={true}
                    />

                    <div className={classes.singleInputCont} style={{ width: "100%" }} >
                        <p className={classes.lableSty} >Role</p>
                        <Autocomplete
                            value={financialRelationType}
                            options={mainRoles}
                            getOptionLabel={(option) => { return option || "" }}
                            getOptionSelected={(option) => {
                                return option === financialRelationType
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    style={{ margin: "0px" }}
                                    margin="normal"
                                    variant="outlined"
                                    placeholder="Select role"
                                />
                            )}
                            onChange={(e, value) => { setFinancialRelationType(value) }}
                            size="small"
                            fullWidth
                        />
                    </div>
                </div>
                <div style={{ width: "100%", height: "5px" }} >
                    {loader && (<LinearProgress />)}
                </div>
                <div className={classes.bottomCont} >
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={onContactAddCall}
                        disabled={selectedContacts && selectedContacts.length > 0 ? false : true}
                    >
                        Save
                    </Button>
                </div>
            </div>}
        />
    );
};

export default ContactCreateDrawer;