import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Avatar, IconButton } from '@material-ui/core';
import emptyIcon from "../../../Assets/FileIcon/emptyfolder.png"
import EntitySearch from "./EntitySearch.js"
import { getProfileForEntitySearch, createAndUpdateFinalcialRelation } from "./api.call";
import NormalDrawer from './NormalDrawer.js';
import CancelIcon from '@material-ui/icons/Cancel';
import "./common.css"

const useStyles = makeStyles((theme) => ({
    mainDialogCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "& .MuiButton-label": {
            textTransform: "capitalize",
            fontSize: "14px"
        }
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 55px)",
        overflow: "hidden"
    },
    bottomAct: {
        width: "100%",
        height: "50px",
        padding: "0px 7px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        borderTop: "1px solid #ececec"
    },
    searchCont: {
        height: "70px",
        padding: "0px 10px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    selectedCont: {
        width: "100%",
        height: "calc(100% - 70px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "0px 10px 0px"
    },
    emptySty: {
        width: "100%",
        height: "100%",
        padding: "20px 0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& img": {
            width: "30%",
            height: "auto"
        },
        "& p": {
            fontSize: "12px",
            fontWeight: "300",
            textAlign: "center",
        }
    },
    cardShowFlex: {
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    detailsCont: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        flexDirection: "column",
        "& h3": {
            fontSize: "14px",
            fontWeight: "500",
        },
        "& p": {
            fontSize: "12px",
            fontWeight: "200"
        }
    },
    roleChip: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        fontSize: "10px",
        fontWeight: "400",
        padding: "1px 5px",
        borderRadius: "7px",
        marginRight: "5px"
    },
}));

//required************
//1.entity = current entity, like "Project", "User" or "Organization"***(require)***
//2.curEntityId = id of that entity(_id)***(require)***
//3.onSave = function will return selected item(if multiple is true then it will return array of profile and if if not then a single profile)***(require)****
//4.open = to open and close drawer
//5.setOpen = to open and close drawer
//6.title = title which will show on the top of the drawer

//optional
//1.searchParameter = through which entityes and roles you want to search. follow structher of "defaultSearchParameter",
//2.givenProfileIdArr = which profiles contact you want to see in search reasult
//3.givenNotAllowedProfileId = which profiles you don't want to see in the search reasult
//4.noFilter = if you don't want to show filters to the users, just make it "ture"
//5.multiple = multiple select item
//6.onClickOptionHideOptions = if you make it ture then when you click any option on dropdown, all the options will hide after that click
//7.financialRelationType = financial relation type
//8.selectedProfile = all selected Profiles, it can be a single object or array of object

export default function SearchEntityDialog({
    searchParameter, givenProfileIdArr = [], givenNotAllowedProfileId = [],
    noFilter = false, entity, curEntityId, multiple = false, onSave, open, setOpen,
    onClickOptionHideOptions = true, financialRelationType = "Customer", title,
    selectedProfile, noPalCreate = false, notCreateFinancialRelation = false, allowOutSider=false
}) {
    const classes = useStyles();
    const theme = useTheme();

    const { user } = useSelector((state) => state.auth);

    const [selectedEntities, setSelectedEntities] = useState([])
    const [profileIdArr, setProfileIdArr] = useState([])
    const [notAllowedProfileId, setNotAllowedProfileId] = useState([])
    const [mainProfile, setMainProfile] = useState(null)
    const [mainProfileWitParent, setMainProfileWithParent] = useState(null)
    const [loader, setLoader] = useState(false)
    const [changeState, setChangeState] = useState(false)

    useEffect(() => {
        if (selectedProfile) {
            if (Array.isArray(selectedProfile)) {
                setSelectedEntities([...selectedProfile])
            } else {
                setSelectedEntities([selectedProfile])
            }
        }
    }, [selectedProfile])

    useEffect(() => {
        if (curEntityId && entity) {
            getProfileForEntitySearch({
                entityId: curEntityId,
                type: entity
            })
                .then((data) => {
                    const profiles = data?.profiles || []
                    const currentProfile = data?.currentProfile
                    const currentProfileWithParent = data?.currentProfileWithParent
                    let locProfileIdArr = new Set([...givenProfileIdArr, ...profiles])
                    let locNotAllowedProfileId = new Set([...givenNotAllowedProfileId, currentProfile])
                    setMainProfile(currentProfile)
                    setProfileIdArr([...locProfileIdArr])
                    setNotAllowedProfileId([...locNotAllowedProfileId])
                    setMainProfileWithParent(currentProfileWithParent)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [curEntityId, entity])

    const findOrAddRel = async () => {
        if (notCreateFinancialRelation) {
            if (multiple) {
                onSave(selectedEntities)
            } else {
                onSave(selectedEntities[0])
            }
            setOpen(false)
        } else {
            setLoader(true)
            const relationProfileIds = selectedEntities.map((entityProfile) => entityProfile._id)
            const notOnPlatformProfileIds = selectedEntities.map((entityProfile) => {
                if (entityProfile.parentModelName === "Pal") {
                    return entityProfile?._id
                }
            })
            const relObj = {
                mainProfile: mainProfile,
                relationProfileIds: relationProfileIds,
                notOnPlatformProfileIds: notOnPlatformProfileIds,
                type: financialRelationType ? financialRelationType : "Customer",
                parent: curEntityId,
                parentModelName: entity,
                addedBy: user?.profile,
                user: user?._id,
            };
            await createAndUpdateFinalcialRelation(relObj)
                .then((data) => {
                    setLoader(false)
                    if (multiple) {
                        onSave(selectedEntities)
                    } else {
                        onSave(selectedEntities[0])
                    }
                    setOpen(false)
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    };

    const selectEntityFunction = (value) => {
        setChangeState(!changeState)
        if (multiple) {
            const profileIds = selectedEntities.map((entityProfile) => entityProfile?._id)
            if (profileIds.includes(value?._id)) {
                const filteredEntities = selectedEntities.filter((entityProfile) => entityProfile?._id != value?._id)
                setSelectedEntities([...filteredEntities])
            } else {
                setSelectedEntities([...selectedEntities, value])
            }
        } else {
            if (selectedEntities && selectedEntities.length > 0 && value?._id === selectedEntities[0]?._id) {
                setSelectedEntities([])
            } else {
                setSelectedEntities([value])
            }
        }
        setChangeState(!changeState)
    }


    return (
        <NormalDrawer
            openDrawer={open}
            setOpenDrawer={setOpen}
            anchor={"right"}
            title={title}
            width={"40vw"}
            content={
                <div className={classes.mainDialogCont} >
                    <div className={classes.bodyCont} >
                        <div className={classes.searchCont} >
                            <EntitySearch
                                searchParameter={searchParameter}
                                profileIdArr={profileIdArr}
                                noPalCreate={noPalCreate}
                                notAllowedProfileId={notAllowedProfileId}
                                noFilter={noFilter}
                                selectedEntities={selectedEntities}
                                selectEntityFunction={selectEntityFunction}
                                onClickOptionHideOptions={onClickOptionHideOptions}
                                financialRelationType={financialRelationType}
                                entity={entity}
                                curEntityId={curEntityId}
                                mainProfileWitParent={mainProfileWitParent}
                                allowOutSider={allowOutSider}
                            />
                        </div>
                        <div className={classes.selectedCont} >
                            {selectedEntities && selectedEntities.length > 0 ? (<>
                                {selectedEntities.map((selectedEntity) => (
                                    <div className="simpleUserCardMainContDrawer" >
                                        <div className={classes.cardShowFlex} >
                                            {selectedEntity?.parentModelName === "OutSider" ? (
                                                <Avatar
                                                    style={{
                                                        width: "30px",
                                                        height: "30px",
                                                        marginRight: "5px"
                                                    }}
                                                >
                                                    {selectedEntity?.parent?.displayPicture?.thumbUrl}
                                                </Avatar>
                                            ) : (
                                                <Avatar
                                                    src={selectedEntity?.displayPicture?.thumbUrl ? selectedEntity?.displayPicture?.thumbUrl : selectedEntity?.displayPicture?.url}
                                                    alt={selectedEntity?.parent?.displayName}
                                                    style={{
                                                        width: "30px",
                                                        height: "30px",
                                                        marginRight: "5px"
                                                    }}
                                                />
                                            )}
                                            <div className={classes.detailsCont} >
                                                <h3>{selectedEntity?.parent?.displayName}</h3>
                                                <p>{selectedEntity?.parent?.username}</p>
                                            </div>
                                        </div>
                                        <span className="simpleUserCardTypeDrawer" >
                                            {`${selectedEntity?.parentModelName} ${selectedEntity?.parentModelName === "Pal" ? selectedEntity?.parent?.type : ""}`}
                                        </span>
                                        <span className="closeBtn" >
                                            <IconButton
                                                onClick={() => {
                                                    selectEntityFunction(selectedEntity)
                                                }}
                                            >
                                                <CancelIcon />
                                            </IconButton>
                                        </span>
                                        {selectedEntity?.contacts && selectedEntity?.contacts.length > 0 && (
                                            <div className="simpleUserCardRolesContDrawer" >
                                                {selectedEntity.contacts.map((contact) => (
                                                    <div className={classes.roleChip} >{contact?.type}</div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </>) : (
                                <div className={classes.emptySty} >
                                    <img src={emptyIcon} />
                                    <p>No party still selected!</p>
                                </div>
                            )}
                        </div>
                    </div>
                    <div style={{ width: "100%", height: "5px" }} >
                        {loader && (<LinearProgress />)}
                    </div>
                    <div className={classes.bottomAct} >
                        <Button
                            onClick={findOrAddRel}
                            variant="contained"
                            size="small"
                            color="primary"
                            disabled={selectedEntities && selectedEntities.length > 0 ? false : true}
                        >
                            Save & Close
                        </Button>
                    </div>
                </div>}
        />
    );
}