import {
  LocalizationProvider,
  DatePicker as MuiDatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";

const DatePicker = ({
  fullWidth = true,
  textfieldProps,
  format = "DD/MM/YYYY",
  ...props
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MuiDatePicker
        format={format}
        slotProps={{
          textField: {
            sx: {
              borderRadius: "8px",
            },
            fullWidth: fullWidth,
            ...textfieldProps,
          },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};

export default DatePicker;
