import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Contacts from "../contacts";
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "80vh",
        display: "flex",
        padding: "20px",
        backgroundColor: "white",
        justifyContent: "space-between",
    },
    addNewContact: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "15px",
    },
    contactCardsCont: {
        width: "100%",
        maxHeight: "calc(100vh - 250px)",
        overflowY: "auto",
        padding: "10px 0px 10px",
        paddingRight: "0px",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
        [theme.breakpoints.down("xs")]: {
            paddingRight: "5px",
        },
    },
    cardSty: {
        width: "calc(33.3% - 20px)",
        textAlign: "center",
        borderRadius: "5px",
        padding: "10px 10px",
        marginTop: "30px",
        marginRight: "20px",
        position: "relative",
        zIndex: "11",
        [theme.breakpoints.down("md")]: {
            width: "calc(50% - 50px)",
            marginTop: "30px",
            marginRight: "50px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "calc(50% - 15px)",
            minWidth: "310px",
            marginTop: "30px",
            marginRight: "15px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            margin: "0px",
            marginTop: "30px",
        },
    },
    cardSkeleton: {
        width: "calc(33.3% - 20px)",
        height: "50px",
        borderRadius: "5px",
        marginBottom: "20px",
        marginRight: "20px",
        [theme.breakpoints.down("md")]: {
            width: "calc(50% - 50px)",
            marginBottom: "20px",
            marginRight: "50px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "calc(50% - 15px)",
            minWidth: "310px",
            marginBottom: "15px",
            marginRight: "15px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            margin: "0px",
            marginBottom: "20px",
        },
    },
    searchPaperCont: {
        borderRadius: "5px",
        display: "flex",
        alignItems: "center",
        position: "relative",
    }
}));


export default function ProjectContacts({ givenIsMdSmall=null, profileId, userRole, wallet, parent, parentModelName, team }) {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    const { user } = useSelector((state) => state.auth);

    const [roles, setRoles] = useState([])
    const [openContactDrawer, setOpenContactDrawer] = useState(false)

    const isMdSmall = useMediaQuery(theme.breakpoints.down("md"));
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));

    useEffect(() => {
        if (userRole === "Owner") {
            setRoles(["Owner", "Admin", "Viewer", "Contractor", "Customer", "Employee", "Investor", "Lender", "Vendor", "Tenant"])
        } else if (userRole === "Admin") {
            setRoles(["Admin", "Viewer", "Contractor", "Customer", "Employee", "Investor", "Lender", "Vendor", "Tenant"])
        } else {
            setRoles([])
        }
    }, [userRole])

    console.log({ profileId, userRole, wallet, parent, parentModelName, team })


    return (
        <div className={classes.root}>
            <Contacts
                profileId={profileId}
                searchWidth={givenIsMdSmall ? "300px" : isExSmall ? "calc(100% - 80px)" : isSmall ? "75%" : isMdSmall ? "300px" : "400px"}
                roles={roles}
                isAddNewBtn={true}
                wallet={wallet}
                parent={parent}
                parentModelName={parentModelName}
                searchAndAddContStyle={classes.addNewContact}
                allCardsContStyle={classes.contactCardsCont}
                singleCardStyle={classes.cardSty}
                cardSkeletonStyle={classes.cardSkeleton}
                searchPaperCont={classes.searchPaperCont}
                openContactDrawer={openContactDrawer}
                setOpenContactDrawer={setOpenContactDrawer}
                team={team}
                givenIsMdSmall={givenIsMdSmall}
            />
        </div>
    );
}