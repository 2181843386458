import Cookies from "universal-cookie";

const cookies = new Cookies(null, { path: "/", domain: ".reallist.ai" });

export const setAuthCookieData = ({ user, token }) => {
  console.log("setAuthCookie called ");
  try {
    cookies.set("reallistUser", JSON.stringify(user));
    cookies.set("reallistToken", token);
  } catch (error) {
    console.log(error, " inside setAuthToken");
  }
};

export const getAuthCookieData = (dispatch) => {
  let cookieDataRaw = cookies.get("reallistUser");
  console.log("cookieDataRaw", cookieDataRaw);
  let user;
  if (cookieDataRaw) {
    user = cookieDataRaw
  }

  if (user) {
    dispatch({
      type: "SET_AUTH_USER",
      user,
    });
  }

  const token = cookies.get("reallistToken");
  if (token) {
    localStorage.setItem("token", token);
  }
  return { user, token };
};
