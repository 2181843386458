import Api from '../../helpers/Api';

export const createNewShare = async (obj) => {
    try {
        const res = await Api.post('share/resource/access', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getProfilesByIdsArr = async (obj) => {
    try {
        const res = await Api.post('profile/getProfiles', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getResourceAccessByResource = async (obj) => {
    try {
        const res = await Api.post('get/share/resource/access/by-resource', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const updateResourceAccessByResource = async (obj) => {
    try {
        const res = await Api.post('update/share/resource/access/multiple', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getResourceAssigneds = async (obj) => {
    try {
        const res = await Api.post('get/exisiting/assigned/profiles', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const updateResourceAssigneds = async (obj) => {
    try {
        const res = await Api.post('update/resource/assigned/profiles', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};