import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Avatar, IconButton } from '@material-ui/core';
import emptyIcon from "../../Assets/FileIcon/emptyfolder.png"
import { getProfileForEntitySearch } from '../styled/CommonComponents/api.call';
import CancelIcon from '@material-ui/icons/Cancel';
import "../styled/CommonComponents/common.css"
import EntitySearch from '../styled/CommonComponents/EntitySearch';

const useStyles = makeStyles((theme) => ({
    searchCont: {
        height: "70px",
        padding: "0px 10px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    selectedCont: {
        width: "100%",
        height: "calc(100% - 70px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "0px 10px 0px"
    },
    emptySty: {
        width: "100%",
        height: "100%",
        padding: "20px 0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& img": {
            width: "30%",
            height: "auto"
        },
        "& p": {
            fontSize: "12px",
            fontWeight: "300",
            textAlign: "center",
        }
    },
    cardShowFlex: {
        width: "100%",
        display: "flex",
        alignItems: "center",
    },
    nameRoleCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    detailsCont: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        flexDirection: "column",
        "& h3": {
            fontSize: "14px",
            fontWeight: "500",
        },
        "& p": {
            fontSize: "12px",
            fontWeight: "200"
        }
    },
    roleChip: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        fontSize: "10px",
        fontWeight: "400",
        padding: "1px 5px",
        borderRadius: "7px",
        marginRight: "5px"
    },
}));

export default function AssignedEntitySelectView({
    entity, curEntityId, selectedEntities, setSelectedEntities, searchParameter
}) {
    const classes = useStyles();
    const theme = useTheme();

    const [profileIdArr, setProfileIdArr] = useState([])
    const [mainProfileWithParent, setMainProfileWithParent] = useState(null)

    useEffect(() => {
        if (curEntityId && entity) {
            getProfileForEntitySearch({
                entityId: curEntityId,
                type: entity
            })
                .then((data) => {
                    const profiles = data?.profiles || []
                    const currentProfileWithParent = data?.currentProfileWithParent
                    let locProfileIdArr = new Set([...profiles])
                    setProfileIdArr([...locProfileIdArr])
                    setMainProfileWithParent(currentProfileWithParent)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [curEntityId, entity])

    const selectEntityFunction = (value) => {
        const profileIds = selectedEntities.map((entityProfile) => entityProfile?._id)
        if (profileIds.includes(value?._id)) {
            const filteredEntities = selectedEntities.filter((entityProfile) => entityProfile?._id != value?._id)
            setSelectedEntities([...filteredEntities])
        } else {
            setSelectedEntities([...selectedEntities, value])
        }
    }

    return (
        <>
            <div className={classes.searchCont} >
                <EntitySearch
                    searchParameter={searchParameter}
                    profileIdArr={profileIdArr}
                    noPalCreate={true}
                    notAllowedProfileId={[]}
                    noFilter={false}
                    selectedEntities={selectedEntities}
                    selectEntityFunction={selectEntityFunction}
                    onClickOptionHideOptions={true}
                    financialRelationType={"Employee"}
                    entity={entity}
                    curEntityId={curEntityId}
                    mainProfileWitParent={mainProfileWithParent}
                    allowOutSider={false}
                />
            </div>
            <div className={classes.selectedCont} >
                {selectedEntities && selectedEntities.length > 0 ? (<>
                    {selectedEntities.map((selectedEntity, index) => (
                        <div className="simpleUserCardMainContDrawer" >
                            <div className={classes.cardShowFlex} >
                                {selectedEntity?.parentModelName === "OutSider" ? (
                                    <Avatar
                                        style={{
                                            width: "30px",
                                            height: "30px",
                                            marginRight: "5px"
                                        }}
                                    >
                                        {selectedEntity?.parent?.displayPicture?.thumbUrl}
                                    </Avatar>
                                ) : (
                                    <Avatar
                                        src={selectedEntity?.parent?.displayPicture?.thumbUrl ? selectedEntity?.parent?.displayPicture?.thumbUrl : selectedEntity?.parent?.displayPicture?.url}
                                        alt={selectedEntity?.parent?.displayName}
                                        style={{
                                            width: "30px",
                                            height: "30px",
                                            marginRight: "5px"
                                        }}
                                    />
                                )}
                                <div className={classes.detailsCont} >
                                    <h3>{selectedEntity?.parent?.displayName}</h3>
                                    <p>{selectedEntity?.parent?.username}</p>
                                </div>
                            </div>
                            <span className="simpleUserCardTypeDrawer" >
                                {`${selectedEntity?.parentModelName} ${selectedEntity?.parentModelName === "Pal" ? selectedEntity?.parent?.type : ""}`}
                            </span>
                            <span className="closeBtn" >
                                <IconButton
                                    onClick={() => {
                                        selectEntityFunction(selectedEntity)
                                    }}
                                >
                                    <CancelIcon />
                                </IconButton>
                            </span>
                            {selectedEntity?.contacts && selectedEntity?.contacts.length > 0 && (
                                <div className="simpleUserCardRolesContDrawer" >
                                    {selectedEntity.contacts.map((contact) => (
                                        <div className={classes.roleChip} >{contact?.type}</div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </>) : (
                    <div className={classes.emptySty} >
                        <img src={emptyIcon} />
                        <p>No User still selected!</p>
                    </div>
                )}
            </div>
        </>
    );
}