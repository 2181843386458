import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";
import mainLogo from "../../Assets/RealList-01.svg";
import collaborationImage from "../../Assets/ContractFloAssets/SVG/collaborationoldWithoutBG.svg";
import "./auth.style.css";
import { useDispatch } from "react-redux";
import iconsGoogle from "../../Assets/iconsGoogle.svg";
import { Link } from "react-router-dom";
import Collapse from '@material-ui/core/Collapse';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { useLocation } from "react-router-dom";
import * as yup from "yup";
import {
  handleGoogleLogin, handleEmailPasswordLogin,
  handleEmailPasswordSignup
} from "./auth.utils";
import ClearIcon from '@material-ui/icons/Clear';

const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
const signupSchema = yup.object().shape({
  fname: yup.string().min(2, 'First name should be 2 chars minimum.').max(50, 'First name should have less then 50 chars.').required("First name should be required"),
  lname: yup.string().max(50, 'Last name should have less then 50 chars.'),
  email: yup.string().email("Not a proper email").required("Mail id should be required"),
  password: yup.string().min(8, 'Too short - should be 8 chars minimum.').matches(passwordRules, { message: "Please create a stronger password" }).required("Pssword should be required"),
});

const signinSchema = yup.object().shape({
  email: yup.string().email("Not a proper email").required("Mail id should be required"),
  password: yup.string().min(8, 'Too short - should be 8 chars minimum.').matches(passwordRules, { message: "Please create a stronger password" }).required("Pssword should be required"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    overflowX: "hidden",
    overflowY: "auto",
    backgroundColor: "#F8F8F6",
    padding: "40px 180px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      padding: "50px",
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      padding: "30px 0px",
    }
  },
  leftSide: {
    width: "55%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  rigthSide: {
    width: "435px",
    height: "100%",
    minHeight: "650px",
    overflow: "hidden",
    backgroundColor: "white",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      width: "95%",
    }
  },
  logoTitleCont: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    "& img": {
      height: "42px"
    },
    [theme.breakpoints.down("sm")]: {
      "& img": {
        height: "37px",
      }
    }
  },
  textCont: {
    "& h3": {
      fontSize: "36px",
      color: theme.palette.primary.main,
      fontWeight: "800"
    },
    "& h4": {
      fontSize: "32px",
      fontWeight: "800"
    }
  },
  imgageSty: {
    width: "80%",
    height: "auto",
    marginTop: "30px"
  },
  logoText: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    "& img": {
      width: "50px",
      height: "auto"
    },
    "& h2": {
      fontSize: "26px",
      color: theme.palette.primary.main,
      fontWeight: "700",
      marginLeft: "5px"
    }
  },
  nameCont: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "15px"
  },
  loginCont: {
    width: "100%",
    marginTop: "20px"
  },
  signupCont: {
    width: "100%",
    marginTop: "40px"
  },
  topAllCont: {
    width: "100%",
    padding: "20px 30px 0px"
  },
  btnTC: {
    width: "100%",
    textAlign: "center",
    padding: "5px 15px",
    fontSize: "13px",
    backgroundColor: "#d3ecff"
  },
  linkStyle: {
    textDecoration: "none",
    color: "blue",
  },
  btnSty: {
    width: "100%",
    marginTop: "40px",
    padding: "8px 0px",
    "& .MuiButton-label": {
      textTransform: "capitalize"
    }
  },
  icon: {
    width: "20px",
    marginRight: "10px",
  },
  googleBtnSty: {
    border: "1px solid gray",
    width: "100%",
    marginTop: "20px",
    "& .MuiButton-label": {
      textTransform: "capitalize"
    }
  },
  userNotExist: {
    width: "100%",
    height: "40px",
    marginBottom: "15px",
  },
  userNotCont: {
    width: "100%",
    height: "100%",
    borderRadius: "5px",
    padding: "0px 12px",
    backgroundColor: "rgba(255,0,0,0.2)",
    color: "#3c3232",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& p": {
      fontSize: "14px"
    }
  }
}));

export default function AuthPage() {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const urlParams = new URLSearchParams(location.search);
  const type = urlParams.get('type');
  const getMailId = urlParams.get('mailId');

  const [boolVal, setBoolVal] = useState(false)
  const [sIshowPassword, setSIshowPassword] = useState(false)
  const [sUshowPassword, setSUIshowPassword] = useState(false)
  const [loginMail, setLoginMail] = useState("")
  const [loginPassword, setLoginPassword] = useState("")
  const [signupFName, setSignupFName] = useState("")
  const [signupLName, setSignupLName] = useState("")
  const [signupMail, setSignupMail] = useState("")
  const [signupPassword, setSignupPassword] = useState("")
  const [loginErrorObj, setLoginErrorObj] = useState({})
  const [signupErrorObj, setSignupErrorObj] = useState({})
  const [noUserExist, setNoUserExist] = useState(false)

  useEffect(() => {
    if (type === "signup") {
      setBoolVal(true)
      if (getMailId) {
        setSignupMail(getMailId)
      }
    } else {
      setBoolVal(false)
    }
  }, [type])

  useEffect(() => {
    setLoginErrorObj({});
  }, [loginMail, loginPassword]);

  useEffect(() => {
    setSignupErrorObj({});
  }, [signupFName, signupLName, signupMail, signupPassword]);

  const onSignupSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      fname: signupFName,
      lname: signupLName,
      email: signupMail,
      password: signupPassword
    };

    await signupSchema
      .validate(formData, { abortEarly: false })
      .then(async (data) => {
        const displayName = `${signupFName} ${signupLName}`
        await handleEmailPasswordSignup(dispatch, signupMail, signupPassword, displayName, true,signupFName,signupLName);
      })
      .catch((err) => {
        let errorArr = err?.inner;
        let obj = {};
        errorArr.map((err) => {
          obj[err?.path] = err?.message;
        });
        setSignupErrorObj(obj);
      });
  };

  const userNotFoundErr = () => {
    setNoUserExist(true)
  }

  const onLoginSubmit = async (e) => {
    e.preventDefault();
    const formData = {
      email: loginMail,
      password: loginPassword
    };

    await signinSchema
      .validate(formData, { abortEarly: false })
      .then(async (data) => {

        await handleEmailPasswordLogin(dispatch, loginMail, loginPassword, userNotFoundErr);

      })
      .catch((err) => {
        let errorArr = err?.inner;
        let obj = {};
        errorArr.map((err) => {
          obj[err?.path] = err?.message;
        });
        setLoginErrorObj(obj);
      });
  };

  return (
    <div className={classes.root} >
      <div className={classes.leftSide} >
        <div className={classes.textCont} >
          <h4>Real Estate Network OS</h4>
          <h3>Collaborative Tools, Powerful Connections, & Endless Opportunities</h3>
        </div>
        <img src={collaborationImage} className={classes.imgageSty} />
      </div>

      <div className={classes.rigthSide} >
        <div className={classes.topAllCont} >
          <div className={classes.logoTitleCont} >
            <img src={mainLogo} alt="logo" />
          </div>

          <div class="switch-button">
            {boolVal ? (
              <input
                class="switch-button-checkbox"
                type="checkbox"
                value={boolVal}
                onChange={(e) => { setBoolVal(e.target.checked) }}
                checked
              ></input>
            ) : (
              <input
                class="switch-button-checkbox"
                type="checkbox"
                value={boolVal}
                onChange={(e) => { setBoolVal(e.target.checked) }}
              ></input>
            )}
            <label class="switch-button-label" for="">
              <span class="switch-button-label-span">Sign In</span>
            </label>
          </div>

          <Collapse in={!boolVal} collapsedSize={0}>
            <div className={classes.loginCont} >
              <div className={classes.userNotExist} >
                {noUserExist && (
                  <div className={classes.userNotCont} >
                    <p>Incorrect mail id or password</p>
                    <ClearIcon
                      style={{
                        fontSize: "20px",
                        cursor: "pointer"
                      }}
                      onClick={() => { setNoUserExist(false) }}
                    />
                  </div>
                )}
              </div>

              <form onSubmit={onLoginSubmit} >
                <TextField
                  label={<>Email<span style={{ color: "red" }} >*</span></>}
                  style={{ width: "100%" }}
                  value={loginMail}
                  onChange={(e) => { setLoginMail(e.target.value); }}
                />
                <p style={{ color: "#EE1D52", fontSize: "12px", marginBottom: "15px" }}>
                  {loginErrorObj?.email && (<>{loginErrorObj?.email}</>)}
                </p>
                <TextField
                  type={sIshowPassword ? "text" : "password"}
                  label={<>Password<span style={{ color: "red" }} >*</span></>}
                  style={{ width: "100%" }}
                  InputProps={{
                    endAdornment: (<InputAdornment position="end">
                      {sIshowPassword ? (
                        <VisibilityOffIcon
                          style={{ color: "gray", cursor: "pointer" }}
                          onClick={() => { setSIshowPassword(!sIshowPassword) }}
                        />
                      ) : (
                        <VisibilityIcon
                          style={{ color: "gray", cursor: "pointer" }}
                          onClick={() => { setSIshowPassword(!sIshowPassword) }}
                        />
                      )}
                    </InputAdornment>)
                  }}
                  value={loginPassword}
                  onChange={(e) => { setLoginPassword(e.target.value); }}
                />
                {loginErrorObj?.password ? (
                  <p style={{ color: "#EE1D52", fontSize: "12px", marginBottom: "15px" }}>
                    {loginErrorObj?.password}
                  </p>
                ) : (<p style={{ color: "gray", fontSize: "12px", marginBottom: "15px", marginTop: "6px" }} >
                  *Must contain at least one uppercase, one lowercase, one numerical character and one special character. Minimum 8 characters.
                </p>)}

                <Button
                  variant="contained"
                  color="primary"
                  className={classes.btnSty}
                  type="submit"
                >
                  Sign In
                </Button>
                <Button
                  className={classes.googleBtnSty}
                  onClick={(el) => { handleGoogleLogin(dispatch) }}
                >
                  <img className={classes.icon} src={iconsGoogle} alt="google" />{" "}
                  Sign In using Google
                </Button>
              </form>
            </div>
          </Collapse>

          <Collapse in={boolVal} collapsedSize={0}>
            <form onSubmit={onSignupSubmit} className={classes.signupCont} >
              <div className={classes.nameCont} >
                <div style={{ width: "47%" }} >
                  <TextField
                    label={<>First name<span style={{ color: "red" }} >*</span></>}
                    style={{ width: "100%" }}
                    value={signupFName}
                    onChange={(e) => { setSignupFName(e.target.value); }}
                  />
                  {signupErrorObj?.fname && (
                    <p style={{ color: "#EE1D52", fontSize: "12px" }}>
                      {signupErrorObj?.fname}
                    </p>
                  )}
                </div>
                <div style={{ width: "47%" }} >
                  <TextField
                    label={<>Last name<span style={{ color: "red" }} >*</span></>}
                    style={{ width: "100%" }}
                    value={signupLName}
                    onChange={(e) => { setSignupLName(e.target.value); }}
                  />
                  {signupErrorObj?.lname && (
                    <p style={{ color: "#EE1D52", fontSize: "12px" }}>
                      {signupErrorObj?.lname}
                    </p>
                  )}
                </div>
              </div>
              <TextField
                label={<>Email<span style={{ color: "red" }} >*</span></>}
                style={{ width: "100%" }}
                value={signupMail}
                onChange={(e) => { setSignupMail(e.target.value); }}
              />
              <p style={{ color: "#EE1D52", fontSize: "12px", marginBottom: "15px" }}>
                {signupErrorObj?.email && (<>{signupErrorObj?.email}</>)}
              </p>
              <TextField
                label={<>Password<span style={{ color: "red" }} >*</span></>}
                style={{ width: "100%" }}
                type={sUshowPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (<InputAdornment position="end">
                    {sUshowPassword ? (
                      <VisibilityOffIcon
                        style={{ color: "gray", cursor: "pointer" }}
                        onClick={() => { setSUIshowPassword(!sUshowPassword) }}
                      />
                    ) : (
                      <VisibilityIcon
                        style={{ color: "gray", cursor: "pointer" }}
                        onClick={() => { setSUIshowPassword(!sUshowPassword) }}
                      />
                    )}
                  </InputAdornment>)
                }}
                value={signupPassword}
                onChange={(e) => { setSignupPassword(e.target.value); }}
              />
              {signupErrorObj?.password ? (
                <p style={{ color: "#EE1D52", fontSize: "12px", marginBottom: "15px" }}>
                  {signupErrorObj?.password}
                </p>
              ) : (<p style={{ color: "gray", fontSize: "12px", marginBottom: "15px", marginTop: "6px" }} >
                *Must contain at least one uppercase, one lowercase, one numerical character and one special character. Minimum 8 characters.
              </p>)}

              <Button
                variant="contained"
                color="primary"
                type="submit"
                className={classes.btnSty}
              >
                Sign Up
              </Button>
              <Button
                className={classes.googleBtnSty}
                onClick={(el) => { handleGoogleLogin(dispatch) }}
              >
                <img className={classes.icon} src={iconsGoogle} alt="google" />{" "}
                Sign Up using Google
              </Button>
            </form>
          </Collapse>
        </div>

        <div className={classes.btnTC} >
          By signing up, you agree to the{" "}
          <Link className={classes.linkStyle} to="/terms">
            Terms of Service
          </Link>{" "}
          and{" "}
          <Link className={classes.linkStyle} to="/terms">
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  );
}