import Api from '../../helpers/Api';

export const getAccessControlOptions = async (obj) => {
    try {
        const res = await Api.post('get/access-control/options', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const createAccessRole = async (obj) => {
    try {
        const res = await Api.post('create/access-role', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const updateAccessRole = async (obj) => {
    try {
        const res = await Api.post('update/access-role', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getAllAccessRolesByTeam = async (obj) => {
    try {
        const res = await Api.post('get/access-role/by-team', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const addParticipants = async (obj) => {
    try {
        const res = await Api.post('create/multiple/participants', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getParticipants = async (obj) => {
    try {
        const res = await Api.post('get/participants/pagination', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getParticipantsCount = async (obj) => {
    try {
        const res = await Api.post('get/participants/count', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getParticipantsByUser = async (obj) => {
    try {
        const res = await Api.post('get/participants/by-user', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getParticipantsByUserAndTeam = async (obj) => {
    try {
        const res = await Api.post('get/participants/by-user-and-team', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const updateParticipant = async (obj) => {
    try {
        const res = await Api.post('update/participant', obj);
        const result = res?.data || {};
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const getParticipantProfileIds = async (obj) => {
    try {
        const res = await Api.post('get/participants/profileIds', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};

export const invitationSendAgain = async (obj) => {
    try {
        const res = await Api.post('create/participants/send/invitation', obj);
        const result = res?.data || [];
        return result;
    }
    catch (error) {
        console.log(error);
        return null;
    }
};