import React, { useState, useEffect, useCallback, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useDebounce } from "react-use";
import { userAdvanceSearch } from "./api.call";
import SimpleUserCard from "./SimpleUserCard";
import SimpleUserCardSkeleton from "../skeletons/SimpleUserCardSkeleton";
import emptyfolder from "../../../Assets/FileIcon/emptyfolder.png";
import EmailIcon from "@material-ui/icons/Email";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import * as Yup from "yup";
// import "yup-phone";

const emailSchema = Yup.object().shape({
  email: Yup.string().required().email(),
});

// const phoneSchema = Yup.string().phone("IN", true).required();

const useStyles = makeStyles((theme) => ({
  feedBodyXCont: {
    width: "100%",
    overflow: "auto",
  },
  progressBox: {
    width: "100%",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  emptySty: {
    width: "100%",
    padding: "20px 0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      width: "35%",
      height: "auto",
    },
    "& p": {
      fontSize: "12px",
      fontWeight: "300",
      textAlign: "center",
    },
  },
}));

export default function EntitySearchPagination({
  searchStr = "",
  profileIdArr = [],
  notAllowedProfileId = [],
  selectEntityFunction,
  selectedEntities,
  onClickOptionHideOptions,
  setShowBool,
  entityScopObj,
  entityRoleObj,
  newPalItem,
  allowOutSider,
}) {
  const classes = useStyles();
  const postFromBackend = 30;
  const observer = useRef();

  const { user } = useSelector((state) => state.auth);

  const [profileArr, setProfileArr] = useState([]);
  const [hasMoreItems, sethasMoreItems] = useState(true);
  const [currPage, setCurrPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [changePageState, setChangePageState] = useState(true);
  const [selectedEntitiesProfileIds, setSelectedEntitiesProfileIds] = useState(
    []
  );

  useEffect(() => {
    const arr = selectedEntities.map((profile) => profile?._id);
    setSelectedEntitiesProfileIds([...arr]);
  }, [selectedEntities]);

  const findPostsForScroll = async () => {
    setLoading(true);
    await userAdvanceSearch({
      entityScopObj: entityScopObj,
      entityRelationType: entityRoleObj,
      name: searchStr,
      profileIdArr: profileIdArr,
      notAllowedProfileId: notAllowedProfileId,
      selectedProfileIds: selectedEntitiesProfileIds,
      limit: postFromBackend,
      page: currPage,
    })
      .then(async (data) => {
        if (currPage === 0) {
          if (data && data.length > 0) {
            setProfileArr(data);
          } else {
            if (allowOutSider) {
              const formData = {
                email: searchStr,
              };
              await emailSchema
                .validate(formData, { abortEarly: false })
                .then(async (data) => {
                  setProfileArr([
                    {
                      _id: searchStr,
                      displayPicture: {
                        thumbUrl: <EmailIcon style={{ fontSize: "23px" }} />,
                      },
                      parent: {
                        displayName: searchStr.split("@")[0],
                        username: searchStr,
                        through: "email",
                        email: searchStr,
                      },
                      parentModelName: "OutSider",
                    },
                  ]);
                })
                .catch((err) => {
                  // if (phoneSchema.isValidSync(searchStr)) {
                  //   setProfileArr([
                  //     {
                  //       _id: `+91  ${searchStr.substring(
                  //         searchStr.length - 10
                  //       )}`,
                  //       displayPicture: {
                  //         thumbUrl: (
                  //           <PhoneAndroidIcon style={{ fontSize: "23px" }} />
                  //         ),
                  //       },
                  //       parent: {
                  //         displayName: searchStr.substring(
                  //           searchStr.length - 10
                  //         ),
                  //         username: `+91  ${searchStr.substring(
                  //           searchStr.length - 10
                  //         )}`,
                  //         through: "phone",
                  //         phone: searchStr,
                  //       },
                  //       parentModelName: "OutSider",
                  //     },
                  //   ]);
                  // } else {
                  //   console.log(err);
                  //   setProfileArr([]);
                  // }
                });
            } else {
              setProfileArr([]);
            }
          }
        } else {
          setProfileArr((prev) => [...prev, ...data]);
        }
        if (data.length < postFromBackend) {
          sethasMoreItems(false);
        } else {
          sethasMoreItems(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMoreItems) {
          setCurrPage((prev) => {
            return prev + 1;
          });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMoreItems]
  );

  useDebounce(() => {
    if (currPage === 0) {
      setChangePageState(!changePageState);
    } else {
      setCurrPage(0);
    }

  }, 500, [entityScopObj, entityRoleObj, profileIdArr, searchStr, newPalItem?._id
  ]);

  useDebounce(
    () => {
      findPostsForScroll();
    },
    1000,
    [currPage, changePageState]
  );

  return (
    <>
      {profileArr && profileArr.length > 0 ? (
        <>
          {currPage === 0 && loading ? (
            <>
              <SimpleUserCardSkeleton />
              <SimpleUserCardSkeleton />
              <SimpleUserCardSkeleton />
              <SimpleUserCardSkeleton />
              <SimpleUserCardSkeleton />
            </>
          ) : (
            <>
              {profileArr.map((profile, idx) => {
                let lastItem = profileArr.length - 5;
                return (
                  profile?._id &&
                  (idx === lastItem ? (
                    <div
                      style={{ width: "100%" }}
                      key={idx}
                      ref={lastElementRef}
                    >
                      <SimpleUserCard
                        profile={profile}
                        selectEntityFunction={selectEntityFunction}
                        selectedEntitiesProfileIds={selectedEntitiesProfileIds}
                        onClickOptionHideOptions={onClickOptionHideOptions}
                        setShowBool={setShowBool}
                      />
                    </div>
                  ) : (
                    <div style={{ width: "100%" }} key={idx}>
                      <SimpleUserCard
                        profile={profile}
                        selectEntityFunction={selectEntityFunction}
                        selectedEntitiesProfileIds={selectedEntitiesProfileIds}
                        onClickOptionHideOptions={onClickOptionHideOptions}
                        setShowBool={setShowBool}
                      />
                    </div>
                  ))
                );
              })}
            </>
          )}
        </>
      ) : (
        <>
          {loading ? (
            <>
              <SimpleUserCardSkeleton />
              <SimpleUserCardSkeleton />
              <SimpleUserCardSkeleton />
              <SimpleUserCardSkeleton />
              <SimpleUserCardSkeleton />
            </>
          ) : (
            <div className={classes.emptySty}>
              <img src={emptyfolder} />
              <p>No result is available!</p>
            </div>
          )}
        </>
      )}

      {profileArr && profileArr.length > 0 && hasMoreItems ? (
        <div className={classes.progressBox}>
          {loading && <p>Loading...</p>}
        </div>
      ) : null}
    </>
  );
}
