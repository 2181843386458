import React, { useEffect, useState } from "react";
import DuoButtonGroup from "../../../styled/generic/DuoButtonGroup";
import DrawerContainer from "../../../styled/generic/DrawerContainer";
import SpaceBetween from "../../../styled/generic/SpaceBetween";
import FormBox from "../../../styled/generic/FormBox";
import TextField from "../../../styled/generic/TextField";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { Box, Slider } from "@mui/material";
import DocumentUploader from "../../../styled/generic/DocumentUploader";
import DocViewer from "../../../styled/generic/DocViewer";
import Api from "../../../../helpers/Api";

const TaskProgressUpdateDrawer = ({
  open,
  setOpen,
  setTasks,
  setTask,
  taskToUpdate,
}) => {
  const history = useHistory();
  const files = useSelector((state) => state.file);
  const { fileDictionary, createdFileIds } = files;
  const dispatch = useDispatch();
  const [workDoneToday, setWorkDoneToday] = useState(0);
  const [comment, setComment] = useState("");
  const [filesArr, setFilesArr] = useState([]);
  const [fileIds, setFileIds] = useState([]);
  const [workLeft, setWorkLeft] = useState(0);

  const handleUpdateTask = (task) => {
    setWorkDoneToday(task?.progress || 0);
    let _workLeft = 100 - (task?.progress || 0);
    if (_workLeft < 0) _workLeft = 0;
    if (_workLeft > 100) _workLeft = 100;
    setWorkLeft(_workLeft);
    setComment(task?.comment || "");
  };

  useEffect(() => {
    if (taskToUpdate?._id) {
      handleUpdateTask(taskToUpdate);
    }
  }, [taskToUpdate]);

  const trackProgress = async () => {
    try {
      const { data } = await Api.post("/wbs/track-progress", {
        wbsId: taskToUpdate?._id,
        progress: workDoneToday,
        comment: comment,
        files: fileIds,
      });

      if (data) {
        if (setTasks) {
          setTasks((prev) => {
            let _prev = [...prev];
            let index = _prev.findIndex((m) => m._id === taskToUpdate?._id);
            _prev[index].progress = workDoneToday;
            _prev[index].comment = comment;
            _prev[index].files = filesArr;
            return _prev;
          });
        }
        if (setTask) {
          setTask(data?.wbs);
        }
        setOpen(false);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: true,
            message: "Task updated successfully",
          },
        });
      } else {
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "An unknown error occurred while updating progress",
          },
        });
      }
    } catch (err) {
      dispatch({
        type: "AddApiAlert",
        payload: {
          success: false,
          message: "An unknown error occurred while updating progress",
        },
      });
    }
  };

  return (
    <DrawerContainer open={open} setOpen={setOpen} title="Update Task">
      <SpaceBetween
        left={
          <FormBox label="Work Done Today">
            <TextField
              placeholder="Enter"
              type="number"
              value={workDoneToday}
              readOnly={true}
              fullWidth={true}
            />
          </FormBox>
        }
        right={
          <FormBox label="Work Left">
            <TextField
              placeholder="Enter"
              type="number"
              value={workLeft}
              readOnly={true}
              fullWidth={true}
            />
          </FormBox>
        }
      />
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Slider
          value={workDoneToday}
          onChange={(e, value) => {
            if (value < taskToUpdate?.data?.Progress) return;
            setWorkDoneToday(value);
            setWorkLeft(100 - value);
          }}
          min={0}
          max={100}
          valueLabelDisplay="auto"
        />
      </Box>
      <FormBox label="Comments" sx={{ mt: 2 }}>
        <TextField
          placeholder="Enter"
          type="text"
          fullWidth={true}
          multiline={true}
          rows={4}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
      </FormBox>

      <FormBox label="Attachments">
        <DocumentUploader
          documents={filesArr}
          onUpload={() => {
            setFileIds(createdFileIds);
            let data = [];
            for (let i = 0; i < createdFileIds?.length; i++) {
              data.push(fileDictionary[createdFileIds[i]]);
            }
            setFilesArr(data);
          }}
          onDelete={(dataId) => {
            setFileIds(fileIds.filter((m) => m !== dataId));
            setFilesArr(files.filter((m) => m._id !== dataId));
          }}
          parentId={taskToUpdate?._id}
          parentType="WBS"
        />
        <Box>
          {filesArr.length > 0 ? (
            <Box>
              <DocViewer
                data={filesArr}
                isDeletable={true}
                disableEditMetaData={true}
                disableShowMetaData={true}
                onMetadataChange={() => {}}
                deleteFunction={(delId) => {}}
              />
            </Box>
          ) : (
            <Box>No documents found</Box>
          )}
        </Box>
      </FormBox>
      <DuoButtonGroup
        primaryButtonText="Update"
        primaryButtonListener={() => trackProgress()}
        secondaryButtonText="Cancel"
        secondaryButtonListener={() => setOpen(false)}
      />
    </DrawerContainer>
  );
};

export default TaskProgressUpdateDrawer;
