import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, TextField, Avatar, IconButton } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import NormalDrawer from '../../styled/CommonComponents/NormalDrawer';
import Autocomplete from "@material-ui/lab/Autocomplete";
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from "@material-ui/icons/Add";
import TextFieldNumberFormated from "../../styled/CommonComponents/TextFieldNumberFormated";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import SearchEntityDialog from '../../styled/CommonComponents/SearchEntityDrawer';
import { ASSET_OPTIONS } from '../../../helpers/constants';
import { addAssetApiCall, addMultipleEvents, offeringBulkUpdate } from '../api.call';
import Select2Autocomplete from '../../styled/CommonComponents/Select2.Autocomplete';
import NotifyAlert from '../../styled/CommonComponents/NotifyAlert';
import * as yup from "yup";
import { mainRoles } from '../../../helpers/contactHelper';

const moveEventValidationSchema = yup.object().shape({
    eventType: yup.string().required("Event type is required"),
    move_dateOfMove: yup.date().required("Move date is required"),
    assets: yup.array().required("Assets is required"),
    AssignToProjectAndOrg: yup.array().required("Move location is required")
});

const assignEventValidationSchema = yup.object().shape({
    eventType: yup.string().required("Event type is required"),
    assign_dateOfMove: yup.date().required("Assign date is required"),
    assets: yup.array().required("Assets is required"),
    assignToIndividual: yup.array().required("Assign to individual is required")
});

const repairEventValidationSchema = yup.object().shape({
    eventType: yup.string().required("Event type is required"),
    assets: yup.array().required("Assets is required"),
    resolution: yup.string().required("Resolution is required"),
    issueProblemDescription: yup.string().required("Issue problem description is required"),
    repairCost: yup.number().min(1).required("Repairing cost is required")
});

const lostEventValidationSchema = yup.object().shape({
    eventType: yup.string().required("Event type is required"),
    assets: yup.array().required("Assets is required"),
    dateOfLoss: yup.date().required("Date of loss is required"),
});

const expiredEventValidationSchema = yup.object().shape({
    eventType: yup.string().required("Event type is required"),
    assets: yup.array().required("Assets is required"),
    expirationDate: yup.date().required("Expiration date is required"),
});

const soldEventValidationSchema = yup.object().shape({
    eventType: yup.string().required("Event type is required"),
    assets: yup.string().required("Assets is required"),
    saleDate: yup.date().required("Sale date is required"),
    sellingPrice: yup.number().min(1).required("Selling price is required")
});


const useStyles = makeStyles((theme) => ({
    mainDialogCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "& .MuiButton-label": {
            textTransform: "capitalize",
            fontSize: "14px"
        }
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 55px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "5px",
        paddingBottom: "15px"
    },
    bottomAct: {
        width: "100%",
        height: "50px",
        padding: "0px 7px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        borderTop: "1px solid #ececec"
    },
    inputBoxCont: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap"
    },
    depricationInputBoxCont: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between"
    },
    fullWInput: {
        width: "100%",
        padding: "10px",
        boxSizing: "border-box",
        "& p": {
            fontSize: "15px",
            fontWeight: "500",
            marginBottom: "1px"
        }
    },
    halfWInput: {
        width: "50%",
        padding: "10px",
        boxSizing: "border-box",
        "& p": {
            fontSize: "15px",
            fontWeight: "500",
            marginBottom: "1px"
        }
    },
    oneFourthWInput: {
        width: "24%",
        padding: "10px",
        boxSizing: "border-box",
        "& p": {
            fontSize: "15px",
            fontWeight: "500",
            marginBottom: "1px"
        }
    },
    halfWInputAutocomplete: {
        width: "50%",
        padding: "10px",
        boxSizing: "border-box",
        "& p": {
            fontSize: "15px",
            fontWeight: "500",
            marginBottom: "1px"
        },
    },
    addVendorSty: {
        width: "100%",
        color: "#aeacac",
        border: "1px solid #c3c2c2",
        borderRadius: "8px",
        cursor: "pointer",
        padding: "9px 10px"
    },
    selectedUserSty: {
        maxWidth: "100%",
        padding: "2px 5px",
        cursor: "pointer",
        border: "1px solid #c3c2c2",
        borderRadius: "8px",
        marginRight: "5px",
        marginBottom: "5px",
        display: "flex",
        alignItems: "center",
        "& .MuiSvgIcon-root": {
            fontSize: "20px",
            marginLeft: "5px"
        },
        "& .MuiIconButton-root": {
            padding: "3px"
        },
        "& .MuiAvatar-root": {
            width: "30px",
            height: "30px",
        },
        "& h3": {
            fontSize: "13px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "10px",
            fontWeight: "400",
            color: "gray"
        }
    },
    selectedUserOrgSty: {
        maxWidth: "100%",
        padding: "2px 5px",
        cursor: "pointer",
        border: "1px solid #c3c2c2",
        borderRadius: "8px",
        marginRight: "5px",
        marginBottom: "5px",
        display: "flex",
        alignItems: "center",
        "& .MuiSvgIcon-root": {
            fontSize: "20px",
            marginLeft: "5px"
        },
        "& .MuiIconButton-root": {
            padding: "3px"
        },
        "& .MuiAvatar-root": {
            width: "30px",
            height: "30px",
        },
        "& h3": {
            fontSize: "13px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "10px",
            fontWeight: "400",
            color: "gray"
        }
    },
    sectionLable: {
        padding: "0px 10px",
        "& div": {
            backgroundColor: "#EEF5FC",
            padding: "5px 0px",
            marginTop: "10px"
        }
    },
    symbolSty: {
        paddingTop: "40px",
        fontSize: "16px",
        fontWeight: "600"
    },
    usersCont: {
        width: "100%",
        height: "100px",
        fontSize: "16px",
        fontWeight: "500",
        borderRadius: "10px",
        border: `2px dashed ${theme.palette.primary.main}`,
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "center",
        backgroundColor: "#f5f5f5",
        "&:hover": {
            backgroundColor: "#ececec",
        }
    },
    selectedParticipantsCont: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        backgroundColor: "#f9f9f9",
        padding: "5px 5px 0px",
        border: "1px solid #dfdfdf",
        "& .MuiButton-root": {
            height: "38px",
            borderRadius: "5px",
            marginBottom: "5px"
        }
    },
    validationSty: {
        fontSize: "12px",
        color: "red",
        fontWeight: "400",
        marginTop: "3px"
    }
}));

const userOrgPalParam = [
    {
        entity: "User",
        scop: [
            {
                type: "Global",
                role: []
            }
        ]
    },
    {
        entity: "Organization",
        scop: [
            {
                type: "Global",
                role: []
            }
        ]
    },
    {
        entity: "User(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    },
    {
        entity: "Group(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    },
    {
        entity: "Organization(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    }
]

const projectOrgPalParam = [
    {
        entity: "Organization",
        scop: [
            {
                type: "Global",
                role: []
            }
        ]
    },
    {
        entity: "Project",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    },
    {
        entity: "User(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    },
    {
        entity: "Group(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    },
    {
        entity: "Organization(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    }
]

const userPalParam = [
    {
        entity: "User",
        scop: [
            {
                type: "Global",
                role: []
            }
        ]
    },
    {
        entity: "User(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    },
    {
        entity: "Group(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    },
    {
        entity: "Organization(Pal)",
        scop: [
            {
                type: "Contact",
                role: [...mainRoles]
            }
        ]
    }
]

export default function AddEventDrawer({
    openAddEventDrawer, setOpenAddEventDrawer, profileParentData,
    profileId, afterAssetsAdd, selectedAsset
}) {
    const classes = useStyles();
    const theme = useTheme();
    const { user } = useSelector((state) => state.auth);

    const initialObj = {
        eventType: ASSET_OPTIONS.eventTypes[0],
        //Event: Move (between site)
        AssignToProjectAndOrg: [],
        move_curLocation: "",
        move_dateOfMove: new Date(),
        move_condition: "",
        move_comment: "",
        //Event: Assign (between people)
        assignToIndividual: [],
        assign_dateOfMove: new Date(),
        assign_condition: "",
        assign_comment: "",
        //Event: Under Repair
        issueProblemDescription: "",
        resolution: "",
        repairDateFrom: new Date(),
        repairDateTo: new Date(),
        repairCost: 0,
        repairVendor: null,
        //Event: lost
        dateOfLoss: new Date(),
        lostComment: "",
        //Event: Expired
        expirationDate: new Date(),
        usageTime: "",
        expriationComment: "",
        //Event: Sold
        saleDate: new Date(),
        sellingPrice: 0,
        buyer: null
    }
    const [openIndividualAssignDrawer, setOpenIndividualAssignDrawer] = useState(false)
    const [openProOrgAssignSelectDrawer, setOpenProOrgAssignSelectDrawer] = useState(false)
    const [eventObject, setEventObject] = useState(initialObj)
    const [loader, setLoader] = useState(false)
    const [openVendorDrawer, setOpenVendorDrawer] = useState(false)
    const [openBuyerDrawer, setOpenBuyerDrawer] = useState(false)
    const [assets, setAssets] = useState([])
    const [notifyObj, setNotifyObj] = useState({})
    const [validationObj, setValidationObj] = useState({})

    useEffect(() => {
        setAssets(selectedAsset)
    }, [selectedAsset])

    useEffect(() => {
        setValidationObj({})
    }, [eventObject, assets])

    const onSubmit = async (e) => {
        e.preventDefault();
        if (profileParentData && profileParentData?._id) {
            let schemaObj = moveEventValidationSchema;
            if (eventObject?.eventType?.value === ASSET_OPTIONS.eventTypes[0]?.value) {
                schemaObj = moveEventValidationSchema;
            } else if (eventObject?.eventType?.value === ASSET_OPTIONS.eventTypes[1]?.value) {
                schemaObj = assignEventValidationSchema;
            } else if (eventObject?.eventType?.value === ASSET_OPTIONS.eventTypes[2]?.value) {
                schemaObj = repairEventValidationSchema;
            } else if (eventObject?.eventType?.value === ASSET_OPTIONS.eventTypes[3]?.value) {
                schemaObj = lostEventValidationSchema;
            } else if (eventObject?.eventType?.value === ASSET_OPTIONS.eventTypes[4]?.value) {
                schemaObj = expiredEventValidationSchema;
            } else if (eventObject?.eventType?.value === ASSET_OPTIONS.eventTypes[5]?.value) {
                schemaObj = soldEventValidationSchema;
            }
            let dataObj = {
                ...eventObject,
                assets: assets
            }

            await schemaObj.validate(dataObj, { abortEarly: false })
                .then(async (data) => {
                    setLoader(true);
                    const entityProfileId = profileParentData?._id;
                    let reqObject = {}
                    let reqObjectArr = [];
                    let offeringUpdateObj = {}
                    let offeringUpdateObjectArr = [];

                    if (eventObject?.eventType?.value === ASSET_OPTIONS.eventTypes[5]?.value) {
                        reqObject = {
                            offering: assets?.data?._id,
                            eventType: eventObject?.eventType?.value,
                            profile: entityProfileId,
                            createdBy: user?.profile,
                            soldObject: {
                                saleDate: eventObject?.saleDate,
                                sellingPrice: eventObject?.sellingPrice,
                                buyer: eventObject?.buyer
                            }
                        }
                        offeringUpdateObj = {
                            updateOne: {
                                filter: { _id: assets?.data?._id },
                                update: {
                                    $set: {
                                        finalSellingPrice: eventObject?.sellingPrice || 0,
                                        assetStatus: eventObject?.eventType?.status,
                                        location: "Third Party",
                                    },
                                },
                            },
                        };

                        reqObjectArr.push(reqObject)
                        offeringUpdateObjectArr.push(offeringUpdateObj)
                    } else {
                        assets && assets.map((asset) => {
                            if (eventObject?.eventType?.value === ASSET_OPTIONS.eventTypes[0]?.value) {
                                reqObject = {
                                    offering: asset?.data?._id,
                                    eventType: eventObject?.eventType?.value,
                                    profile: entityProfileId,
                                    createdBy: user?.profile,
                                    moveObject: {
                                        assignTo: eventObject?.AssignToProjectAndOrg && eventObject.AssignToProjectAndOrg.length > 0 ? eventObject.AssignToProjectAndOrg.map((s) => s?._id) : [],
                                        curLocation: eventObject?.move_curLocation,
                                        dateOfMove: eventObject?.move_dateOfMove,
                                        condition: eventObject?.move_condition,
                                        comment: eventObject?.move_comment
                                    }
                                }
                                offeringUpdateObj = {
                                    updateOne: {
                                        filter: { _id: asset?.data?._id },
                                        update: {
                                            $set: {
                                                assetStatus: eventObject?.eventType?.status,
                                                location: eventObject?.move_curLocation,
                                                assignToTeam: eventObject?.AssignToProjectAndOrg && eventObject.AssignToProjectAndOrg.length > 0 ? eventObject.AssignToProjectAndOrg.map((s) => s?._id) : [],
                                            },
                                        },
                                    },
                                };

                                reqObjectArr.push(reqObject)
                                offeringUpdateObjectArr.push(offeringUpdateObj)
                            } else if (eventObject?.eventType?.value === ASSET_OPTIONS.eventTypes[1]?.value) {
                                reqObject = {
                                    offering: asset?.data?._id,
                                    eventType: eventObject?.eventType?.value,
                                    profile: entityProfileId,
                                    createdBy: user?.profile,
                                    assignObject: {
                                        assignTo: eventObject?.assignToIndividual && eventObject.assignToIndividual.length > 0 ? eventObject.assignToIndividual.map((s) => s?._id) : [],
                                        dateOfMove: eventObject?.assign_dateOfMove,
                                        condition: eventObject?.assign_condition,
                                        comment: eventObject?.assign_comment
                                    }
                                }
                                offeringUpdateObj = {
                                    updateOne: {
                                        filter: { _id: asset?.data?._id },
                                        update: {
                                            $set: {
                                                assetStatus: eventObject?.eventType?.status,
                                                assignToIndividual: eventObject?.assignToIndividual && eventObject.assignToIndividual.length > 0 ? eventObject.assignToIndividual.map((s) => s?._id) : [],
                                            },
                                        },
                                    },
                                };

                                reqObjectArr.push(reqObject)
                                offeringUpdateObjectArr.push(offeringUpdateObj)
                            } else if (eventObject?.eventType?.value === ASSET_OPTIONS.eventTypes[2]?.value) {
                                reqObject = {
                                    offering: asset?.data?._id,
                                    eventType: eventObject?.eventType?.value,
                                    profile: entityProfileId,
                                    createdBy: user?.profile,
                                    repairObject: {
                                        issueProblemDescription: eventObject?.issueProblemDescription,
                                        resolution: eventObject?.resolution,
                                        repairDateFrom: eventObject?.repairDateFrom,
                                        repairDateTo: eventObject?.repairDateTo,
                                        repairCost: eventObject?.repairCost,
                                        repairVendor: eventObject?.repairVendor?._id
                                    }
                                }
                                offeringUpdateObj = {
                                    updateOne: {
                                        filter: { _id: asset?.data?._id },
                                        update: {
                                            $inc: { totalExpense: eventObject?.repairCost || 0 },
                                            $set: {
                                                assetStatus: eventObject?.eventType?.status,
                                                location: "Third Party",
                                            },
                                        },
                                    },
                                };

                                reqObjectArr.push(reqObject)
                                offeringUpdateObjectArr.push(offeringUpdateObj)
                            } else if (eventObject?.eventType?.value === ASSET_OPTIONS?.eventTypes[3]?.value) {
                                reqObject = {
                                    offering: asset?.data?._id,
                                    eventType: eventObject?.eventType?.value,
                                    profile: entityProfileId,
                                    createdBy: user?.profile,
                                    lostObject: {
                                        dateOfLoss: eventObject?.dateOfLoss,
                                        lostComment: eventObject?.lostComment,
                                    }
                                }
                                offeringUpdateObj = {
                                    updateOne: {
                                        filter: { _id: asset?.data?._id },
                                        update: {
                                            $set: {
                                                assetStatus: eventObject?.eventType?.status,
                                                location: "Unknown",
                                            },
                                        },
                                    },
                                };

                                reqObjectArr.push(reqObject)
                                offeringUpdateObjectArr.push(offeringUpdateObj)
                            } else if (eventObject?.eventType?.value === ASSET_OPTIONS.eventTypes[4]?.value) {
                                reqObject = {
                                    offering: asset?.data?._id,
                                    eventType: eventObject?.eventType?.value,
                                    profile: entityProfileId,
                                    createdBy: user?.profile,
                                    expiredObject: {
                                        expirationDate: eventObject?.expirationDate,
                                        usageTime: eventObject?.usageTime,
                                        expriationComment: eventObject?.expriationComment
                                    }
                                }
                                offeringUpdateObj = {
                                    updateOne: {
                                        filter: { _id: asset?.data?._id },
                                        update: {
                                            $set: {
                                                assetStatus: eventObject?.eventType?.status,
                                                location: "Warehouse/Main Office",
                                            },
                                        },
                                    },
                                };

                                reqObjectArr.push(reqObject)
                                offeringUpdateObjectArr.push(offeringUpdateObj)
                            }
                        })
                    }

                    await addMultipleEvents({ reqObjectArr })
                        .then(async (eventRes) => {
                            await offeringBulkUpdate({ offeringUpdateObjectArr })
                                .then((offeringUpdateRes) => {
                                    if (afterAssetsAdd) {
                                        afterAssetsAdd(null, true)
                                        setLoader(false)
                                        setOpenAddEventDrawer(false)
                                        setEventObject(initialObj)
                                        setAssets([])
                                    }
                                    setNotifyObj({
                                        show: true,
                                        message: "Event successfully added!!!",
                                        status: "success"
                                    })
                                })
                                .catch((err) => {
                                    console.log(err)
                                    setLoader(true)
                                    setNotifyObj({
                                        show: true,
                                        message: "Something went wrong!!!",
                                        status: "error"
                                    })
                                })
                        })
                        .catch((err) => {
                            console.log(err)
                            setLoader(true)
                            setNotifyObj({
                                show: true,
                                message: "Something went wrong!!!",
                                status: "error"
                            })
                        })
                })
                .catch((err) => {
                    console.log(err)
                    let errorArr = err?.inner
                    let obj = {}
                    errorArr.map((err) => {
                        obj[err?.path] = err?.message
                    })
                    setValidationObj(obj)
                })
        } else {
            setNotifyObj({
                show: true,
                message: "Something went wrong!!!",
                status: "warning"
            })
        }
    }

    return (<>
        <NormalDrawer
            openDrawer={openAddEventDrawer}
            setOpenDrawer={setOpenAddEventDrawer}
            anchor={"right"}
            title={"Add Asset Event"}
            content={
                <form className={classes.mainDialogCont} onSubmit={onSubmit} >
                    <div className={classes.bodyCont} >
                        <div className={classes.fullWInput} >
                            <p>Event<span style={{ color: "red" }} >*</span></p>
                            <Autocomplete
                                options={ASSET_OPTIONS.eventTypes}
                                value={eventObject?.eventType}
                                onChange={(event, value) => {
                                    setEventObject({
                                        ...eventObject,
                                        eventType: value
                                    })
                                }}
                                disableClearable
                                fullWidth
                                size="small"
                                getOptionLabel={(option) => option?.lable}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        {...params}
                                        placeholder={"Select event"}
                                    />
                                )}
                            />
                            <h5 className={classes.validationSty} >{validationObj?.eventType}</h5>
                        </div>

                        <div className={classes.fullWInput} >
                            <p>Asset(s)<span style={{ color: "red" }} >*</span></p>
                            <Select2Autocomplete
                                selectedOption={assets}
                                setSelectedOption={setAssets}
                                apiUrl={`/get/asset/dropdown/${profileId}`}
                                uniqueField={"_id"}
                                labelField={"name"}
                                isMulti={eventObject?.eventType?.value === ASSET_OPTIONS.eventTypes[5]?.value ? false : true}
                            />
                            <h5 className={classes.validationSty} >{validationObj?.assets}</h5>
                        </div>

                        {eventObject?.eventType?.value === ASSET_OPTIONS.eventTypes[0]?.value && (<>
                            <div className={classes.sectionLable} style={{ marginBottom: "15px" }} >
                                <div>
                                    Step 1: New Location - Project(s)/Organization(s)<span style={{ color: "red" }} >*</span>
                                </div>
                            </div>
                            <div style={{ padding: "0px 10px" }} >
                                {eventObject?.AssignToProjectAndOrg && eventObject.AssignToProjectAndOrg.length > 0 ? (
                                    <div className={classes.selectedParticipantsCont}>
                                        {eventObject?.AssignToProjectAndOrg && eventObject.AssignToProjectAndOrg.map((selectedProfile) => (<div className={classes.selectedUserOrgSty} >
                                            <Avatar
                                                src={selectedProfile?.parent?.displayPicture?.thumbUrl ? selectedProfile?.parent?.displayPicture?.thumbUrl : selectedProfile?.parent?.displayPicture?.url}
                                                alt={selectedProfile?.parent?.displayName}
                                            />
                                            <div style={{ marginLeft: "5px" }} >
                                                <h3>{selectedProfile?.parent?.displayName}</h3>
                                                <p>{selectedProfile?.parent?.username}</p>
                                            </div>
                                            <IconButton
                                                onClick={() => {
                                                    const filteredEntities = eventObject.AssignToProjectAndOrg.filter((entityProfile) => entityProfile?._id != selectedProfile?._id)
                                                    setEventObject({
                                                        ...eventObject,
                                                        AssignToProjectAndOrg: filteredEntities
                                                    })
                                                }}
                                            >
                                                <CancelIcon />
                                            </IconButton>
                                        </div>))}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            startIcon={<AddIcon />}
                                            onClick={() => {
                                                setOpenProOrgAssignSelectDrawer(true)
                                            }}
                                        >
                                            Add Project/Organization
                                        </Button>
                                    </div>
                                ) : (
                                    <div
                                        className={classes.usersCont}
                                        onClick={() => {
                                            setOpenProOrgAssignSelectDrawer(true)
                                        }}
                                    >
                                        + Add Project/Organization
                                    </div>
                                )}
                                <h5 className={classes.validationSty} >{validationObj?.AssignToProjectAndOrg}</h5>
                            </div>
                            <SearchEntityDialog
                                searchParameter={projectOrgPalParam}
                                entity={profileParentData?.parentModelName}
                                curEntityId={profileParentData?.parent?._id}
                                onSave={(value) => {
                                    setEventObject({
                                        ...eventObject,
                                        AssignToProjectAndOrg: value
                                    })
                                }}
                                selectedProfile={eventObject?.AssignToProjectAndOrg && eventObject.AssignToProjectAndOrg.length > 0 ? eventObject.AssignToProjectAndOrg : []}
                                noFilter={true}
                                multiple={true}
                                open={openProOrgAssignSelectDrawer}
                                setOpen={setOpenProOrgAssignSelectDrawer}
                                onClickOptionHideOptions={true}
                                financialRelationType={"Customer"}
                                title={"Select Project/Organization"}
                                noPalCreate={false}
                            />
                            <div className={classes.sectionLable} >
                                <div>
                                    Step 2:Basic Information
                                </div>
                            </div>
                            <div className={classes.inputBoxCont} >
                                <div className={classes.halfWInputAutocomplete} >
                                    <p>New Location Type</p>
                                    <Autocomplete
                                        options={ASSET_OPTIONS.location}
                                        value={eventObject?.move_curLocation}
                                        onChange={(event, value) => {
                                            setEventObject({
                                                ...eventObject,
                                                move_curLocation: value
                                            })
                                        }}
                                        fullWidth
                                        size="small"
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                size="small"
                                                variant="outlined"
                                                {...params}
                                                placeholder={"Select location"}
                                            />
                                        )}
                                    />
                                </div>
                                <div className={classes.halfWInput} >
                                    <p>Date Of Move<span style={{ color: "red" }} >*</span></p>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            value={eventObject?.move_dateOfMove}
                                            fullWidth
                                            margin="normal"
                                            id="due-date-picker"
                                            format="MM/dd/yyyy"
                                            onChange={async (date) => {
                                                setEventObject({
                                                    ...eventObject,
                                                    move_dateOfMove: date
                                                })
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <h5 className={classes.validationSty} >{validationObj?.move_dateOfMove}</h5>
                                </div>
                                <div className={classes.halfWInputAutocomplete} >
                                    <p>Condition</p>
                                    <Autocomplete
                                        options={ASSET_OPTIONS.condition}
                                        value={eventObject?.move_condition}
                                        onChange={(event, value) => {
                                            setEventObject({
                                                ...eventObject,
                                                move_condition: value
                                            })
                                        }}
                                        fullWidth
                                        size="small"
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                size="small"
                                                variant="outlined"
                                                {...params}
                                                placeholder={"Select condition"}
                                            />
                                        )}
                                    />
                                </div>
                                <div className={classes.fullWInput} >
                                    <p>Comment</p>
                                    <TextField
                                        id="outlined-multiline-static"
                                        multiline
                                        fullWidth
                                        rows={4}
                                        size="small"
                                        value={eventObject?.move_comment}
                                        onChange={(e) => {
                                            setEventObject({
                                                ...eventObject,
                                                move_comment: e.target.value
                                            })
                                        }}
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        </>)}

                        {eventObject?.eventType?.value === ASSET_OPTIONS.eventTypes[1]?.value && (<>
                            <div className={classes.sectionLable} style={{ marginBottom: "15px" }} >
                                <div>Step 1: Assign to Individual<span style={{ color: "red" }} >*</span></div>
                            </div>
                            <div style={{ padding: "0px 10px" }} >
                                {eventObject?.assignToIndividual && eventObject.assignToIndividual.length > 0 ? (
                                    <div className={classes.selectedParticipantsCont}>
                                        {eventObject?.assignToIndividual && eventObject.assignToIndividual.map((selectedProfile) => (<div className={classes.selectedUserOrgSty} >
                                            <Avatar
                                                src={selectedProfile?.parent?.displayPicture?.thumbUrl ? selectedProfile?.parent?.displayPicture?.thumbUrl : selectedProfile?.parent?.displayPicture?.url}
                                                alt={selectedProfile?.parent?.displayName}
                                            />
                                            <div style={{ marginLeft: "5px" }} >
                                                <h3>{selectedProfile?.parent?.displayName}</h3>
                                                <p>{selectedProfile?.parent?.username}</p>
                                            </div>
                                            <IconButton
                                                onClick={() => {
                                                    const filteredEntities = eventObject.assignToIndividual.filter((entityProfile) => entityProfile?._id != selectedProfile?._id)
                                                    setEventObject({
                                                        ...eventObject,
                                                        assignToIndividual: filteredEntities
                                                    })
                                                }}
                                            >
                                                <CancelIcon />
                                            </IconButton>
                                        </div>))}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            startIcon={<AddIcon />}
                                            onClick={() => {
                                                setOpenIndividualAssignDrawer(true)
                                            }}
                                        >
                                            Add User(s)
                                        </Button>
                                    </div>
                                ) : (
                                    <div
                                        className={classes.usersCont}
                                        onClick={() => {
                                            setOpenIndividualAssignDrawer(true)
                                        }}
                                    >
                                        + Add User(s)
                                    </div>
                                )}
                                <h5 className={classes.validationSty} >{validationObj?.assignToIndividual}</h5>
                            </div>
                            <SearchEntityDialog
                                searchParameter={userPalParam}
                                entity={profileParentData?.parentModelName}
                                curEntityId={profileParentData?.parent?._id}
                                onSave={(value) => {
                                    setEventObject({
                                        ...eventObject,
                                        assignToIndividual: value
                                    })
                                }}
                                selectedProfile={eventObject?.assignToIndividual && eventObject.assignToIndividual.length > 0 ? eventObject.assignToIndividual : []}
                                noFilter={true}
                                multiple={true}
                                open={openIndividualAssignDrawer}
                                setOpen={setOpenIndividualAssignDrawer}
                                onClickOptionHideOptions={true}
                                financialRelationType={"Customer"}
                                title={"Select Users(s)"}
                                noPalCreate={false}
                            />
                            <div className={classes.sectionLable} >
                                <div>
                                    Step 2:Basic Information
                                </div>
                            </div>
                            <div className={classes.inputBoxCont} >
                                <div className={classes.halfWInput} >
                                    <p>Date of Move<span style={{ color: "red" }} >*</span></p>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            value={eventObject?.assign_dateOfMove}
                                            fullWidth
                                            margin="normal"
                                            id="due-date-picker"
                                            format="MM/dd/yyyy"
                                            onChange={async (date) => {
                                                setEventObject({
                                                    ...eventObject,
                                                    assign_dateOfMove: date
                                                })
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <h5 className={classes.validationSty} >{validationObj?.assign_dateOfMove}</h5>
                                </div>
                                <div className={classes.halfWInputAutocomplete} >
                                    <p>Condition</p>
                                    <Autocomplete
                                        options={ASSET_OPTIONS.condition}
                                        value={eventObject?.assign_condition}
                                        onChange={(event, value) => {
                                            setEventObject({
                                                ...eventObject,
                                                assign_condition: value
                                            })
                                        }}
                                        fullWidth
                                        size="small"
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                size="small"
                                                variant="outlined"
                                                {...params}
                                                placeholder={"Select condition"}
                                            />
                                        )}
                                    />
                                </div>
                                <div className={classes.fullWInput} >
                                    <p>Comment</p>
                                    <TextField
                                        id="outlined-multiline-static"
                                        multiline
                                        fullWidth
                                        rows={4}
                                        size="small"
                                        value={eventObject?.assign_comment}
                                        onChange={(e) => {
                                            setEventObject({
                                                ...eventObject,
                                                assign_comment: e.target.value
                                            })
                                        }}
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        </>)}

                        {eventObject?.eventType?.value === ASSET_OPTIONS.eventTypes[2]?.value && (<>
                            <div className={classes.inputBoxCont} >
                                <div className={classes.fullWInput} >
                                    <p>Issue/Problem Description<span style={{ color: "red" }} >*</span></p>
                                    <TextField
                                        id="outlined-multiline-static"
                                        multiline
                                        fullWidth
                                        rows={4}
                                        value={eventObject?.issueProblemDescription}
                                        onChange={(e) => {
                                            setEventObject({
                                                ...eventObject,
                                                issueProblemDescription: e.target.value
                                            })
                                        }}
                                        variant="outlined"
                                    />
                                    <h5 className={classes.validationSty} >{validationObj?.issueProblemDescription}</h5>
                                </div>
                                <div className={classes.fullWInput} >
                                    <p>Resolution<span style={{ color: "red" }} >*</span></p>
                                    <TextField
                                        id="outlined-multiline-static"
                                        multiline
                                        fullWidth
                                        rows={4}
                                        value={eventObject?.resolution}
                                        onChange={(e) => {
                                            setEventObject({
                                                ...eventObject,
                                                resolution: e.target.value
                                            })
                                        }}
                                        variant="outlined"
                                    />
                                    <h5 className={classes.validationSty} >{validationObj?.resolution}</h5>
                                </div>
                                <div className={classes.halfWInput} >
                                    <p>Repair Date From</p>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            value={eventObject?.repairDateFrom}
                                            fullWidth
                                            margin="normal"
                                            id="due-date-picker"
                                            format="MM/dd/yyyy"
                                            onChange={async (date) => {
                                                setEventObject({
                                                    ...eventObject,
                                                    repairDateFrom: date
                                                })
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className={classes.halfWInput} >
                                    <p>Repair Date To</p>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            value={eventObject?.repairDateTo}
                                            fullWidth
                                            margin="normal"
                                            id="due-date-picker"
                                            format="MM/dd/yyyy"
                                            onChange={async (date) => {
                                                setEventObject({
                                                    ...eventObject,
                                                    repairDateTo: date
                                                })
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </div>
                                <div className={classes.halfWInput} >
                                    <p>Repair Cost</p>
                                    <TextFieldNumberFormated
                                        variant="outlined"
                                        size="small"
                                        placeholder="Enter purchse cost"
                                        autoComplete='off'
                                        value={eventObject?.repairCost}
                                        onChange={(e) => {
                                            setEventObject({
                                                ...eventObject,
                                                repairCost: e.target.value
                                            })
                                        }}
                                        fullWidth
                                    />
                                </div>
                                <div className={classes.halfWInput} >
                                    <p>Vendor</p>
                                    {eventObject?.repairVendor ? (
                                        <div className={classes.selectedUserSty} onClick={() => { setOpenVendorDrawer(true) }} >
                                            <Avatar
                                                src={eventObject?.repairVendor?.parent?.displayPicture?.thumbUrl ? eventObject?.repairVendor?.parent?.displayPicture?.thumbUrl : eventObject?.repairVendor?.parent?.displayPicture?.url}
                                                alt={eventObject?.repairVendor?.parent?.displayName}
                                            />
                                            <div style={{ marginLeft: "5px" }} >
                                                <h3>{eventObject?.repairVendor?.parent?.displayName}</h3>
                                                <p>{eventObject?.repairVendor?.parent?.username}</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={classes.addVendorSty} onClick={() => { setOpenVendorDrawer(true) }} >
                                            + Add Vendor
                                        </div>
                                    )}
                                    <SearchEntityDialog
                                        searchParameter={userOrgPalParam}
                                        givenNotAllowedProfileId={[]}
                                        entity={profileParentData?.parentModelName}
                                        curEntityId={profileParentData?.parent?._id}
                                        onSave={(value) => {
                                            setEventObject({
                                                ...eventObject,
                                                repairVendor: value
                                            })
                                        }}
                                        selectedProfile={eventObject?.repairVendor ? [eventObject?.repairVendor] : []}
                                        noFilter={true}
                                        multiple={false}
                                        open={openVendorDrawer}
                                        setOpen={setOpenVendorDrawer}
                                        onClickOptionHideOptions={true}
                                        financialRelationType={"Vendor"}
                                        title={"Select vendor"}
                                        noPalCreate={false}
                                        allowOutSider={false}
                                    />
                                </div>
                            </div>
                        </>)}

                        {eventObject?.eventType?.value === ASSET_OPTIONS.eventTypes[3]?.value && (<>
                            <div className={classes.inputBoxCont} >
                                <div className={classes.halfWInput} >
                                    <p>Date Of Loss<span style={{ color: "red" }} >*</span></p>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            value={eventObject?.dateOfLoss}
                                            fullWidth
                                            margin="normal"
                                            id="due-date-picker"
                                            format="MM/dd/yyyy"
                                            onChange={async (date) => {
                                                setEventObject({
                                                    ...eventObject,
                                                    dateOfLoss: date
                                                })
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <h5 className={classes.validationSty} >{validationObj?.dateOfLoss}</h5>
                                </div>

                                <div className={classes.fullWInput} >
                                    <p>Comment</p>
                                    <TextField
                                        id="outlined-multiline-static"
                                        multiline
                                        fullWidth
                                        rows={4}
                                        value={eventObject?.lostComment}
                                        onChange={(e) => {
                                            setEventObject({
                                                ...eventObject,
                                                lostComment: e.target.value
                                            })
                                        }}
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        </>)}

                        {eventObject?.eventType?.value === ASSET_OPTIONS.eventTypes[4]?.value && (<>
                            <div className={classes.inputBoxCont} >
                                <div className={classes.halfWInput} >
                                    <p>expiration date<span style={{ color: "red" }} >*</span></p>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            value={eventObject?.expirationDate}
                                            fullWidth
                                            margin="normal"
                                            id="due-date-picker"
                                            format="MM/dd/yyyy"
                                            onChange={async (date) => {
                                                setEventObject({
                                                    ...eventObject,
                                                    expirationDate: date
                                                })
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <h5 className={classes.validationSty} >{validationObj?.expirationDate}</h5>
                                </div>
                                <div className={classes.halfWInput} >
                                    <p>Utilization Period</p>
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        size="small"
                                        placeholder="Enter asset usage"
                                        autoComplete='off'
                                        type="number"
                                        value={eventObject?.usageTime}
                                        fullWidth
                                        onChange={(e) => {
                                            setEventObject({
                                                ...eventObject,
                                                usageTime: e.target.value
                                            })
                                        }}
                                    />
                                </div>
                                <div className={classes.fullWInput} >
                                    <p>Comment</p>
                                    <TextField
                                        id="outlined-multiline-static"
                                        multiline
                                        fullWidth
                                        rows={4}
                                        value={eventObject?.expriationComment}
                                        onChange={(e) => {
                                            setEventObject({
                                                ...eventObject,
                                                expriationComment: e.target.value
                                            })
                                        }}
                                        variant="outlined"
                                    />
                                </div>
                            </div>
                        </>)}

                        {eventObject?.eventType?.value === ASSET_OPTIONS.eventTypes[5]?.value && (<>
                            <div className={classes.inputBoxCont} >
                                <div className={classes.halfWInput} >
                                    <p>Sale Date<span style={{ color: "red" }} >*</span></p>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <KeyboardDatePicker
                                            value={eventObject?.saleDate}
                                            fullWidth
                                            margin="normal"
                                            id="due-date-picker"
                                            format="MM/dd/yyyy"
                                            onChange={async (date) => {
                                                setEventObject({
                                                    ...eventObject,
                                                    saleDate: date
                                                })
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <h5 className={classes.validationSty} >{validationObj?.saleDate}</h5>
                                </div>
                                <div className={classes.halfWInput} >
                                    <p>selling Price<span style={{ color: "red" }} >*</span></p>
                                    <TextFieldNumberFormated
                                        variant="outlined"
                                        size="small"
                                        placeholder="Enter purchse cost"
                                        autoComplete='off'
                                        value={eventObject?.sellingPrice}
                                        onChange={(e) => {
                                            setEventObject({
                                                ...eventObject,
                                                sellingPrice: e.target.value
                                            })
                                        }}
                                        fullWidth
                                    />
                                    <h5 className={classes.validationSty} >{validationObj?.sellingPrice}</h5>
                                </div>
                                <div className={classes.halfWInput} >
                                    <p>Buyer</p>
                                    {eventObject?.buyer ? (
                                        <div className={classes.selectedUserSty} onClick={() => { setOpenBuyerDrawer(true) }} >
                                            <Avatar
                                                src={eventObject?.buyer?.parent?.displayPicture?.thumbUrl ? eventObject?.buyer?.parent?.displayPicture?.thumbUrl : eventObject?.buyer?.parent?.displayPicture?.url}
                                                alt={eventObject?.buyer?.parent?.displayName}
                                            />
                                            <div style={{ marginLeft: "5px" }} >
                                                <h3>{eventObject?.buyer?.parent?.displayName}</h3>
                                                <p>{eventObject?.buyer?.parent?.username}</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className={classes.addVendorSty} onClick={() => { setOpenBuyerDrawer(true) }} >
                                            + Add Vendor
                                        </div>
                                    )}
                                    <SearchEntityDialog
                                        searchParameter={userOrgPalParam}
                                        givenNotAllowedProfileId={[]}
                                        entity={profileParentData?.parentModelName}
                                        curEntityId={profileParentData?.parent?._id}
                                        onSave={(value) => {
                                            setEventObject({
                                                ...eventObject,
                                                buyer: value
                                            })
                                        }}
                                        selectedProfile={eventObject?.buyer ? [eventObject?.buyer] : []}
                                        noFilter={true}
                                        multiple={false}
                                        open={openBuyerDrawer}
                                        setOpen={setOpenBuyerDrawer}
                                        onClickOptionHideOptions={true}
                                        financialRelationType={"Vendor"}
                                        title={"Select vendor"}
                                        noPalCreate={false}
                                        allowOutSider={false}
                                    />
                                </div>
                            </div>
                        </>)}
                    </div>
                    <div style={{ width: "100%", height: "5px" }} >
                        {loader && (<LinearProgress />)}
                    </div>
                    <div className={classes.bottomAct} >
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            type="submit"
                            disabled={loader ? true : profileParentData && profileParentData?._id ? false : true}
                        >
                            Save & Close
                        </Button>
                    </div>
                </form>
            }
        />
        <NotifyAlert
            alertObj={notifyObj}
            setAlertObj={setNotifyObj}
        />
    </>);
}