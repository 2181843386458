import { Box, IconButton, Typography } from "@mui/material";
import React from "react";
import CustomFileUploadButton from "../../file/Uploader/CustomFileUploadButton";
import UploadButton2 from "./UploadButton2";
import { CloseOutlined } from "@material-ui/icons";

const DocumentUploader = ({
  documents,
  onDelete,
  onUpload,
  parentType,
  parentId,
}) => {
  return (
    <Box>
      <CustomFileUploadButton
        showComponent={<UploadButton2 />}
        parentType={parentType}
        parentId={parentId}
        fileNum={25}
        givenMaxSize={26214400}
        closeFunCall={(data) => {
          onUpload(data);
        }}
      />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-start"
        flexWrap="wrap"
      >
        {documents?.map((doc) => (
          <Box
            sx={{
              minWidth: "160px",
              borderRadius: "14px",
              margin: 2,
              padding: 2,
              backgroundColor: "#F4F7FF",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body1">{doc.name}</Typography>
            <IconButton size="small" onClick={() => onDelete(doc._id)}>
              <CloseOutlined />
            </IconButton>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default DocumentUploader;
