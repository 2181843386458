import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { BiSolidLeftArrow } from "react-icons/bi";
import Skeleton from '@material-ui/lab/Skeleton';
import { FaCircle } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
    cardCont: {
        display: "flex",
    },
    eventDetailsContPadding: {
        width: "calc(100% - 100px)",
        padding: "20px"
    },
    eventDetailsCont: {
        padding: "10px",
        width: "100%",
        borderRadius: "7px",
        border: "1px solid #c5c5c5",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        position: "relative",
    },
    tackerPointContFull: {
        width: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        "& .lineSty": {
            width: "2px",
            height: "100%",
            backgroundColor: "#ececec",
        },
    },
    tackerPointContTopHalf: {
        width: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        position: "relative",
        "& .lineSty": {
            width: "2px",
            height: "50%",
            backgroundColor: "#ececec",
        },
    },
    tackerPointContBottomHalf: {
        width: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        position: "relative",
        "& .lineSty": {
            width: "2px",
            height: "50%",
            backgroundColor: "#ececec",
        },
    },
    iconContSty: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "1",
        backgroundColor: "#ececec",
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    eventCardHead: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "0px 10px",
        justifyContent: "space-between",
        "& h3": {
            fontSize: "16px",
            fontWeight: "500"
        },
        "& div": {
            padding: "2px 7px",
            borderRadius: "4px",
            color: "white"
        }
    },
    allFieldsCont: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
    },
    oneThirdCont: {
        width: "33.33%",
        padding: "10px",
        paddingBottom: "5px",
        "& p": {
            fontSize: "15px",
            fontWeight: "500",
            color: "gray"
        },
        "& h3": {
            fontSize: "15px",
            fontWeight: "500"
        }
    },
    fullCont: {
        width: "100%",
        padding: "10px",
        paddingBottom: "5px",
        "& p": {
            fontSize: "15px",
            fontWeight: "500",
            color: "gray"
        },
        "& h3": {
            fontSize: "15px",
            fontWeight: "500"
        }
    },
    avatarGroupSty: {
        "& .MuiAvatar-root": {
            width: "30px",
            height: "30px",
        },
    },
    selectedUserOrgSty: {
        maxWidth: "100%",
        padding: "2px 5px",
        cursor: "pointer",
        border: "1px solid #c3c2c2",
        borderRadius: "8px",
        marginRight: "5px",
        marginBottom: "5px",
        display: "flex",
        alignItems: "center",
        "& .MuiSvgIcon-root": {
            fontSize: "20px",
            marginLeft: "5px"
        },
        "& .MuiIconButton-root": {
            padding: "3px"
        },
        "& .MuiAvatar-root": {
            width: "30px",
            height: "30px",
        },
        "& h3": {
            fontSize: "13px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "10px",
            fontWeight: "400",
            color: "gray"
        }
    },
    arrorIcon: {
        position: "absolute",
        top: "calc(50% - 13px)",
        zIndex: "1",
        left: "-19px",
        fontSize: "25px",
        color: "#c5c5c5"
    },
    singleSLine: {
        width: "100%",
        height: "22px",
        marginBottom: "5px"
    }
}));

export default function AssetEventCardSkeleton(props) {
    const classes = useStyles();
    const postFromBackend = 20;
    const observer = useRef();
    const { user } = useSelector((state) => state.auth);


    const singleCardComponent = (isStart, isEnd) => {
        return (
            <div className={classes.cardCont} >
                <div className={isStart ? classes.tackerPointContBottomHalf : isEnd ? classes.tackerPointContTopHalf : classes.tackerPointContFull} >
                    <div className="lineSty" >
                    </div>
                    <span className={classes.iconContSty} >
                        <FaCircle style={{ color: "white", fontSize: "25px" }} />
                    </span>
                </div>

                <div className={classes.eventDetailsContPadding} >
                    <div className={classes.eventDetailsCont} >
                        <BiSolidLeftArrow className={classes.arrorIcon} />
                        <div className={classes.eventCardHead} >
                            <Skeleton style={{ width: "150px", height: "30px" }} variant="rect" />
                            <Skeleton style={{ width: "100px", height: "30px" }} variant="rect" />
                        </div>
                        <div className={classes.allFieldsCont} >
                            <div className={classes.oneThirdCont} >
                                <Skeleton className={classes.singleSLine} variant="rect" />
                                <Skeleton className={classes.singleSLine} variant="rect" />
                            </div>

                            <div className={classes.oneThirdCont} >
                                <Skeleton className={classes.singleSLine} variant="rect" />
                                <Skeleton className={classes.singleSLine} variant="rect" />
                            </div>

                            <div className={classes.oneThirdCont} >
                                <Skeleton className={classes.singleSLine} variant="rect" />
                                <Skeleton className={classes.singleSLine} variant="rect" />
                            </div>

                            <div className={classes.oneThirdCont} >
                                <Skeleton className={classes.singleSLine} variant="rect" />
                                <Skeleton className={classes.singleSLine} variant="rect" />
                            </div>

                            <div className={classes.oneThirdCont} >
                                <Skeleton className={classes.singleSLine} variant="rect" />
                                <Skeleton className={classes.singleSLine} variant="rect" />
                            </div>

                            <div className={classes.fullCont} >
                                <Skeleton className={classes.singleSLine} variant="rect" />
                                <Skeleton className={classes.singleSLine} variant="rect" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (<>
        {singleCardComponent(true, false)}
        {singleCardComponent(false, false)}
        {singleCardComponent(false, true)}
    </>);
}