import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import {
  Avatar,
  Paper,
  InputBase,
  InputAdornment,
  Button,
  IconButton,
} from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import { useParams, useHistory, useLocation } from "react-router-dom";
import {
  addNewFinancialRelation,
  getAllFinancialContacts,
  getInitialEntities,
  getPaginatedContact,
  searchEntities,
} from "./api.call";
import AddIcon from "@material-ui/icons/Add";
import { useMediaQuery } from "@material-ui/core";
import emptyIcon from "../../Assets/EmptyInboxOutline.svg";
import SearchIcon from "@material-ui/icons/Search";
import { useDebounce } from "react-use";
import Skeleton from "@material-ui/lab/Skeleton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import ContactsDialog from "./ContactsDialog";
import LessText from "../styled/CommonComponents/LessText";
import { parseContacts } from "../../helpers/contactHelper";
import Autocomplete from "../styled/generic/Autocomplete";
import TextField from "../styled/generic/TextField";
import ContactCreateDrawer from "./ContactCreateDrawer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  autocompleteAndBtnCont: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  inputPaper: {
    padding: "5px 4px",
    paddingLeft: "15px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  iconButton: {
    padding: "10px",
  },
  itemCont: {
    width: "100%",
    padding: "5px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    "& h3": {
      fontSize: "15px",
      fontWeight: "500",
    },
    "& p": {
      fontSize: "12px",
      fontWeight: "400",
      color: "gray",
    },
    "& h4": {
      backgroundColor: "gray",
      color: "white",
      fontSize: "10px",
      fontWeight: "350",
      padding: "0px 5px",
      borderRadius: "3px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "350px",
    },
  },
  autoSty: {
    width: "calc(100% - 100px)",
    position: "absolute",
    top: "0px",
    right: "0px",
    zIndex: "9",
    backgroundColor: "white",
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 60px)",
    },
  },
  roleAutoSty: {
    width: "130px",
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      minWidth: "85px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90px",
    },
  },
  noBorder: {
    border: "none",
  },
  inputPaper: {
    width: "calc(100% - 100px)",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 60px)",
    },
  },
  cardHeader: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  infoCont: {
    marginLeft: "5px",
    "& h3": {
      fontSize: "15px",
      fontWeight: "510",
      textAlign: "left",
    },
    "& p": {
      fontSize: "12px",
      fontWeight: "450",
      color: "gray",
      textAlign: "left",
    },
  },
  btnCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "20px",
  },
  threeDotSty: {
    position: "absolute",
    top: "5px",
    right: "10px",
    zIndex: "11",
  },
  typeContSty: {
    position: "absolute",
    top: "-18px",
    left: "5px",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    fontSize: "12px",
    fontWeight: "400",
    padding: "0px 5px 1px",
    borderRadius: "4px",
    borderBottomLeftRadius: "0px",
    borderBottomRightRadius: "0px",
  },
  addAtDate: {
    position: "absolute",
    bottom: "10px",
    left: "10px",
    zIndex: "9",
    fontSize: "10px",
    fontWeight: "400",
    color: "gray",
    textAlign: "left",
  },
  emptyCont: {
    width: "100%",
    height: "70%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& img": {
      width: "250px",
      height: "auto",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      color: "gray",
    },
  },
  searchInputCont: {
    width: "calc(100% - 100px)",
    position: "absolute",
    top: "0px",
    right: "0px",
    zIndex: "11",
    backgroundColor: "white",
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 60px)",
    },
  },
  optionsCont: {
    width: "125px",
    padding: "5px 0px",
    "& a": {
      display: "block",
      width: "100%",
      textDecoration: "none",
      color: "black",
      fontSize: "13px",
      fontWeight: "400",
      padding: "4px 5px",
      textAlign: "left",
      cursor: "pointer",
    },
  },
  paginationCont: {
    width: "100%",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderTop: "1px solid #d2d1d1",
  },
}));

export default function Contacts({
  searchWidth,
  roles,
  team,
  searchAndAddContStyle,
  allCardsContStyle,
  singleCardStyle,
  cardSkeletonStyle,
  searchPaperCont,
  openContactDrawer,
  setOpenContactDrawer,
  givenIsMdSmall
}) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const { user } = useSelector((state) => state.auth);
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [filteredContactsArr, setFilteredContactsArr] = useState([]);
  const [selectedSearchRole, setSelectedSearchRole] = useState("Any");
  const [searchText, setSearchText] = useState("");
  const [curPage, setCurPage] = useState(1)
  const [pageLimit, setPageLimit] = useState(25)
  const [changeState, setChangeState] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [loader, setLoader] = useState(true)

  const getAllContactsApiCall = async () => {
    setLoader(true)
    await getPaginatedContact({
      profileId: team?.parent?.profile?._id,
      role: selectedSearchRole,
      searchText: searchText,
      limit: pageLimit,
      curPage: curPage - 1
    })
      .then((data) => {
        const resultContacts = data?.allContacts || [];
        const resultCount = data?.count || 0;
        let locTotalPage = Math.ceil(resultCount / pageLimit);
        setTotalPages(locTotalPage)
        setFilteredContactsArr(resultContacts)
        setLoader(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useDebounce(() => {
    getAllContactsApiCall()
  }, 1000, [curPage, changeState])

  useEffect(() => {
    setCurPage(1)
    setChangeState(!changeState)
  }, [searchText, selectedSearchRole])


  let SerachOption = (<div>
    <Autocomplete
      id="free-solo-with-text-demo"
      selectOnFocus
      freeSolo
      clearOnBlur
      handleHomeEndKeys
      value={selectedSearchRole}
      onChange={(event, newValue) => {
        setSelectedSearchRole(newValue);
      }}
      options={["Any", ...roles]}
      getOptionLabel={(option) => {
        return option;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Role"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            classes: { notchedOutline: classes.noBorder },
          }}
        />
      )}
      size="small"
      className={classes.roleAutoSty}
    />
    <TextField
      placeholder={"Enter Name"}
      variant="outlined"
      size="small"
      value={searchText}
      onChange={(e) => {
        setSearchText(e.target.value);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      sx={{ ml: 2 }}
    />
  </div>)




  return (
    <div className={classes.root}>
      {/* <div>
        <h3>Contact Management</h3>

        {SerachOption}

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpenContactDrawer(true);
          }}
          startIcon={isSmall ? null : <AddIcon />}
        >
          {isSmall ? <AddIcon /> : "Add"}
        </Button>
      </div>
      <div>
        {SerachOption}
      </div> */}

      <div className={searchAndAddContStyle}>
        {loader ? (
          <Skeleton
            variant="rect"
            className={searchPaperCont}
            style={{ width: searchWidth, height: "40px" }}
          />
        ) : (
          <Paper
            className={searchPaperCont}
            style={{ width: searchWidth }}
            elevation={0}
          >

          </Paper>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpenContactDrawer(true);
          }}
          startIcon={isSmall ? null : <AddIcon />}
        >
          {isSmall ? <AddIcon /> : "Add"}
        </Button>
      </div>
      <div className={allCardsContStyle}>
        {loader ? (
          <>
            <Skeleton variant="rect" className={cardSkeletonStyle} />
            <Skeleton variant="rect" className={cardSkeletonStyle} />
            <Skeleton variant="rect" className={cardSkeletonStyle} />
            <Skeleton variant="rect" className={cardSkeletonStyle} />
            <Skeleton variant="rect" className={cardSkeletonStyle} />
            <Skeleton variant="rect" className={cardSkeletonStyle} />
            <Skeleton variant="rect" className={cardSkeletonStyle} />
          </>
        ) : (
          <>
            {filteredContactsArr && filteredContactsArr.length > 0 ? (
              filteredContactsArr.map((contactData, i) => (
                <Paper style={givenIsMdSmall ? { width: "100%" } : {}} className={singleCardStyle} key={i} elevation={0}>
                  <div
                    className={classes.cardHeader}
                    onClick={() => history.push(`/contact/${contactData._id}`)}
                  >
                    <Avatar
                      style={{ width: "35px", height: "35px" }}
                      src={contactData?.profile?.parent?.displayPicture?.url}
                      alt={contactData?.profile?.parent?.displayName}
                    />
                    <div className={classes.infoCont}>
                      <h3>
                        <LessText
                          limit={25}
                          string={contactData?.profile?.parent?.displayName}
                        />
                      </h3>
                      <p>
                        {contactData?.profile?.parent?.username
                          ? `@${contactData?.profile?.parent?.username}`
                          : contactData?.profile?.parent?.email}
                      </p>
                    </div>
                  </div>
                  <div className={classes.threeDotSty}></div>
                  <div className={classes.typeContSty}>{contactData?.type}</div>
                </Paper>
              ))
            ) : (
              <div className={classes.emptyCont}>
                <img src={emptyIcon} />
                <p>No contact is available.</p>
              </div>
            )}
          </>
        )}
      </div>
      {loader ? (
        <Skeleton variant="rect" style={{ width: "100%", height: "52px" }} />
      ) : (
        <div className={classes.paginationCont} >
          <Pagination
            count={totalPages}
            page={curPage}
            color="primary"
            siblingCount={0}
            onChange={(event, value) => { setCurPage(value) }}
          />
        </div>
      )}

      <ContactCreateDrawer
        openContactDrawer={openContactDrawer}
        setOpenContactDrawer={setOpenContactDrawer}
        team={team}
        callAfterFun={() => {
          setCurPage(1)
          setChangeState(!changeState)
        }}
      />




    </div>
  );
}
