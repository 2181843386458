import React from "react";
import { makeStyles } from "@material-ui/core/styles"
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    assetSearchBar: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        margin: "20px 0px",
        justifyContent: "space-between",
    },
    assetCardsCont: {
        width: "100%",
        maxHeight: "calc(100vh - 250px)",
        overflowX: "hidden",
        overflowY: "auto",
        display: "flex",
        flexWrap: "wrap",
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        borderTop: "1px solid #d2d1d1",
    },
    cardPaddingCont: {
        boxSizing: "border-box",
        width: "25%",
        padding: "10px"
    },
    singleCard: {
        width: "100%",
        height: "400px"
    }
}));

const AssetTableSkeleton = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    return (<>
        <div className={classes.assetSearchBar} >
            <Skeleton style={{ width: "200px", height: "28px" }} variant="rect" />
            <Skeleton style={{ width: "450px", height: "35px" }} variant="rect" />
            <Skeleton style={{ width: "90px", height: "40px" }} variant="rect" />
        </div>
        <div className={classes.assetCardsCont} >
            <div className={classes.cardPaddingCont} >
                <Skeleton className={classes.singleCard} variant="rect" />
            </div>
            <div className={classes.cardPaddingCont} >
                <Skeleton className={classes.singleCard} variant="rect" />
            </div>
            <div className={classes.cardPaddingCont} >
                <Skeleton className={classes.singleCard} variant="rect" />
            </div>
            <div className={classes.cardPaddingCont} >
                <Skeleton className={classes.singleCard} variant="rect" />
            </div>
            <div className={classes.cardPaddingCont} >
                <Skeleton className={classes.singleCard} variant="rect" />
            </div>
            <div className={classes.cardPaddingCont} >
                <Skeleton className={classes.singleCard} variant="rect" />
            </div>
            <div className={classes.cardPaddingCont} >
                <Skeleton className={classes.singleCard} variant="rect" />
            </div>
        </div>
        <Skeleton className={classes.paginationCont} variant="rect" />
    </>);
};

export default AssetTableSkeleton;