import React, { useState, useEffect, useCallback, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useDebounce } from "react-use";
import emptyfolder from "../../../Assets/FileIcon/emptyfolder.png";
import { getEventsByAsset } from "../api.call";
import EventCard from "./Event.Card";
import AssetEventCardSkeleton from "../../styled/skeletons/Asset.Event.Card.Skeleton";

const useStyles = makeStyles((theme) => ({
    progressBox: {
        width: "100%",
        height: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    emptySty: {
        width: "100%",
        padding: "20px 0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& img": {
            width: "250px",
            height: "auto",
        },
        "& p": {
            fontSize: "12px",
            fontWeight: "300",
            textAlign: "center",
        },
    },
    sectionLable: {
        "& div": {
            backgroundColor: "#EEF5FC",
            padding: "5px 10px",
            marginTop: "10px",
            fontSize: "16px",
            fontWeight: "500"
        }
    },
    cardsCont: {
        width: "100%",
        maxHeight: "calc(100vh - 125px)",
        overflowY: "auto",
        overflowX: "hidden",
        marginTop: "10px"
    },
}));

export default function EventsByAsset({ assetId }) {
    const classes = useStyles();
    const postFromBackend = 20;
    const observer = useRef();

    const { user } = useSelector((state) => state.auth);

    const [assetEventArr, setAssetEventsArr] = useState([]);
    const [hasMoreItems, sethasMoreItems] = useState(true);
    const [curPage, setCurrPage] = useState(0);
    const [loading, setLoading] = useState(true);
    const [changePageState, setChangePageState] = useState(true);
    const [searchObject, setSearchObject] = useState([]);
    const [totalLen, setTotalLen] = useState(0)

    const getEventsWithScroll = async () => {
        setLoading(true);
        await getEventsByAsset({
            offering: assetId,
            searchObject: searchObject,
            limit: postFromBackend,
            curPage: curPage,
        })
            .then(async (resResult) => {
                const data = resResult?.allAssetEvent || [];
                setTotalLen(resResult?.allAssetEventsCount || 0)
                if (curPage === 0) {
                    if (data && data.length > 0) {
                        setAssetEventsArr(data);
                    } else {
                        setAssetEventsArr([]);
                    }
                } else {
                    setAssetEventsArr((prev) => [...prev, ...data]);
                }
                if (data.length < postFromBackend) {
                    sethasMoreItems(false);
                } else {
                    sethasMoreItems(true);
                }
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const lastElementRef = useCallback((node) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver((entries) => {
            if (entries[0].isIntersecting && hasMoreItems) {
                setCurrPage((prev) => {
                    return prev + 1;
                });
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, hasMoreItems]);

    useEffect(() => {
        if (curPage === 0) {
            setChangePageState(!changePageState);
        } else {
            setCurrPage(0);
        }
    }, [searchObject]);

    useDebounce(() => {
        getEventsWithScroll();
    }, 700, [curPage, changePageState]);

    return (<>
        <div className={classes.sectionLable} >
            <div>Asset Tracking</div>
        </div>
        <div className={classes.cardsCont} >
            {assetEventArr && assetEventArr.length > 0 ? (
                <>
                    {curPage === 0 && loading ? (<AssetEventCardSkeleton />) : (
                        <>
                            {assetEventArr.map((eventData, idx) => {
                                let lastItem = assetEventArr.length - 5;
                                return (
                                    eventData?._id &&
                                    (idx === lastItem ? (
                                        <div
                                            style={{ width: "100%" }}
                                            key={idx}
                                            ref={lastElementRef}
                                        >
                                            <EventCard
                                                isStart={idx == 0}
                                                isEnd={(totalLen - 1) == idx}
                                                eventData={eventData}
                                            />
                                        </div>
                                    ) : (
                                        <div style={{ width: "100%" }} key={idx}>
                                            <EventCard
                                                isStart={idx == 0}
                                                isEnd={(totalLen - 1) == idx}
                                                eventData={eventData}
                                            />
                                        </div>
                                    ))
                                );
                            })}
                        </>
                    )}
                </>
            ) : (
                <>
                    {loading ? (<AssetEventCardSkeleton />) : (
                        <div className={classes.emptySty}>
                            <img src={emptyfolder} />
                            <p>No result is available!</p>
                        </div>
                    )}
                </>
            )}
            {assetEventArr && assetEventArr.length > 0 && hasMoreItems ? (
                <div className={classes.progressBox}>
                    {loading && <p>Loading...</p>}
                </div>
            ) : null}
        </div>
    </>);
}
