import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BuildIcon from '@mui/icons-material/Build';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import { Avatar } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import { BiSolidLeftArrow } from "react-icons/bi";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    cardCont: {
        display: "flex",
    },
    eventDetailsContPadding: {
        width: "calc(100% - 100px)",
        padding: "20px"
    },
    eventDetailsCont: {
        padding: "10px",
        width: "100%",
        borderRadius: "7px",
        border: "1px solid #c5c5c5",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        position: "relative",
    },
    tackerPointContFull: {
        width: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        "& .lineSty": {
            width: "2px",
            height: "100%",
            backgroundColor: "blue",
        },
    },
    tackerPointContTopHalf: {
        width: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        position: "relative",
        "& .lineSty": {
            width: "2px",
            height: "50%",
            backgroundColor: "blue",
        },
    },
    tackerPointContBottomHalf: {
        width: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end",
        position: "relative",
        "& .lineSty": {
            width: "2px",
            height: "50%",
            backgroundColor: "blue",
        },
    },
    iconContSty: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: "1",
        backgroundColor: "blue",
        width: "35px",
        height: "35px",
        borderRadius: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    eventCardHead: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& h3": {
            fontSize: "16px",
            fontWeight: "500"
        },
        "& div": {
            padding: "2px 7px",
            borderRadius: "4px",
            color: "white"
        }
    },
    allFieldsCont: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
    },
    oneThirdCont: {
        width: "33.33%",
        padding: "5px",
        "& p": {
            fontSize: "15px",
            fontWeight: "500",
            color: "gray"
        },
        "& h3": {
            fontSize: "15px",
            fontWeight: "500"
        }
    },
    fullCont: {
        width: "100%",
        padding: "10px",
        "& p": {
            fontSize: "15px",
            fontWeight: "500",
            color: "gray"
        },
        "& h3": {
            fontSize: "15px",
            fontWeight: "500"
        }
    },
    avatarGroupSty: {
        "& .MuiAvatar-root": {
            width: "30px",
            height: "30px",
        },
    },
    selectedUserOrgSty: {
        maxWidth: "100%",
        padding: "2px 5px",
        cursor: "pointer",
        border: "1px solid #c3c2c2",
        borderRadius: "8px",
        marginRight: "5px",
        marginBottom: "5px",
        display: "flex",
        alignItems: "center",
        "& .MuiSvgIcon-root": {
            fontSize: "20px",
            marginLeft: "5px"
        },
        "& .MuiIconButton-root": {
            padding: "3px"
        },
        "& .MuiAvatar-root": {
            width: "30px",
            height: "30px",
        },
        "& h3": {
            fontSize: "13px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "10px",
            fontWeight: "400",
            color: "gray"
        }
    },
    arrorIcon: {
        position: "absolute",
        top: "calc(50% - 13px)",
        zIndex: "1",
        left: "-19px",
        fontSize: "25px",
        color: "#c5c5c5"
    }
}));

export default function EventCard({ eventData, isStart, isEnd }) {
    const classes = useStyles();
    const postFromBackend = 20;
    const observer = useRef();

    const { user } = useSelector((state) => state.auth);

    const rendarEventContent = (data) => {
        switch (data?.eventType) {
            case "move":
                return (<>
                    <div className={classes.eventCardHead} >
                        <h3>{moment(data?.createdAt).format('MMM Do YYYY, hh:mm a')}</h3>
                        <div style={{ backgroundColor: "#0000FF" }} >Asset Move</div>
                    </div>
                    <div className={classes.allFieldsCont} >
                        <div className={classes.oneThirdCont} >
                            <p>Assign To</p>
                            <AvatarGroup max={4} className={classes.avatarGroupSty} >
                                {data?.moveObject?.assignTo && data.moveObject.assignTo.map((participant, idx) => (
                                    <Avatar
                                        key={idx}
                                        title={participant?.parent?.displayName}
                                        alt={participant?.parent?.displayName}
                                        src={participant?.parent?.displayPicture?.url}
                                    />
                                ))}
                            </AvatarGroup>
                        </div>

                        <div className={classes.oneThirdCont} >
                            <p>Previous Location</p>
                            <h3>{data?.moveObject?.previousLocation}</h3>
                        </div>

                        <div className={classes.oneThirdCont} >
                            <p>Current Location</p>
                            <h3>{data?.moveObject?.curLocation}</h3>
                        </div>

                        <div className={classes.oneThirdCont} >
                            <p>Date Of Move</p>
                            <h3>{data?.moveObject?.dateOfMove}</h3>
                        </div>

                        <div className={classes.oneThirdCont} >
                            <p>Condition</p>
                            <h3>{data?.moveObject?.condition}</h3>
                        </div>

                        <div className={classes.fullCont} >
                            <p>Comment</p>
                            <h3>{data?.moveObject?.comment}</h3>
                        </div>
                    </div>
                </>)

            case "assign":
                return (<>
                    <div className={classes.eventCardHead} >
                        <h3>{moment(data?.createdAt).format('MMM Do YYYY, hh:mm a')}</h3>
                        <div style={{ backgroundColor: "#00FF00" }} >Assign Asset</div>
                    </div>

                    <div className={classes.allFieldsCont} >
                        <div className={classes.oneThirdCont} >
                            <p>Assign To</p>
                            <AvatarGroup max={4} className={classes.avatarGroupSty} >
                                {data?.assignObject?.assignTo && data.assignObject.assignTo.map((participant, idx) => (
                                    <Avatar
                                        key={idx}
                                        title={participant?.parent?.displayName}
                                        alt={participant?.parent?.displayName}
                                        src={participant?.parent?.displayPicture?.url}
                                    />
                                ))}
                            </AvatarGroup>
                        </div>

                        <div className={classes.oneThirdCont} >
                            <p>Previous Location</p>
                            <h3>{data?.assignObject?.previousLocation}</h3>
                        </div>

                        <div className={classes.oneThirdCont} >
                            <p>Current Location</p>
                            <h3>{data?.assignObject?.curLocation}</h3>
                        </div>

                        <div className={classes.oneThirdCont} >
                            <p>Date Of Assign</p>
                            <h3>{data?.assignObject?.dateOfMove}</h3>
                        </div>

                        <div className={classes.oneThirdCont} >
                            <p>Condition</p>
                            <h3>{data?.assignObject?.condition}</h3>
                        </div>

                        <div className={classes.fullCont} >
                            <p>Comment</p>
                            <h3>{data?.assignObject?.comment}</h3>
                        </div>
                    </div>
                </>)

            case "repair":
                return (<>
                    <div className={classes.eventCardHead} >
                        <h3>{moment(data?.createdAt).format('MMM Do YYYY, hh:mm a')}</h3>
                        <div style={{ backgroundColor: "#FFA500" }} >Repair Asset</div>
                    </div>

                    <div className={classes.allFieldsCont} >
                        <div className={classes.oneThirdCont} >
                            <p>Vendor</p>
                            <div className={classes.selectedUserOrgSty} >
                                <Avatar
                                    src={data?.repairObject?.repairVendor?.parent?.displayPicture?.thumbUrl ? data?.repairObject?.repairVendor?.parent?.displayPicture?.thumbUrl : data?.repairObject?.repairVendor?.parent?.displayPicture?.url}
                                    alt={data?.repairObject?.repairVendor?.parent?.displayName}
                                />
                                <div style={{ marginLeft: "5px" }} >
                                    <h3>{data?.repairObject?.repairVendor?.parent?.displayName}</h3>
                                    <p>{data?.repairObject?.repairVendor?.parent?.username}</p>
                                </div>
                            </div>
                        </div>

                        <div className={classes.oneThirdCont} >
                            <p>Repair Cost</p>
                            <h3>{data?.repairObject?.repairCost}</h3>
                        </div>

                        <div className={classes.oneThirdCont} >
                            <p>Repair Date From</p>
                            <h3>{data?.repairObject?.repairDateFrom}</h3>
                        </div>

                        <div className={classes.oneThirdCont} >
                            <p>Repair Date To</p>
                            <h3>{data?.repairObject?.repairDateTo}</h3>
                        </div>

                        <div className={classes.fullCont} >
                            <p>Issue Depreciation</p>
                            <h3>{data?.repairObject?.issueProblemDescription}</h3>
                        </div>

                        <div className={classes.fullCont} >
                            <p>Resolution</p>
                            <h3>{data?.repairObject?.resolution}</h3>
                        </div>
                    </div>
                </>)

            case "lost":
                return (<>
                    <div className={classes.eventCardHead} >
                        <h3>{moment(data?.createdAt).format('MMM Do YYYY, hh:mm a')}</h3>
                        <div style={{ backgroundColor: "#FF0000" }} >Asset Lost</div>
                    </div>

                    <div className={classes.allFieldsCont} >
                        <div className={classes.oneThirdCont} >
                            <p>Date Of Loss</p>
                            <h3>{data?.lostObject?.dateOfLoss}</h3>
                        </div>

                        <div className={classes.fullCont} >
                            <p>Comment</p>
                            <h3>{data?.lostObject?.lostComment}</h3>
                        </div>
                    </div>
                </>)

            case "expired":
                return (<>
                    <div className={classes.eventCardHead} >
                        <h3>{moment(data?.createdAt).format('MMM Do YYYY, hh:mm a')}</h3>
                        <div style={{ backgroundColor: "#808080" }} >Asset Lost</div>
                    </div>

                    <div className={classes.allFieldsCont} >
                        <div className={classes.oneThirdCont} >
                            <p>Date Of Expiration</p>
                            <h3>{data?.expiredObject?.expirationDate}</h3>
                        </div>

                        <div className={classes.oneThirdCont} >
                            <p>Usage Period</p>
                            <h3>{data?.expiredObject?.usageTime}</h3>
                        </div>

                        <div className={classes.fullCont} >
                            <p>Comment</p>
                            <h3>{data?.expiredObject?.expriationComment}</h3>
                        </div>
                    </div>
                </>)

            case "sold":
                return (<>
                    <div className={classes.eventCardHead} >
                        <h3>{moment(data?.createdAt).format('MMM Do YYYY, hh:mm a')}</h3>
                        <div style={{ backgroundColor: "#800080" }} >Asset Lost</div>
                    </div>

                    <div className={classes.allFieldsCont} >
                        <div className={classes.oneThirdCont} >
                            <p>Sale Date</p>
                            <h3>{data?.soldObject?.saleDate}</h3>
                        </div>

                        <div className={classes.oneThirdCont} >
                            <p>Selling Price</p>
                            <h3>{data?.soldObject?.sellingPrice}</h3>
                        </div>

                        <div className={classes.oneThirdCont} >
                            <p>Buyer</p>
                            <div className={classes.selectedUserOrgSty} >
                                <Avatar
                                    src={data?.soldObject?.buyer?.parent?.displayPicture?.thumbUrl ? data?.soldObject?.buyer?.parent?.displayPicture?.thumbUrl : data?.soldObject?.buyer?.parent?.displayPicture?.url}
                                    alt={data?.soldObject?.buyer?.parent?.displayName}
                                />
                                <div style={{ marginLeft: "5px" }} >
                                    <h3>{data?.soldObject?.buyer?.parent?.displayName}</h3>
                                    <p>{data?.soldObject?.buyer?.parent?.username}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>)
        }
    }


    const rendarIcont = (data) => {
        switch (data?.eventType) {
            case "move":
                return (<span className={classes.iconContSty} >
                    <LocalShippingIcon style={{ color: "white" }} />
                </span>)

            case "assign":
                return (<span className={classes.iconContSty} >
                    <AssignmentIcon style={{ color: "white" }} />
                </span>)

            case "repair":
                return (<span className={classes.iconContSty} >
                    <BuildIcon style={{ color: "white" }} />
                </span>)

            case "lost":
                return (<span className={classes.iconContSty} >
                    <RemoveCircleIcon style={{ color: "white" }} />
                </span>)

            case "expired":
                return (<span className={classes.iconContSty} >
                    <AccessTimeIcon style={{ color: "white" }} />
                </span>)

            case "sold":
                return (<span className={classes.iconContSty} >
                    <LocalMallIcon style={{ color: "white" }} />
                </span>)
        }
    }


    return (
        <div className={classes.cardCont} >
            <div className={isStart ? classes.tackerPointContBottomHalf : isEnd ? classes.tackerPointContTopHalf : classes.tackerPointContFull} >
                <div className="lineSty" >
                </div>
                {rendarIcont(eventData)}
            </div>

            <div className={classes.eventDetailsContPadding} >
                <div className={classes.eventDetailsCont} >
                    <BiSolidLeftArrow className={classes.arrorIcon} />
                    {rendarEventContent(eventData)}
                </div>
            </div>
        </div>
    );
}
