import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles"
import { Button, TextField, InputAdornment, ClickAwayListener } from "@material-ui/core"
import { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RiToolsFill } from "react-icons/ri";
import { TbCarCrane } from "react-icons/tb";
import { FaLaptopCode } from "react-icons/fa";
import { RiGroupLine } from "react-icons/ri";
import { BiRupee } from "react-icons/bi";
import AddIcon from '@material-ui/icons/Add';
import TuneIcon from '@material-ui/icons/Tune';
import SearchIcon from '@material-ui/icons/Search';
import AssetAddDrawer from "../Drawers/Asset.Add.Drawer";
import { getAllAggregateValuesForAssetHome, getAllAssetAssignTo, getAllAssetsApiCall, getAllCategories } from "../api.call";
import Pagination from "@material-ui/lab/Pagination";
import AddEventDrawer from "../Drawers/Add.Event.Drawer";
import { useDebounce } from "react-use";
import Autocomplete from "../../styled/generic/Autocomplete";
import { Avatar } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import AssetTableSkeleton from "../../styled/skeletons/Asset.Table.Skeleton";
import { ASSET_OPTIONS } from '../../../helpers/constants';
import emptyfolder from "../../../Assets/FileIcon/emptyfolder.png";
import SimplePieGraph from "../../styled/CommonComponents/Simple.PieGraph";
import SimpleBarChart from "../../styled/CommonComponents/Simple.BarChart";

const useStyles = makeStyles((theme) => ({
    totalsCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    totalBox: {
        width: "18%",
        padding: "20px 10px",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px"
    },
    amountLableCont: {
        "& p": {
            fontSize: "12px",
            fontWeight: "400",
            color: "gray",
            marginBottom: "-2px"
        },
        "& h3": {
            fontSize: "23px",
            fontWeight: "800"
        },
    },
    iconCont: {
        width: "30px",
        height: "30px",
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,
        color: "white",
        display: "flex",
        alignItems: "center",
        fontSize: "20px",
        justifyContent: "center"
    },
    chartsCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "20px"
    },
    paiChartCont: {
        width: "49%",
        height: "250px",
        borderRadius: "5px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px"
    },
    barChartCont: {
        width: "49%",
        height: "250px",
        borderRadius: "5px",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px"
    },
    allAssetsCont: {
        width: "100%",
        maxHeight: "100vh",
        overflow: "hidden",
    },
    assetSearchBar: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        margin: "20px 0px",
        justifyContent: "space-between",
        "& h3": {
            fontSize: "18px",
            fontWeight: "600"
        }
    },
    searchBtnCont: {
        display: "flex",
        alignItems: "center",
        position: "relative",
    },
    searchFiled: {
        width: "400px",
        marginRight: "10px"
    },
    assetCardsCont: {
        width: "100%",
        maxHeight: "calc(100vh - 250px)",
        overflowX: "hidden",
        overflowY: "auto",
        display: "flex",
        flexWrap: "wrap",
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderTop: "1px solid #d2d1d1",
    },
    cardPaddingCont: {
        boxSizing: "border-box",
        width: "25%",
        padding: "10px"
    },
    assetCard: {
        width: "100%",
        borderRadius: "5px",
        padding: "15px",
        boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px"
    },
    lableValue: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        padding: "5px 0px",
        "& h3": {
            width: "110px",
            minWidth: "110px",
            fontSize: "15px",
            fontWeight: "500",
            color: "gray",
            textAlign: "left"
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "500",
            color: "black",
            textAlign: "left",
            padding: "1px 5px",
            borderRadius: "3px"
        },
    },
    cardBtnCont: {
        width: "100%",
        marginTop: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiButton-root": {
            width: "45%",
            borderRadius: "0px"
        }
    },
    avatarGroupSty: {
        "& .MuiAvatar-root": {
            width: "30px",
            height: "30px",
        },
    },
    filterDrawerShow: {
        width: "calc(100% + 100px)",
        height: "300px",
        overflow: "hidden",
        position: "absolute",
        top: "50px",
        zIndex: "10",
        left: "-50px",
        backgroundColor: "white",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        transition: "all 0.3s ease-in-out",

    },
    filterDrawerHide: {
        width: "calc(100% + 100px)",
        height: "0px",
        overflow: "hidden",
        position: "absolute",
        top: "50px",
        zIndex: "10",
        left: "-50px",
        backgroundColor: "white",
        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
        transition: "all 0.3s ease-in-out"
    },
    filterInputsCont: {
        width: "100%",
        height: "250px",
        overflowY: "auto",
        display: "flex",
        alignItems: "flex-start",
        flexWrap: "wrap",
        padding: "10px",
    },
    filterBottomBtnCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 10px 10px"
    },
    filterInputHalfSingleCont: {
        width: "50%",
        minWidth: "50%",
        padding: "5px",
    },
    filterAutocompleteHalfSingleCont: {
        width: "50%",
        minWidth: "50%",
        padding: "5px",
        "& .MuiInputBase-root": {
            padding: "4px !important"
        }
    },
    filterInputFullSingleCont: {
        width: "100%",
        minWidth: "100%",
        padding: "5px",
        "& .MuiInputBase-root": {
            padding: "4px !important"
        }
    },
    itemCont: {
        width: "100%",
        padding: "5px 0px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        "& h3": {
            fontSize: "15px",
            fontWeight: "500"
        },
        "& p": {
            fontSize: "12px",
            fontWeight: "400",
            color: "gray"
        },
        "& h4": {
            backgroundColor: "gray",
            color: "white",
            fontSize: "10px",
            fontWeight: "350",
            padding: "0px 5px",
            borderRadius: "3px"
        }
    },
    emptySty: {
        width: "100%",
        padding: "20px 0px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        "& img": {
            width: "250px",
            height: "auto",
        },
        "& p": {
            fontSize: "12px",
            fontWeight: "300",
            textAlign: "center",
        },
    },
}));

const Dashboard = ({ profileParentData, profileId, walletData }) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);

    const [searchObject, setSearchObject] = useState({
        assetSearchStr: "",
        filterModel: "",
        filterCondition: null,
        filterCategory: null,
        filterType: null,
        filterLocation: null,
        filterStatus: null,
        filterAssignToLocation: [],
        filterAssignToIndividual: []
    });
    const [createAssetDawer, setCreateAssetDrawer] = useState(false);
    const [openAddEventDrawer, setOpenAddEventDrawer] = useState(false);
    const [loader, setLoader] = useState(true)
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(20)
    const [totalPages, setTotalPages] = useState(0)
    const [assets, setAssets] = useState([])
    const [changeState, setChangeState] = useState(false)
    const [showFilter, setShowFilter] = useState(false)
    const [selectedAsset, setSelectedAsset] = useState([])
    const [filterCategoriesOption, setFilterCategoriesOption] = useState([])
    const [filterAssignToUsers, setFilterAssignToUsers] = useState([])
    const [filterAssignToOrgProjects, setFilterAssignToOrgProjects] = useState([])
    const [assetAggregateValues, setAssetAggregateValues] = useState(null)
    const [pieChartArr, setPieChartArr] = useState([])
    const [barChartArr, setBarChartArr] = useState([])

    const returnStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case "unassigned":
                return "#D3D3D3";
            case "moved":
                return "#0000FF";
            case "assigned":
                return "#00FF00";
            case "repair":
                return "#FFA500";
            case "lost":
                return "#FF0000";
            case "expired":
                return "#808080";
            case "sold":
                return "#800080";
            default:
                return "white";
        }
    };

    const getAggregatedValues = async () => {
        await getAllAggregateValuesForAssetHome({ profileId: profileId })
            .then((data) => {
                if (data && data.length > 0) {
                    setAssetAggregateValues(data[0])
                    setPieChartArr([
                        {
                            name: "Unassigned",
                            value: data[0]?.totalNumberOfUnassignedOfferings,
                            color: returnStatusColor("unassigned")
                        },
                        {
                            name: "Moved",
                            value: data[0]?.totalNumberOfMovedOfferings,
                            color: returnStatusColor("moved")
                        },
                        {
                            name: "Assigned",
                            value: data[0]?.totalNumberOfAssignedOfferings,
                            color: returnStatusColor("assigned")
                        },
                        {
                            name: "Repair",
                            value: data[0]?.totalNumberOfRepairOfferings,
                            color: returnStatusColor("repair")
                        },
                        {
                            name: "Lost",
                            value: data[0]?.totalNumberOfLostOfferings,
                            color: returnStatusColor("lost")
                        },
                        {
                            name: "Expired",
                            value: data[0]?.totalNumberOfExpiredOfferings,
                            color: returnStatusColor("expired")
                        },
                        {
                            name: "Sold",
                            value: data[0]?.totalNumberOfSoldOfferings,
                            color: returnStatusColor("sold")
                        },
                    ])

                    setBarChartArr([
                        {
                            name: "Office",
                            value: data[0]?.totalCostOfOfficeOffering,
                            color: "#D3D3D3"
                        },
                        {
                            name: "Site",
                            value: data[0]?.totalCostOfConstructionSiteOffering,
                            color: "#0000FF"
                        },
                        {
                            name: "Warehouse",
                            value: data[0]?.totalCostOfWarehousMainOfficeOffering,
                            color: "#00FF00"
                        },
                        {
                            name: "Third Party",
                            value: data[0]?.totalCostOfThirdPartyOffering,
                            color: "#FFA500"
                        },
                        {
                            name: "Unknown",
                            value: data[0]?.totalCostOfUnknownOffering,
                            color: "#FF0000"
                        },
                    ])
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (profileId) {
            getAggregatedValues()
        }
    }, [profileId])

    const getAllAssignFiltered = async () => {
        await getAllAssetAssignTo({ profileId: profileParentData?._id })
            .then((data) => {
                let locAsssignUsers = [];
                let locAsssignProjectAndOrgs = [];
                data && data.map((s) => {
                    if (s?.parentModelName === " User") {
                        locAsssignUsers.push(s)
                    } else {
                        locAsssignProjectAndOrgs.push(s)
                    }
                })
                setFilterAssignToUsers(locAsssignUsers)
                setFilterAssignToOrgProjects(locAsssignProjectAndOrgs)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        if (profileParentData?._id) {
            getAllAssignFiltered();
        }
    }, [profileParentData?._id])

    const getFilterableCategories = async () => {
        await getAllCategories({ profileId: profileParentData?._id })
            .then((data) => {
                setFilterCategoriesOption(data)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        if (profileParentData?._id) {
            getFilterableCategories()
        }
    }, [profileParentData?._id])

    const getAllAssets = async () => {
        if (profileParentData?._id) {
            setLoader(true)
            await getAllAssetsApiCall({
                profileId: profileParentData?._id,
                searchObject: searchObject,
                limit: pageLimit,
                curPage: curPage - 1
            })
                .then((data) => {
                    let locAssets = [];
                    data?.allAssets && data.allAssets.map((asset) => {
                        const arr1 = asset?.assignToTeam || [];
                        const arr2 = asset?.assignToIndividual || [];
                        locAssets.push({
                            ...asset,
                            assignTo: [...arr1, ...arr2]
                        })
                    })
                    setAssets(locAssets)
                    let locTotalPage = Math.ceil(data?.allAssetsCount / pageLimit);
                    setTotalPages(locTotalPage)
                    setLoader(false)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    useDebounce(() => {
        getAllAssets()
    }, 1000, [curPage, searchObject, changeState, profileParentData?._id])

    useEffect(() => {
        setCurPage(1)
        setChangeState(!changeState)
    }, [searchObject])

    const afterAssetsAdd = (allNewAssets, isEdit) => {
        if (isEdit) {
            setChangeState(!changeState)
        } else {
            setCurPage(1)
            setChangeState(!changeState)
        }
        getAggregatedValues()
        getAllAssignFiltered()
        getFilterableCategories()
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (<>
        <div className={classes.totalsCont} >
            <div className={classes.totalBox} >
                <div className={classes.amountLableCont} >
                    <p>Total Cost</p>
                    <h3>₹{numberWithCommas((assetAggregateValues?.totalCost || 0).toFixed(2))}</h3>
                </div>

                <div className={classes.iconCont} >
                    <BiRupee />
                </div>
            </div>
            <div className={classes.totalBox} >
                <div className={classes.amountLableCont} >
                    <p>Total Asset</p>
                    <h3>₹{numberWithCommas((assetAggregateValues?.totalAsset || 0).toFixed(2))}</h3>
                </div>

                <div className={classes.iconCont} >
                    <RiToolsFill />
                </div>
            </div>
            <div className={classes.totalBox} >
                <div className={classes.amountLableCont} >
                    <p>Hardware</p>
                    <h3>{numberWithCommas((assetAggregateValues?.totalNumberOfHardware || 0))}</h3>
                </div>

                <div className={classes.iconCont} >
                    <TbCarCrane />
                </div>
            </div>
            <div className={classes.totalBox} >
                <div className={classes.amountLableCont} >
                    <p>Software</p>
                    <h3>{numberWithCommas((assetAggregateValues?.totalNumberOfSoftware || 0))}</h3>
                </div>

                <div className={classes.iconCont} >
                    <FaLaptopCode />
                </div>
            </div>
            <div className={classes.totalBox} >
                <div className={classes.amountLableCont} >
                    <p>Haevy Equipment</p>
                    <h3>{numberWithCommas((assetAggregateValues?.totalNumberOfHaevyEquipment || 0))}</h3>
                </div>

                <div className={classes.iconCont} >
                    <RiGroupLine />
                </div>
            </div>
        </div>
        <div className={classes.chartsCont} >
            <div className={classes.paiChartCont} >
                <SimplePieGraph
                    Data={pieChartArr}
                    heightNum={"250px"}
                    chartRadius={85}
                />
            </div>
            <div className={classes.barChartCont} >
                <SimpleBarChart
                    chartData={barChartArr}
                    width={"100%"}
                    height={250}
                />
            </div>
        </div>
        <div className={classes.allAssetsCont} >
            <div className={classes.assetSearchBar} >
                <h3>Assets</h3>

                <ClickAwayListener
                    onClickAway={() => {
                        setShowFilter(false)
                    }}
                >
                    <div className={classes.searchBtnCont} >
                        <TextField
                            id="outlined-basic"
                            variant="outlined"
                            size="small"
                            placeholder={"Enter asset name"}
                            autoComplete='off'
                            value={searchObject?.assetSearchStr}
                            className={classes.searchFiled}
                            onChange={(e) => {
                                setSearchObject({
                                    ...searchObject,
                                    assetSearchStr: e.target.value
                                })
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<TuneIcon />}
                            onClick={() => { setShowFilter(!showFilter) }}
                        >
                            Filter
                        </Button>

                        <div className={showFilter ? classes.filterDrawerShow : classes.filterDrawerHide} >
                            <div className={classes.filterInputsCont} >
                                <div className={classes.filterInputHalfSingleCont} >
                                    <p>Model</p>
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        size="small"
                                        autoComplete='off'
                                        placeholder="Enter model"
                                        value={searchObject?.filterModel}
                                        fullWidth
                                        onChange={(e) => {
                                            setSearchObject({
                                                ...searchObject,
                                                filterModel: e.target.value
                                            })
                                        }}
                                    />
                                </div>
                                <div className={classes.filterAutocompleteHalfSingleCont} >
                                    <p>Condition</p>
                                    <Autocomplete
                                        multiple
                                        options={ASSET_OPTIONS.condition}
                                        value={searchObject?.filterCondition || []}
                                        onChange={(event, value) => {
                                            setSearchObject({
                                                ...searchObject,
                                                filterCondition: value
                                            })
                                        }}
                                        fullWidth
                                        size="small"
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                size="small"
                                                variant="outlined"
                                                {...params}
                                                placeholder={"Select condition"}
                                            />
                                        )}
                                    />
                                </div>
                                <div className={classes.filterAutocompleteHalfSingleCont} >
                                    <p>Category</p>
                                    <Autocomplete
                                        multiple
                                        options={filterCategoriesOption}
                                        value={searchObject?.filterCategory || []}
                                        onChange={(event, value) => {
                                            setSearchObject({
                                                ...searchObject,
                                                filterCategory: value
                                            })
                                        }}
                                        fullWidth
                                        size="small"
                                        getOptionLabel={(option) => {
                                            if (option?.code && option?.name) {
                                                return `${option?.code}|${option?.name}`
                                            } else if (option?.name) {
                                                return `${option?.name}`
                                            } else {
                                                return ""
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                size="small"
                                                variant="outlined"
                                                {...params}
                                                placeholder={"Select category"}
                                            />
                                        )}
                                    />
                                </div>
                                <div className={classes.filterAutocompleteHalfSingleCont} >
                                    <p>Type</p>
                                    <Autocomplete
                                        multiple
                                        options={ASSET_OPTIONS?.type}
                                        value={searchObject?.filterType || []}
                                        onChange={(event, value) => {
                                            setSearchObject({
                                                ...searchObject,
                                                filterType: value
                                            })
                                        }}
                                        fullWidth
                                        size="small"
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                size="small"
                                                variant="outlined"
                                                {...params}
                                                placeholder={"Select type"}
                                            />
                                        )}
                                    />
                                </div>
                                <div className={classes.filterAutocompleteHalfSingleCont} >
                                    <p>Location</p>
                                    <Autocomplete
                                        multiple
                                        options={ASSET_OPTIONS?.location}
                                        value={searchObject?.filterLocation || []}
                                        onChange={(event, value) => {
                                            setSearchObject({
                                                ...searchObject,
                                                filterLocation: value
                                            })
                                        }}
                                        fullWidth
                                        size="small"
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                {...params}
                                                placeholder={"Select location"}
                                            />
                                        )}
                                    />
                                </div>
                                <div className={classes.filterAutocompleteHalfSingleCont} >
                                    <p>Status</p>
                                    <Autocomplete
                                        multiple
                                        options={ASSET_OPTIONS?.status}
                                        value={searchObject?.filterStatus || []}
                                        onChange={(event, value) => {
                                            setSearchObject({
                                                ...searchObject,
                                                filterStatus: value
                                            })
                                        }}
                                        fullWidth
                                        size="small"
                                        getOptionLabel={(option) => option}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                {...params}
                                                placeholder={"Select Status"}
                                            />
                                        )}
                                    />
                                </div>
                                <div className={classes.filterInputFullSingleCont} >
                                    <p>Assign To Location</p>
                                    <Autocomplete
                                        multiple
                                        options={filterAssignToOrgProjects}
                                        value={searchObject?.filterAssignToLocation || []}
                                        onChange={(event, value) => {
                                            setSearchObject({
                                                ...searchObject,
                                                filterAssignToLocation: value
                                            })
                                        }}
                                        fullWidth
                                        size="small"
                                        getOptionLabel={(option) => option?.parent?.displayName}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                {...params}
                                                placeholder={"Select Status"}
                                            />
                                        )}
                                    />
                                </div>
                                <div className={classes.filterInputFullSingleCont} >
                                    <p>Assign To Individual</p>
                                    <Autocomplete
                                        multiple
                                        options={filterAssignToUsers}
                                        value={searchObject?.filterAssignToIndividual || []}
                                        onChange={(event, value) => {
                                            setSearchObject({
                                                ...searchObject,
                                                filterAssignToIndividual: value
                                            })
                                        }}
                                        fullWidth
                                        size="small"
                                        getOptionLabel={(option) => option?.parent?.displayName}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                {...params}
                                                placeholder={"Select Status"}
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className={classes.filterBottomBtnCont} >
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    size="small"
                                    onClick={() => {
                                        setSearchObject({
                                            assetSearchStr: "",
                                            filterModel: "",
                                            filterCondition: null,
                                            filterCategory: null,
                                            filterType: null,
                                            filterLocation: null,
                                            filterStatus: null,
                                            filterAssignToLocation: null,
                                            filterAssignToIndividual: null
                                        })
                                    }}
                                >
                                    Reset Filter
                                </Button>


                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={() => { setShowFilter(false); }}
                                >
                                    close
                                </Button>
                            </div>
                        </div>
                    </div>
                </ClickAwayListener>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => { setCreateAssetDrawer(true) }}
                >
                    Add Asset
                </Button>
                <AssetAddDrawer
                    createAssetDawer={createAssetDawer}
                    setCreateAssetDrawer={setCreateAssetDrawer}
                    profileParentData={profileParentData}
                    afterAssetsAdd={afterAssetsAdd}
                    walletData={walletData}
                />
            </div>
            {loader ? <AssetTableSkeleton /> : (<>
                {assets && assets.length > 0 ? (<>
                    <div className={classes.assetCardsCont} >
                        {assets && assets.map((asset) => (<div className={classes.cardPaddingCont} >
                            <div className={classes.assetCard} >
                                <div className={classes.lableValue} >
                                    <h3>Name</h3>
                                    <p>{asset?.name}</p>
                                </div>
                                <div className={classes.lableValue} >
                                    <h3>Model</h3>
                                    <p>{asset?.assetModel}</p>
                                </div>
                                <div className={classes.lableValue} >
                                    <h3>Condition</h3>
                                    <p>{asset?.condition}</p>
                                </div>
                                <div className={classes.lableValue} >
                                    <h3>Category</h3>
                                    <p>{asset?.category?.name}</p>
                                </div>
                                <div className={classes.lableValue} >
                                    <h3>Type</h3>
                                    <p>{asset?.type}</p>
                                </div>
                                <div className={classes.lableValue} >
                                    <h3>Location</h3>
                                    <p>{asset?.location}</p>
                                </div>
                                <div className={classes.lableValue} >
                                    <h3>Purchase Cost</h3>
                                    <p>{asset?.purchasePrice}</p>
                                </div>
                                <div className={classes.lableValue} >
                                    <h3>Status</h3>
                                    <p style={{ backgroundColor: returnStatusColor(asset?.assetStatus) }} >{asset?.assetStatus}</p>
                                </div>
                                <div className={classes.lableValue} >
                                    <h3>Assign To</h3>
                                    <AvatarGroup max={4} className={classes.avatarGroupSty} >
                                        {asset?.assignTo && asset?.assignTo.map((participant, idx) => (
                                            <Avatar
                                                key={idx}
                                                title={participant?.parent?.displayName}
                                                alt={participant?.parent?.displayName}
                                                src={participant?.parent?.displayPicture?.url}
                                            />
                                        ))}
                                    </AvatarGroup>
                                </div>

                                <div className={classes.cardBtnCont} >
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        onClick={() => {
                                            history.push(`/asset/${asset?._id}`)
                                        }}
                                    >
                                        View Details
                                    </Button>

                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        startIcon={<AddIcon />}
                                        onClick={() => {
                                            setSelectedAsset([{
                                                value: asset?._id,
                                                label: asset?.name,
                                                data: {
                                                    ...asset
                                                }
                                            }])
                                            setOpenAddEventDrawer(true)
                                        }}
                                    >
                                        Add Event
                                    </Button>
                                </div>
                            </div>
                        </div>))}

                        <AddEventDrawer
                            openAddEventDrawer={openAddEventDrawer}
                            setOpenAddEventDrawer={setOpenAddEventDrawer}
                            profileParentData={profileParentData}
                            profileId={profileId}
                            afterAssetsAdd={afterAssetsAdd}
                            selectedAsset={selectedAsset}
                        />
                    </div>
                    <div className={classes.paginationCont} >
                        <Pagination
                            count={totalPages}
                            page={curPage}
                            color="primary"
                            siblingCount={0}
                            onChange={(event, value) => { setCurPage(value) }}
                        />
                    </div>
                </>) : (<div className={classes.emptySty}>
                    <img src={emptyfolder} />
                    <p>No result is available!</p>
                </div>)}
            </>)}
        </div>
    </>);
};

export default Dashboard;