import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { TextField, Paper, Button, Avatar } from "@material-ui/core";
import NormalDialog from "../styled/CommonComponents/NormalDialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddIcon from "@material-ui/icons/Add";
import emptyIcon from "../../Assets/EmptyInboxOutline.svg";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import InputAdornment from "@material-ui/core/InputAdornment";
import PalForm from "../AddMember/newPalForm";
import PalFormDrawer from "../AddMember/newPalFormDrawer";

const useStyles = makeStyles((theme) => ({
  dialogBody: {
    width: "450px",
    [theme.breakpoints.down("xs")]: {
      width: "75vw",
    },
  },
  inputPaper: {
    width: "100%",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "relative",
    "& .MuiButton-label": {
      textTransform: "capitalize",
    },
  },
  roleAutoSty: {
    width: "130px",
    "& .MuiAutocomplete-inputRoot .MuiAutocomplete-input": {
      minWidth: "85px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90px",
    },
  },
  textFieldSty: {
    width: "calc(100% - 100px)",
    position: "absolute",
    top: "0px",
    right: "0px",
    zIndex: "9",
    backgroundColor: "white",
    [theme.breakpoints.down("xs")]: {
      width: "calc(100% - 60px)",
    },
  },
  noBorder: {
    border: "none",
  },
  resultBody: {
    width: "100%",
    height: "250px",
    overflowY: "auto",
    margin: "20px 0px 10px",
  },
  btnCont: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
  itemCont: {
    width: "100%",
    padding: "7px 10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    cursor: "pointer",
    "& h3": {
      fontSize: "15px",
      fontWeight: "500",
    },
    "& p": {
      fontSize: "12px",
      fontWeight: "400",
      color: "gray",
    },
    "& h4": {
      backgroundColor: "gray",
      color: "white",
      fontSize: "10px",
      fontWeight: "350",
      padding: "0px 5px",
      borderRadius: "3px",
    },
    "&:hover": {
      backgroundColor: "#edecec",
    },
  },
  emptyCont: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& img": {
      width: "200px",
      height: "auto",
    },
    "& p": {
      fontSize: "14px",
      fontWeight: "400",
      color: "gray",
    },
  },
}));

export default function ContactsDialog({
  openContactDialog,
  setOpenContactDialog,
  title,
  entities,
  text,
  setText,
  roles,
  selectedRole,
  submitToAdd,
  setSelectedRole,
  setEntities,
  selectedEntity,
  setSelectedEntity,
  entityLoader,
  relationCreateLoader,
}) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [openNewPal, setOpenNewPal] = useState(false);
  const [loading, setLoading] = useState(false);

  const newOnSelect = async (newPalData) => {
    setOpenNewPal(false);

    if (newPalData) {
      submitToAdd(newPalData?.profile);
    }
  };

  return (
    <NormalDialog
      openDialog={openContactDialog}
      handleCloseShare={() => {
        setOpenContactDialog(false);
      }}
      pageTitle={title}
      loading={relationCreateLoader}
      content={
        <>
          <div className={classes.dialogBody}>
            <Paper className={classes.inputPaper}>
              <Autocomplete
                id="free-solo-with-text-demo"
                selectOnFocus
                freeSolo
                clearOnBlur
                handleHomeEndKeys
                value={selectedRole}
                onChange={(event, newValue) => {
                  setSelectedRole(newValue);
                  setEntities([]);
                }}
                options={roles}
                getOptionLabel={(option) => {
                  return option;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Role"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      classes: { notchedOutline: classes.noBorder },
                    }}
                  />
                )}
                size="small"
                className={classes.roleAutoSty}
              />
              <TextField
                placeholder="Search user/project/organization"
                variant="outlined"
                size="small"
                className={classes.textFieldSty}
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
                InputProps={{
                  classes: { notchedOutline: classes.noBorder },
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        startIcon={<AddIcon />}
                        onClick={() => {
                          setOpenNewPal(true);
                        }}
                      >
                        Add Pal
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />

              <PalFormDrawer
                open={openNewPal}
                setOpen={setOpenNewPal}
                text={text}
                onSelect={newOnSelect}
                setLoading={setLoading}
                relationType={null}
              />

              {/* <NormalDialog
                openDialog={openNewPal}
                handleCloseShare={() => {
                  setOpenNewPal(false);
                }}
                pageTitle={`Add New Contact(Pal)`}
                loading={loading}
                content={
                  <>
                    <div style={{ height: "5px" }}>
                      {loading && <LinearProgress />}
                    </div>
                    <PalForm
                      text={text}
                      onSelect={newOnSelect}
                      setLoading={setLoading}
                    />
                  </>
                }
              /> */}
            </Paper>
            <div className={classes.resultBody}>
              {entityLoader ? (
                <div className={classes.emptyCont}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  {entities && entities.length > 0 ? (
                    entities.map((option, i) => {
                      if (option) {
                        return (
                          <div
                            key={i}
                            className={classes.itemCont}
                            onClick={() => {
                              setSelectedEntity(option);
                            }}
                            style={
                              selectedEntity?._id === option?._id
                                ? { backgroundColor: "#d0eeff" }
                                : { backgroundColor: "white" }
                            }
                          >
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Avatar
                                src={
                                  option?.parent?.displayPicture?.thumbUrl
                                    ? option?.parent?.displayPicture?.thumbUrl
                                    : option?.parent?.displayPicture?.url
                                }
                                alt={option?.parent?.displayName}
                                style={{ width: "35px", height: "35px" }}
                              />
                              <div style={{ marginLeft: "7px" }}>
                                <h3>{option?.parent?.displayName}</h3>
                                <p>@{option?.parent?.username}</p>
                              </div>
                            </div>
                            <h4>
                              {option?.parent?.model == "Pal"
                                ? "Connection"
                                : option?.parentModelName}
                            </h4>
                          </div>
                        );
                      }
                    })
                  ) : (
                    <div className={classes.emptyCont}>
                      <img src={emptyIcon} />
                      <p>No contact is available.</p>
                    </div>
                  )}
                </>
              )}
            </div>
            <div style={{ width: "100%", height: "5px" }}>
              {relationCreateLoader && <LinearProgress />}
            </div>
            <div className={classes.btnCont}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                disabled={selectedEntity ? false : true}
                onClick={() => {
                  submitToAdd(selectedEntity);
                }}
              >
                Add
              </Button>
            </div>
          </div>
        </>
      }
    />
  );
}
