import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import NormalDrawer from "../styled/CommonComponents/NormalDrawer";
import * as yup from "yup";
import { useParams, useHistory } from 'react-router-dom';
import { TextField, Checkbox } from '@material-ui/core';
import emptyPage from "../../Assets/EmptyPageSVG.svg"
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextFieldNumberFormated from '../styled/CommonComponents/TextFieldNumberFormated';
import { createOffering, getCategoriesByLibrary, getWalletAndChartAccountsByProfile, updateOfferingAndRelation } from './Api.call';
import { AREA_UNIT_OPTIONS } from "../../helpers/constants"
import CategoryAutocomplete from './CategoryAutocomplete';
import HierarchicalAutocomplete from '../styled/CommonComponents/Hierarchical.Autocomplete';

const validationSchema1 = yup.object().shape({
    name: yup.string().min(2).max(250).required("Name is required"),
    type: yup.string().required("Type is required")
});

const validationSchema2 = yup.object().shape({
    name: yup.string().min(2).max(250).required("Name is required"),
    type: yup.string().required("Type is required"),
    sellingPrice: yup.number().required("Selling price is required"),
    sellingUnit: yup.string().required("Selling unit is required"),
});

const validationSchema3 = yup.object().shape({
    name: yup.string().min(2).max(250).required("Name is required"),
    type: yup.string().required("Type is required"),
    Purchasing: yup.number().required("Purchasing price is required"),
    PurchasUnit: yup.string().required("Purchas unit is required"),
});

const validationSchema4 = yup.object().shape({
    name: yup.string().min(2).max(250).required("Name is required"),
    type: yup.string().required("Type is required"),
    sellingPrice: yup.number().required("Selling price is required"),
    sellingUnit: yup.string().required("Selling unit is required"),
    Purchasing: yup.number().required("Purchasing price is required"),
    PurchasUnit: yup.string().required("Purchas unit is required"),
});

const unitsArray = AREA_UNIT_OPTIONS;

const useStyles = makeStyles((theme) => ({
    mainDialogCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "& .MuiButton-label": {
            textTransform: "capitalize",
            fontSize: "14px"
        }
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 55px)",
        overflowX: "hidden",
        overflowY: "auto",
        padding: "15px"
    },
    bottomAct: {
        width: "100%",
        height: "50px",
        padding: "0px 7px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        borderTop: "1px solid #ececec"
    },
    duleInputCont: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginBottom: "15px"
    },
    taxAndTaxType: {
        width: "48%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    purchaseSty: {
        width: "100%",
        borderTop: "1px solid #ececec",
        marginBottom: "15px",
        "& h3": {
            fontSize: "16px",
            fontWeight: "500",
            marginTop: "10px"
        },
        "& p": {
            fontSize: "14px",
            fontWeight: "400",
            marginLeft: "7px"
        },
        "& div": {
            width: "100%",
            display: "flex",
            alignItems: "center"
        },
        "& .MuiSvgIcon-root": {
            fontSize: "20px"
        }
    },
    taxTypeCont: {
        display: "flex",
        alignItems: "center",
        "& .MuiButton-containedSizeSmall": {
            padding: "7px 0px",
            minWidth: "41px"
        },
        "& .MuiButton-outlinedSizeSmall": {
            padding: "7px 0px",
            minWidth: "41px"
        }
    },
    halfWidth: {
        width: "48%"
    },
    validationSty: {
        fontSize: "12px",
        color: "red",
        fontWeight: "400",
        marginTop: "5px"
    },
    emptyCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "0px 30px",
        "& img": {
            width: "200px",
            height: "auto"
        },
        "& p": {
            fontSize: "14px",
            color: "gray",
            textAlign: "center",
            margin: "10px 0px 25px"
        },
        "& a": {
            color: theme.palette.primary.main,
            cursor: "pointer",
        }
    },
}));
export default function OfferingCreateDrawer({
    createOfferingDawer, setCreateOfferingDrawer,
    profileId, library, afterOfferingCreateOrEdit,
    selectedCategoryForOffering = null,
    purchase = false, selling = true,
    curEditDeleteOffering = null
}) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const { user } = useSelector((state) => state.auth);

    //replaced
    const [incomeChartAccounts, setIncomeChartAccounts] = useState([])
    const [expenseChartAccounts, setExpenseChartAccounts] = useState([])
    const [wallet, setWallet] = useState(null)
    const [loader, setLoader] = useState(false)

    const [name, setName] = useState("")
    const [code, setCode] = useState("")
    const [category, setCategory] = useState([])
    const [rate, setRate] = useState()
    const [unit, setUnit] = useState()
    const [tax, setTax] = useState()
    const [taxType, setTaxType] = useState("%")
    const [model, setModel] = useState()
    const [incomeAcc, setIncomeAcc] = useState()
    const [incomeDesc, setIncomeDesc] = useState("")
    const [isPurchase, setIsPurchase] = useState(purchase)
    const [isSelling, setIsSelling] = useState(selling)
    const [cost, setCost] = useState()
    const [costUnit, setCostUnit] = useState()
    const [expenseAcc, setExpenseAcc] = useState()
    const [expenseDesc, setExpenseDesc] = useState("")
    const [validationObj, setValidationObj] = useState({})

    useEffect(() => {
        if (profileId) {
            getWalletAndIncomeExpenseChartAccount();
        }
    }, [profileId])

    const getWalletAndIncomeExpenseChartAccount = async () => {
        await getWalletAndChartAccountsByProfile({
            profileId: profileId
        })
            .then((data) => {
                setIncomeChartAccounts(data?.incomeChartAccount || [])
                setExpenseChartAccounts(data?.expenseChartAccount || [])
                setWallet(data?.curWallet || null)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        setValidationObj({})
    }, [name, model, rate, unit, cost, costUnit])

    const setInitialState = () => {
        setLoader(false)
        setIncomeAcc(incomeChartAccounts[0])
        setExpenseAcc()
        setCategory(null)
        setUnit(unitsArray[0])
        setModel("Product")
        setCostUnit(unitsArray[0])
        setName("")
        setCode("")
        setRate()
        setTax()
        setTaxType("%")
        setIncomeDesc("")
        setIsPurchase(false)
        setCost()
        setExpenseDesc("")
    }

    useEffect(() => {
        if (createOfferingDawer === false && curEditDeleteOffering?._id) {
            setInitialState();
        }
    }, [createOfferingDawer])

    useEffect(() => {
        if (curEditDeleteOffering?._id) {
            setName(curEditDeleteOffering?.name)
            setCode(curEditDeleteOffering?.code)
            setRate(curEditDeleteOffering?.price)
            setUnit(curEditDeleteOffering?.unit)
            setTax(curEditDeleteOffering?.tax)
            setTaxType(curEditDeleteOffering?.taxType)
            setModel(curEditDeleteOffering?.model)
            setExpenseDesc(curEditDeleteOffering?.expenseDescription)
            setIncomeDesc(curEditDeleteOffering?.incomeDescription)
            setIsPurchase(curEditDeleteOffering?.doPurchase)
            setCost(curEditDeleteOffering?.purchasePrice)
            setCostUnit(curEditDeleteOffering?.purchaseUnit)
            setCategory(curEditDeleteOffering?.category)
            setIncomeAcc(curEditDeleteOffering?.incomeChartAccount)
            setExpenseAcc(curEditDeleteOffering?.expenseChartAccount)
        }
    }, [curEditDeleteOffering, createOfferingDawer])

    useEffect(() => {
        if (selectedCategoryForOffering) {
            setCategory(selectedCategoryForOffering)
        }
    }, [selectedCategoryForOffering, createOfferingDawer])

    const onSubmit = async (e) => {
        e.preventDefault();
        let curSchema = null;
        if (isPurchase && isSelling) {
            curSchema = validationSchema4;
        } else if (!isPurchase && isSelling) {
            curSchema = validationSchema2;
        } else if (isPurchase && !isSelling) {
            curSchema = validationSchema3;
        } else {
            curSchema = validationSchema1;
        }
        const formData = {
            name,
            type: model,
            sellingPrice: rate,
            sellingUnit: unit,
            Purchasing: cost,
            PurchasUnit: costUnit
        }

        await curSchema.validate(formData, { abortEarly: false })
            .then(async (data) => {
                setLoader(true)
                if (curEditDeleteOffering?._id) {
                    let updateOfferingObj = {
                        _id: curEditDeleteOffering?._id,
                        name: name,
                        code: code,
                        unit: unit,
                        taxType: taxType,
                        tax: tax,
                        incomeDescription: incomeDesc,
                        expenseDescription: expenseDesc,
                        model: model,
                        price: rate,
                        doPurchase: isPurchase,
                        purchasePrice: cost,
                        purchaseUnit: costUnit
                    }
                    if (category?._id == null || category?._id == undefined || category?._id == "") {
                        updateOfferingObj.$unset = { category: 1 };
                    } else {
                        updateOfferingObj.category = category?._id
                    }
                    let updateOfferingRelationObj = {
                        _id: curEditDeleteOffering?.offeringRelation?._id,
                        profile: profileId,
                        shared: [profileId]
                    }
                    let createOfferingRelationObj = {
                        profile: profileId,
                        shared: [profileId]
                    }
                    if (user?._id == null || user?._id == undefined || user?._id == "") {
                        updateOfferingRelationObj.$unset = { ...updateOfferingRelationObj.$unset, user: 1 };
                    } else {
                        updateOfferingRelationObj.user = user?._id;
                        createOfferingRelationObj.user = user?._id;
                    }
                    if (wallet?._id == null || wallet?._id == undefined || wallet?._id == "") {
                        updateOfferingRelationObj.$unset = { ...updateOfferingRelationObj.$unset, wallet: 1 };
                    } else {
                        updateOfferingRelationObj.wallet = wallet?._id;
                        createOfferingRelationObj.wallet = wallet?._id;
                    }
                    if (incomeAcc?._id == null || incomeAcc?._id == undefined || incomeAcc?._id == "") {
                        updateOfferingRelationObj.$unset = { ...updateOfferingRelationObj.$unset, incomeChartAccount: 1 };
                    } else {
                        updateOfferingRelationObj.incomeChartAccount = incomeAcc?._id;
                        createOfferingRelationObj.incomeChartAccount = incomeAcc?._id;
                    }
                    if (expenseAcc?._id == null || expenseAcc?._id == undefined || expenseAcc?._id == "") {
                        updateOfferingRelationObj.$unset = { ...updateOfferingRelationObj.$unset, expenseChartAccount: 1 };
                    } else {
                        updateOfferingRelationObj.expenseChartAccount = expenseAcc?._id;
                        createOfferingRelationObj.expenseChartAccount = expenseAcc?._id;
                    }
                    if (category?._id == null || category?._id == undefined || category?._id == "") {
                        updateOfferingRelationObj.$unset = { ...updateOfferingRelationObj.$unset, category: 1 };
                    } else {
                        updateOfferingRelationObj.category = category?._id;
                        createOfferingRelationObj.category = category?._id;
                    }
                    if (library?._id == null || library?._id == undefined || library?._id == "") {
                        updateOfferingRelationObj.$unset = { ...updateOfferingRelationObj.$unset, library: 1 };
                    } else {
                        updateOfferingRelationObj.library = library?._id;
                        createOfferingRelationObj.library = library?._id;
                    }

                    await updateOfferingAndRelation({
                        offeringObj: updateOfferingObj,
                        updateOfferingRelationObj,
                        createOfferingRelationObj
                    })
                        .then((data) => {
                            afterOfferingCreateOrEdit(data, true)
                            setCreateOfferingDrawer(false)
                            setInitialState()
                            setLoader(false)

                        })
                        .catch((err) => {
                            console.log(err)
                        })
                } else {
                    const createObj = {
                        name,
                        code,
                        category: category?._id,
                        rate,
                        unit,
                        tax,
                        taxType,
                        model,
                        incomeAcc: incomeAcc?._id,
                        incomeDesc,
                        isPurchase,
                        cost,
                        costUnit,
                        expenseAcc: expenseAcc?._id,
                        expenseDesc,
                        library: library?._id,
                        libraryName: library?.name,
                        wallet: wallet?._id,
                        profileId: profileId,
                        createdByProfile: user?.profile,
                        userId: user?._id,
                        isPurchase: isPurchase
                    }
                    await createOffering(createObj)
                        .then((data) => {
                            afterOfferingCreateOrEdit(data, false)
                            setCreateOfferingDrawer(false)
                            setInitialState()
                            setLoader(false)
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                }
            })
            .catch((err) => {
                let errorArr = err?.inner
                let obj = {}
                errorArr.map((err) => {
                    obj[err?.path] = err?.message
                })
                setValidationObj(obj)
            })
    }

    return (
        <NormalDrawer
            openDrawer={createOfferingDawer}
            setOpenDrawer={setCreateOfferingDrawer}
            anchor={"right"}
            title={curEditDeleteOffering?._id ? "Edit Product/Service" : "Create Product/Service"}
            content={<>
                {library?._id ? (<>
                    <form className={classes.mainDialogCont} onSubmit={onSubmit} >
                        <div className={classes.bodyCont} >
                            <div className={classes.duleInputCont} >
                                <div className={classes.halfWidth} >
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        size="small"
                                        label={<>Name<span style={{ color: "red" }}>*</span></>}
                                        placeholder="Enter Name"
                                        autoComplete='off'
                                        value={name}
                                        fullWidth
                                        onChange={(e) => { setName(e.target.value); }}
                                    />
                                    <p className={classes.validationSty} >{validationObj?.name}</p>
                                </div>
                                <TextField
                                    id="outlined-basic"
                                    variant="outlined"
                                    size="small"
                                    label={"Cost Code"}
                                    placeholder="Enter Cost Code"
                                    autoComplete='off'
                                    value={code}
                                    style={{ width: "48%" }}
                                    onChange={(e) => { setCode(e.target.value); }}
                                />
                            </div>
                            <div style={{ width: "100%", marginBottom: "15px" }} >
                                <CategoryAutocomplete
                                    libraryId={library?._id}
                                    selectedCategories={category}
                                    setSelectedCategories={setCategory}
                                    isMultiple={false}
                                    type={null}
                                    profileId={profileId}
                                    isCreateNew={true}
                                    label={<>Category<span style={{ color: "red" }}>*</span></>}
                                    placeholder="Select Category"
                                />
                            </div>
                            <div className={classes.duleInputCont} >
                                <div className={classes.taxAndTaxType} >
                                    <TextField
                                        id="outlined-basic"
                                        variant="outlined"
                                        size="small"
                                        label={"Tax"}
                                        placeholder="Enter tax"
                                        autoComplete='off'
                                        value={tax}
                                        type="number"
                                        style={{ width: "calc(100% - 110px)" }}
                                        onChange={(e) => { setTax(e.target.value); }}
                                    />
                                    <div className={classes.taxTypeCont} >
                                        <Button
                                            variant={taxType === "%" ? "contained" : "outlined"}
                                            size="small"
                                            color="primary"
                                            onClick={() => { setTaxType("%") }}
                                            style={{ marginRight: "10px" }}
                                        >
                                            %
                                        </Button>
                                        <Button
                                            variant={taxType === "₹" ? "contained" : "outlined"}
                                            size="small"
                                            color="primary"
                                            onClick={() => { setTaxType("₹") }}
                                        >
                                            ₹
                                        </Button>
                                    </div>
                                </div>
                                <div className={classes.halfWidth} >
                                    <Autocomplete
                                        id="free-solo-demo"
                                        freeSolo
                                        value={model}
                                        options={["Product", "Service"]}
                                        disableClearable
                                        getOptionLabel={(option) => { return option }}
                                        getOptionSelected={(option) => {
                                            return option === model
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                style={{ margin: "0px" }}
                                                margin="normal"
                                                variant="outlined"
                                                label={<>Type<span style={{ color: "red" }}>*</span></>}
                                                placeholder={"Select Type"}
                                            />
                                        )}
                                        onChange={(event, value) => {
                                            setModel(value)
                                        }}
                                        fullWidth
                                        size="small"
                                    />
                                    <p className={classes.validationSty} >{validationObj?.type}</p>
                                </div>
                            </div>
                            <div className={classes.purchaseSty} >
                                <h3>Selling information</h3>
                                <div>
                                    <Checkbox
                                        checked={isSelling}
                                        onChange={(evt, checked) => {
                                            setIsSelling(checked)
                                        }}
                                        color="primary"
                                    />
                                    <p>I sell this product/service.</p>
                                </div>
                            </div>
                            {isSelling && (<>
                                <div className={classes.duleInputCont} >
                                    <div className={classes.halfWidth} >
                                        <TextFieldNumberFormated
                                            label={<>Sells price/rate<span style={{ color: "red" }}>*</span></>}
                                            value={rate}
                                            size="small"
                                            onChange={(e) => { setRate(e.target.value); }}
                                            variant="outlined"
                                            placeholder="Enter price"
                                            type="number"
                                            fullWidth
                                        />
                                        <p className={classes.validationSty} >{validationObj?.sellingPrice}</p>
                                    </div>
                                    <div className={classes.halfWidth} >
                                        <Autocomplete
                                            id="free-solo-demo"
                                            freeSolo
                                            value={unit}
                                            options={unitsArray}
                                            disableClearable
                                            getOptionLabel={(option) => { return option }}
                                            getOptionSelected={(option) => {
                                                return option === unit
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    style={{ margin: "0px" }}
                                                    margin="normal"
                                                    variant="outlined"
                                                    label={<>Unit<span style={{ color: "red" }}>*</span></>}
                                                    placeholder={"Select unit"}
                                                />
                                            )}
                                            onChange={(event, value) => {
                                                setUnit(value)
                                            }}
                                            fullWidth
                                            size="small"
                                        />
                                        <p className={classes.validationSty} >{validationObj?.sellingUnit}</p>
                                    </div>
                                </div>
                                <div style={{ width: "100%", marginBottom: "15px" }} >
                                    <HierarchicalAutocomplete
                                        lableKey={"name"}
                                        options={incomeChartAccounts}
                                        value={incomeAcc}
                                        rootId={"_id"}
                                        parentId={"parent"}
                                        label={"Income account"}
                                        placeholder="Select Chart Account"
                                        onChange={(e, v) => {
                                            setIncomeAcc(v)
                                        }}
                                    />
                                </div>
                                <TextField
                                    id="outlined-multiline-static"
                                    label={"Description"}
                                    placeholder={"Enter Description"}
                                    value={incomeDesc}
                                    onChange={(e) => { setIncomeDesc(e.target.value) }}
                                    multiline
                                    rows={3}
                                    fullWidth
                                    style={{ marginBottom: "15px" }}
                                    variant="outlined"
                                />
                            </>)}
                            <div className={classes.purchaseSty} >
                                <h3>Purchasing information</h3>
                                <div>
                                    <Checkbox
                                        checked={isPurchase}
                                        onChange={(evt, checked) => {
                                            setIsPurchase(checked)
                                        }}
                                        color="primary"
                                    />
                                    <p>I purchase this product/service from a vendor.</p>
                                </div>
                            </div>
                            {isPurchase && (<>
                                <div className={classes.duleInputCont} >
                                    <div className={classes.halfWidth} >
                                        <TextFieldNumberFormated
                                            label={<>Cost<span style={{ color: "red" }}>*</span></>}
                                            value={cost}
                                            size="small"
                                            onChange={(e) => { setCost(e.target.value); }}
                                            variant="outlined"
                                            placeholder="Enter cost"
                                            type="number"
                                            fullWidth
                                        />
                                        <p className={classes.validationSty} >{validationObj?.Purchasing}</p>
                                    </div>
                                    <div className={classes.halfWidth} >
                                        <Autocomplete
                                            id="free-solo-demo"
                                            freeSolo
                                            value={costUnit}
                                            options={unitsArray}
                                            disableClearable
                                            getOptionLabel={(option) => { return option }}
                                            getOptionSelected={(option) => {
                                                return option === costUnit
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    style={{ margin: "0px" }}
                                                    margin="normal"
                                                    variant="outlined"
                                                    label={<>Unit<span style={{ color: "red" }}>*</span></>}
                                                    placeholder={"Select Unit"}
                                                />
                                            )}
                                            onChange={(event, value) => {
                                                setCostUnit(value)
                                            }}
                                            size="small"
                                        />
                                        <p className={classes.validationSty} >{validationObj?.PurchasUnit}</p>
                                    </div>
                                </div>
                                <div style={{ width: "100%", marginBottom: "15px" }} >
                                    <HierarchicalAutocomplete
                                        lableKey={"name"}
                                        options={expenseChartAccounts}
                                        value={expenseAcc}
                                        rootId={"_id"}s
                                        parentId={"parent"}
                                        label={"Expense account"}
                                        placeholder="Select Chart Account"
                                        onChange={(e, v) => {
                                            setExpenseAcc(v)
                                        }}
                                    />
                                </div>
                                <TextField
                                    id="outlined-multiline-static"
                                    label={"Description"}
                                    placeholder={"Enter Description"}
                                    value={expenseDesc}
                                    onChange={(e) => { setExpenseDesc(e.target.value) }}
                                    multiline
                                    rows={3}
                                    fullWidth
                                    style={{ marginBottom: "15px" }}
                                    variant="outlined"
                                />
                            </>)}
                        </div>
                        <div style={{ width: "100%", height: "5px" }} >
                            {loader && (<LinearProgress />)}
                        </div>
                        <div className={classes.bottomAct} >
                            <Button
                                variant="contained"
                                size="small"
                                disabled={loader}
                                color="primary"
                                type="submit"
                            >
                                Save & Close
                            </Button>
                        </div>
                    </form>
                </>) : (
                    <div className={classes.emptyCont} >
                        <img src={emptyPage} />
                        <p>
                            No Library exists in the current directory or any library still didn't selected as default library.<br />
                            Click <a
                                onClick={() => {
                                    history.push(`/offerings-categories/management/${profileId}`)
                                }}
                            >here</a> to create and manage your library(s), category(s), product(s) and service(s)
                        </p>
                    </div>
                )}
            </>}
        />
    );
}