import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, LinearProgress } from '@material-ui/core';
import NormalDrawer from '../styled/CommonComponents/NormalDrawer';
import ShareEntitySelectView from './ShareEntitySelectView';
import "./index.css"
import { createNewShare, getResourceAccessByResource, updateResourceAccessByResource, updateResourceAssigneds } from './Api.call';
import AssignedEntitySelectView from './AssignedEntitySelectView';
import NotifyAlert from '../styled/CommonComponents/NotifyAlert';

const useStyles = makeStyles((theme) => ({
    mainDialogCont: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "& .MuiButton-label": {
            textTransform: "capitalize",
            fontSize: "14px"
        }
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 55px)",
        overflowX: "hidden",
        overflowY: "auto",
        "& .MuiBox-root-121": {
            padding: "0px !important"
        }
    },
    bottomAct: {
        width: "100%",
        height: "50px",
        padding: "0px 7px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        borderTop: "1px solid #ececec"
    }
}));

export default function ResourceAssignedDrawer({
    openResourceAssign, setOpenResourceAssign, entity, curEntityId, callbackAfterShare,
    searchParameter, assignedProfiles, resourceName, resourceId
}) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    const [loader, setLoader] = useState(false)
    const [selectedEntities, setSelectedEntities] = useState([])
    const [alertObj, setAlertObj] = useState({})

    useEffect(() => {
        if (openResourceAssign) {
            setSelectedEntities(assignedProfiles)
        }
    }, [assignedProfiles, openResourceAssign])

    const onSubmit = async (e) => {
        e.preventDefault();
        if (resourceId != null && resourceId != undefined) {
            setLoader(true)
            const reqObj = {
                resourceName,
                assignedProfiles: selectedEntities,
                resourceId,
                creatorProfile: user?.profile
            }
            await updateResourceAssigneds(reqObj)
                .then(async (data) => {
                    if (callbackAfterShare) {
                        await callbackAfterShare(true, data)
                        setOpenResourceAssign(false)
                    }
                    setLoader(false)
                    setAlertObj({
                        show: true,
                        message: "assign successfully updated!!!",
                        status: "success"
                    })
                })
                .catch((err) => {
                    console.log(err);
                    setLoader(false)
                    setAlertObj({
                        show: true,
                        message: "Something went wrong!!!",
                        status: "error"
                    })
                })
        } else {
            await callbackAfterShare(false, selectedEntities)
            setOpenResourceAssign(false)
        }
    }

    return (<>
        <NormalDrawer
            openDrawer={openResourceAssign}
            setOpenDrawer={setOpenResourceAssign}
            anchor={"right"}
            width={"40vw"}
            title={"Manage Resource Access"}
            content={
                <form className={classes.mainDialogCont} onSubmit={onSubmit} >
                    <div className={classes.bodyCont} >
                        <AssignedEntitySelectView
                            entity={entity}
                            curEntityId={curEntityId}
                            searchParameter={searchParameter}
                            selectedEntities={selectedEntities}
                            setSelectedEntities={setSelectedEntities}
                        />
                    </div>
                    <div style={{ width: "100%", height: "5px" }} >
                        {loader && (<LinearProgress />)}
                    </div>
                    <div className={classes.bottomAct} >
                        <Button
                            variant="contained"
                            size="small"
                            color="primary"
                            type="submit"
                        >
                            Save & Close
                        </Button>
                    </div>
                </form>
            }
        />
        <NotifyAlert
            alertObj={alertObj}
            setAlertObj={setAlertObj}
        />
    </>);
}