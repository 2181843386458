import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTheme } from "styled-components";
import IconButton from "@material-ui/core/IconButton";
import ProjectManagementCardSvg from "../../../Assets/proj_mgmt_logo.svg";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import IssueSelectDialog from "../../issue/issue.select.dialog";
import SelectTemplate from "../../issue/select.template.dialog";
import useGetTemplates from "../../issue/useGetTemplates";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ProjectManagementSingleCard from "./Projecct.Management.Single.Card";
import emptyIcon from "../../../Assets/vivekEmpty.svg";
import Skeleton from "@material-ui/lab/Skeleton";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        width: "100%",
        height: "370px",
        borderRadius: "10px",
        marginBottom: "20px",
        padding: "10px 15px",
        [theme.breakpoints.down("xs")]: {
            padding: "10px 5px",
        },
    },
    cardTopHeading: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& > h3": {
            fontWeight: "510",
            fontSize: "17px",
            color: "#193B56",
        },
    },
    createBtn: {
        width: "25px",
        height: "25px",
        borderRadius: "6px",
        backgroundColor: "#c1def6",
        color: theme.palette.primary.main,
        "&:hover": {
            backgroundColor: "#70b8f4",
            color: "white",
        },
    },
    topBox: {
        width: "100%",
        padding: "3px 10px",
        display: "flex",
        alignItems: "flex-start",
        marginTop: "10px",
        cursor: "pointer",
        marginBottom: "20px",
        "& > p": {
            fontSize: "15px",
            marginTop: "10px",
            color: "#696969",
        },
        "&:hover": {
            backgroundColor: "#edecec",
        },
    },
    // topBoxSvgCont: {
    //     width: "120px",
    //     height: "70px",
    //     display: "flex",
    //     overflow: "hidden",
    //     alignItems: "center",
    //     justifyContent: "center",
    //     marginRight: "15px",
    //     "& > img": {
    //         width: "95px",
    //         height: "95px",
    //     },
    // },
    container: {
        width: "100%",
        height: "215px",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    timeSty: {
        fontSize: "10px",
        fontWeight: "500",
        color: "gray",
    },
    iconSty: {
        fontSize: "45px",
    },
    tbHeaderCell: {
        borderTop: "1px solid #e0e0e0",
        color: "#696969",
        fontSize: "15px",
        padding: "0px",
    },
    tbBodyCell: {
        border: "none",
        padding: "10px 5px",
    },
    statusCont: {
        fontSize: "11px",
        padding: "1px 3px",
        borderRadius: "3px",
        backgroundColor: "gray",
        color: "white",
    },
    avatarGroupSty: {
        maxWidth: "100px",
        "& .MuiAvatar-root": {
            width: "30px",
            height: "30px",
        },
    },
    skeletonCont: {
        paddingTop: "10px",
        width: "100%",
        height: "175px",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none",
        },
    },
    emptyCont: {
        display: "flex",
        width: "100%",
        height: "175px",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        overflow: "hidden",
        "& img": {
            height: "100px",
            width: "auto",
        },
        "& p": {
            fontSize: "12px",
            color: "gray",
            textAlign: "center",
            marginTop: "5px",
        },
    },
}));

const PermitAndApprovalCard = ({ issues, profileId, loader }) => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();

    const [open, setOpen] = useState(false);
    const [selectedIssue, setSelectedIssue] = useState({});
    const [selectTemplateOpen, setSelectTemplateOpen] = useState(false);

    const {
        templateIds,
        setTemplateIds,
        templateDictionary,
        setTemplateDictionary,
    } = useGetTemplates(profileId);

    const location = useLocation();

    const [redirectedFromProject, setRedirectedFromProject] = useState(null);
    useEffect(() => {
        const url = location.pathname;
        const segments = url.split("/");
        if (segments[1] === "project") {
            setRedirectedFromProject(segments[2]);
        }
    }, [location.pathname]);

    return (
        <Paper className={classes.cardContainer}>
            <div className={classes.cardTopHeading}>
                <h3>Permit And Approval</h3>
                <IconButton
                    className={classes.createBtn}
                    onClick={() =>
                        //     {
                        //     setSelectTemplateOpen(true);
                        //   }
                        history.push(
                            "/permitapproval/profile/" + profileId
                        )
                    }
                    color="primary"
                >
                    +
                </IconButton>
            </div>

            <Paper
                elevation={1}
                onClick={() =>
                    history.push(
                        "/permitapproval/profile/" + profileId
                    )
                }
                className={classes.topBox}
            >
                {/* <div className={classes.topBoxSvgCont}>
                    <img src={ProjectManagementCardSvg} />
                </div> */}
                <p>
                    Create different Permit And Approvals.
                </p>
            </Paper>

            {/* <IssueSelectDialog open={open} setOpen={setOpen} issue={selectedIssue} />
      {selectTemplateOpen && (
        <SelectTemplate
          templateIds={templateIds}
          templateDictionary={templateDictionary}
          profile={profileId}
          defaultOpen={true}
          setOuterOpen={setSelectTemplateOpen}
        />
      )} */}
        </Paper>
    );
};
export default PermitAndApprovalCard;
